
import SI3349 from "./../../images/products/sizer/1.jpg";
import SI3419 from "./../../images/products/sizer/2.jpg";
import SI3431 from "./../../images/products/sizer/3.jpg";
import SI3446 from "./../../images/products/sizer/4.jpg";
import SI3478 from "./../../images/products/sizer/5.jpg";
import SI6 from "./../../images/products/sizer/6.jpg";

const sizerImages = [
    { original: SI3349, thumbnail: SI3349 },
    { original: SI3419, thumbnail: SI3419 },
    { original: SI3419, thumbnail: SI3431 },
    { original: SI3446, thumbnail: SI3446 },
    { original: SI3478, thumbnail: SI3478 },
    { original: SI6, thumbnail: SI6 },
]

const sizer = [
    {
        id: 'sizer-412',
        title: 'Sizer - 412',
        series: 'SIZER',
        images: sizerImages,
        video: "https://www.youtube.com/embed/Rf8HkDra5ug",
        description: 'Grader is used to separate rice on the basis of thickness, Rice is gravity fed into the round sieves which continuously rotates. The small fraction rice which is under size passes through the screen & is discharged through the hopper & the remaining rice are delivered to the outlet for further processing.',
        usp: ['Precise Stainless-steel Sieves that ensure the accuracy of separation.', 'An integrated cleaning brush counter rotates with the drum to avoid  choking of slots.', 'A customized selection of sieves to suit various applications'],
        specification: [{ 'Product Name': 'Grading' }, { 'Model': 'SIZER 412' }, { 'Capacity': '4 TPH' }, { 'Power Consumption (HP) (Recommended)': '3 HP' }, { 'Weight (Kg)': '500' }, { 'Dimensions (L*W*H in mm)': '2270*840*1730' }],
        technology: ['The final rice is fed into the round perforated or dimpled steel drums, which rotates continuously at the set RPM. The rice kernel of the required length gets stuck into the dimples and taken along to a required height and then dropped into a screw conveyor. The remaining rice of different length is then transferred to other drums for further grading.'],
        category: 'Sizer',
        categoryLink: '/sizer'
    },
    {
        id: 'sizer-612',
        title: 'Sizer - 612',
        series: 'SIZER',
        images: sizerImages,
        video: "https://www.youtube.com/embed/Rf8HkDra5ug",
        description: 'Grader is used to separate rice on the basis of thickness, Rice is gravity fed into the round sieves which continuously rotates. The small fraction rice which is under size passes through the screen & is discharged through the hopper & the remaining rice are delivered to the outlet for further processing.', usp: ['Precise Stainless steel Sieve that ensures the accuracy of separation', 'An integrated cleaning brush counter rotates with the drum to avoid choking of slots', 'Customized selection of sieves to suit various application'],
        usp: ['Precise Stainless-steel Sieves that ensure the accuracy of separation.', 'An integrated cleaning brush counter rotates with the drum to avoid  choking of slots.', 'A customized selection of sieves to suit various applications'],
        specification: [{ 'Product Name': 'Grading' }, { 'Model': 'SIZER 612' }, { 'Capacity': '6 TPH' }, { 'Power Consumption (HP) (Recommended)': '3 HP' }, { 'Weight (Kg)': '700' }, { 'Dimensions (L*W*H in mm)': '2270*840*2340' }],
        technology: ['The final rice is fed into the round perforated or dimpled steel drums, which rotates continuously at the set RPM. The rice kernel of the required length gets stuck into the dimples and taken along to a required height and then dropped into a screw conveyor. The remaining rice of different length is then transferred to other drums for further grading.'],
        category: 'Sizer',
        categoryLink: '/sizer'
    }
]

export default sizer;