
import { createSlice } from '@reduxjs/toolkit';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

import axios from 'axios'
import { keyUri } from '../key'


export const initialState = {
  loading: false,
  success: false,
  hasErrors: false,
  mails: [],
  currentmails: null,
  redirectToThank: false,
}

export const subscribeSlice = createSlice({
  name: "subscribe",
  initialState,
  reducers: {

    getSubscribe: state => {
      state.loading = true
    },

    getSubscribeSuccess: (state, { payload }) => {
      state.loading = false
      state.subscribe = payload
      state.success = true
      state.redirectToThank = true
    },

    getCurrentSubscribe: (state, { payload }) => {
      console.log({ k: payload });

      state.loading = false
      state.currentsubscribe = payload

    },
    getSubscribeFailure: (state, { payload }) => {

      state.loading = false
      state.subscribe = payload
      state.success = false

      state.redirectToThank = false
    },
  }
})

export const { getSubscribe, getSubscribeSuccess, getCurrentSubscribe, getSubscribeFailure } = subscribeSlice.actions

export const subscribeSelector = state => state.subscribe
export const redirectReady = state => state.subscribe?.redirectToThank

export default subscribeSlice.reducer

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
};

export const createSubscribe = (values, navigate) => async dispatch => {
  console.log(values);
  const key = "subscribe"
  dispatch(getSubscribe())
  try {
    const { data } = await axios.post(keyUri.BACKEND_URI + '/mail', values, config);
    dispatch(getSubscribeSuccess(data));
    toast.success('Succesfully Subscribed to mailingList', {
      position: "top-center",
      autoClose: 10000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    navigate('/thankyou');
  } catch (err) {
    console.log(err);
    toast.error('You have Already Subscribed to our Mailing list', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(getSubscribeFailure())
  }

}










