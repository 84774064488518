

import FS1 from "./../../images/products/floursifter/1.jpg";

const huskFanImages = [
    { original: FS1, thumbnail: FS1 },
];

const floursifter = [
    {
        id: 'flour-sifter',
        title: 'Flour Sifter',
        series: 'Flour Sifter',
        images: huskFanImages,
        description: 'Fixed Drum flour sifter is robustly designed to separate the coarse material from fine flour. It is designed for a accurate and precise operation. The machinery works best for cleaning of fine flour in distillery',
        usp: ['East Operation and Cleanup', 'Robust structure and design for long life of the machine', 'Screens help to separate the flour particles, removing any lumps and impurities', 'Food grade 304 SS screens used for durability'],
        specification: [{ 'Product Name': 'Flour Sifter' }, { 'Mode': 'FDRC 10' }, { 'Capacity on Paddy': '10-12 TPH' }, { 'Power Consumption (HP) ': '7.5/1' }, { 'Weight (Kg) ': '720' }, { 'Dimensions (LxWxH in mm': '2439 X 1220 X 2134' }],
        category: 'Flour Sifter',
        categoryLink: '/flour-sifter'
    },
]

export default floursifter;