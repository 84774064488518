import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllnews, newsSelector } from '../../api/news';
import Ourevents from '../../images/ourevents.jpg';
import { Link, useParams } from 'react-router-dom';
import PageHeader from "./../global/pageheader";

function NewsCard(props) {
  return (
    <div className="col-md-4 col-sm-6" key={props?._id}>
      <div className="card m-1 shadow">
        <div className="card-body ">
          <Link to={`/newspage/${props?._id}`}>
            <img className="img-fluid rounded" src={props?.image} alt="" />
            <p className="text-center" aria-current="page">{props?.title}</p>
          </Link>
        </div>
      </div>
    </div>
  )
}
export default function News() {
  console.log('Calling Calling');
  const dispatch = useDispatch();
  const { all_news } = useSelector(newsSelector);
  const { id } = useParams();

  console.log('All News', all_news);
  useEffect(() => {
    dispatch(fetchAllnews(id))
  })

  const newsItems = [];
  all_news.forEach((n) => newsItems.push(NewsCard(n)));

  return (
    <div className="container">
      <PageHeader img={Ourevents} head="Our Latest News"></PageHeader>
      <div className="row">
        {newsItems}
      </div>
    </div>
  )
}
