import MultiProducts from "../multiproducts";

import i1 from "./../../../images/products/materialhandling/chainconveyor/1.jpg";
import i2 from "./../../../images/products/materialhandling/chainconveyor/2.jpg";


const bucketImages = [
    { original: i1, thumbnail: i1 },
    { original: i2, thumbnail: i2 },
]


const data =
{
    id: 'Chain_Conveyor',
    title: 'Chain Conveyor',
    series: 'CC',
    images: bucketImages,
    video: "",
    description: 'The chain conveyor has a series of gears connected into a continuous system by the chain. Chain conveyors are used for moving products down an assembly line and/or around a manufcaturing or warehousing facility',
    usp: ['Easy to use'],
    specification: [
        ['Product Name', 'Model', 'Capacity Paddy (TPH)', 'Capacity Rice (TPH)'],
        ['Grain Feeder', 'CC 150', 'NA', '12'],
        ['Grain Feeder', 'CC 200', '25', '30'],
        ['Grain Feeder', 'CC 250', '30', '45'],
        ['Grain Feeder', 'CC 300', '45', '55'],
    ],
    category: 'Chain Conveyor',
    categoryLink: '/products/chain-conveyor-cc-300'
};

export default function ChainConveyors() {

    return (
        <MultiProducts currentProduct={data}></MultiProducts>
    )
}