import LI3264 from "./../../images/products/lengthgrader/1.jpg";
import LI3276 from "./../../images/products/lengthgrader/2.jpg";
import LI3286 from "./../../images/products/lengthgrader/3.jpg";
import LI3296 from "./../../images/products/lengthgrader/4.jpg";
import LI3312 from "./../../images/products/lengthgrader/5.jpg";
import LI3327 from "./../../images/products/lengthgrader/6.jpg";

const graderImages = [
    { original: LI3264, thumbnail: LI3264 },
    { original: LI3276, thumbnail: LI3276 },
    { original: LI3286, thumbnail: LI3286 },
    { original: LI3296, thumbnail: LI3296 },
    { original: LI3312, thumbnail: LI3312 },
    { original: LI3327, thumbnail: LI3327 },
]

const lengthgrader = [
    {
        id: 'length-grader-lg-01',
        title: 'Length Grader LG - 01',
        series: 'LENGTH GRADER LG',
        images: graderImages,
        video: "https://www.youtube.com/embed/Rf8HkDra5ug",
        description: 'This Machine is specially used to separate one or two kinds of broken rice from head rice by use of indented cylinder based on length of the input rice.',
        usp: [
            "Precise Indent Screen for perfect grading",
            "Hardened Stainless Steel screen is used, for long life and to prevent choking of Bran",
            "Easy maintenance and minimum operating cost",
            "Higher out put with adjustable speed (optional)"
        ],
        specification: [{ 'Product Name': 'Grading' }, { 'Model': 'LG 01' }, { 'Capacity': '1 TPH' }, { 'Power Consumption (HP) (Recommended)': '1 HP' }, { 'Weight (Kg)': '275' }, { 'Dimensions (L*W*H in mm)': '2820*665*800' }],
        technology: ['The final rice is fed into the round perforated or dimpled steel drums, which rotates continuously at the set RPM. The rice kernel of the required length gets stuck into the dimples and taken along to a required height and then dropped into a screw conveyor. The remaining rice of different length is then transferred to other drums for further grading.'],
        category: 'Length Grader',
        categoryLink: '/length-grader'
    },
    {
        id: 'length-grader-lg-03',
        title: 'Length Grader LG - 03',
        series: 'LENGTH GRADER LG',
        images: graderImages,
        video: "https://www.youtube.com/embed/Rf8HkDra5ug",
        description: 'This Machine is specially used to separate one or two kinds of broken rice from head rice by use of indented cylinder based on length of the input rice.',
        usp: [
            "Precise Indent Screen for perfect grading",
            "Hardened Stainless Steel screen is used, for long life and to prevent choking of Bran",
            "Easy maintenance and minimum operating cost",
            "Higher out put with adjustable speed (optional)"
        ],
        specification: [{ 'Product Name': 'Grading' }, { 'Model': 'LG 03' }, { 'Capacity': '3 TPH' }, { 'Power Consumption (HP) (Recommended)': '3 HP' }, { 'Weight (Kg)': '810' }, { 'Dimensions (L*W*H in mm)': '3990*1275*1075' }],
        technology: ['The final rice is fed into the round perforated or dimpled steel drums, which rotates continuously at the set RPM. The rice kernel of the required length gets stuck into the dimples and taken along to a required height and then dropped into a screw conveyor. The remaining rice of different length is then transferred to other drums for further grading.'],
        category: 'Length Grader',
        categoryLink: '/length-grader'
    }
]

export default lengthgrader;