import { Link } from "react-router-dom";

export default function ProductTile(props) {
    return (
        <div key={props.i} className="col text-center">
            <div className="row">
                <a className="" href={'/products' + props.link}>
                    <img className="img-fluid rounded img-responsive shadow"
                        style={{ maxWidth: '300px', maxHeight: '300px', minHeight: '300px', minWidth: '300px' }}
                        src={props.img} alt="" />
                </a>
            </div>
            <div className="row">
                <Link to={props.furl ? props.link : ('/products' + props.link)} className="text-center">
                    <div className="btn btn-primary mt-2">{props.title}
                    </div></Link>
            </div>
        </div>
    );
}
