import React, { useState, useEffect, useLayoutEffect } from "react";
import Features from '../HomePage/features'
import Videologo from '../HomePage/videologo'
import Newsletter from '../HomePage/newsletter'
import News from '../HomePage/news'
import { useForm } from 'react-hook-form';
import Aboutus from './about'
import IndexSolutions from "./solutions";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { createContactEnquiry, contactEnquirySelector } from "../../api/contactenquiry";
import 'react-responsive-modal/styles.css';
import { useNavigate } from "react-router-dom";
import Testimonials from "./testimonials";
import MainCarousel from "./maincarousel";

export default function Index() {
  const dispatch = useDispatch()
  const { all_contact } = useSelector(contactEnquirySelector)
  console.log({ all_contact })
  const [visible, setVisible] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (value) => {
    value.crmsource = 'Website-Contact-Us';
    dispatch(createContactEnquiry(value));
    if(navigate) {
      navigate('/thankyou');
    }
    onCloseModal();
  }

  const onOpenModal = () => setModalOpen(true);
  const onCloseModal = () => setModalOpen(false);

  useEffect(() => {
    console.log('isMobile', isMobile);
    //setTimeout(() => setModalOpen(!isMobile), 3000)
  }, []);
  
  return (
    <div className="container-fluid bg-light">
      {modalOpen ?
        '' :
        <div className="row opacity-75"
          onClick={setModalOpen}
          style={{ position: 'fixed', bottom: '170px', right: '40px', zIndex: '100' }}>
          <i className="bi bi-chat-right-dots " style={{ fontSize: '3rem', color: 'green' }}></i>
        </div>
      }
      <div>
        {modalOpen ?
          <div
            style={{ minWidth: '270px', position: 'fixed', bottom: '110px', right: '40px', zIndex: '100', maxHeight: '60%' }}>
            <div className="card opacity-75">
              <div className="card-header">
                <div className="row">
                  <div className="col-10">Need Help ?</div>
                  <div className="col-1">
                    <span className="btn btn-sm btn-primary" onClick={onCloseModal}>
                      X
                    </span>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)} className="form">
                  <div className="mb-2">
                    <input {...register("name", { required: true })} className="form-control" type="text" id="name" placeholder="Name*" />
                    {errors.name?.type === 'required'}
                  </div>
                  <div className="mb-2  grid-cols-2">
                    <input {...register('email', { required: true })} className="form-control" type="email" id="email" placeholder="Email Address*" />
                    {errors.email?.type === 'required'}
                  </div>
                  <div className="mb-2  grid-cols-2">
                    <input {...register('phone', { required: true })} className="form-control" type="text" id="phone_number" placeholder="Phone Number*" />
                    {errors.phone_number?.type === 'required'}
                  </div>
                  <div className="mb-2  grid-cols-2 ">
                    <input {...register('country', { required: true })} className="form-control" type="text" id="country" placeholder="Country" />
                    {errors.country?.type === 'required'}
                  </div>
                  <div className="mb-2 grid-cols-2 ">
                    <input {...register('state', { required: true })} className="form-control" type="text" id="state" placeholder="State" />
                  </div>
                  <div className="mb-2  grid-cols-2 ">
                    <input {...register('city', { required: true })} className="form-control" type="text" id="city" placeholder="City" />
                  </div>

                  <div className="mb-2 ">
                    <textarea {...register('message', { required: true })} className="form-control" rows="2" type="text" id="message" placeholder="Message(Optional)"></textarea>
                  </div>

                  <div className="mt-2 mx-auto text-center">
                    <button className="btn btn-info" value="Submit" type="submit" >Submit</button>
                    <button className="btn btn-danger mx-1" type="button" onClick={onCloseModal} >Close</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          : ''}
      </div>
      <div className="row">
        <MainCarousel />
      </div>
      
      <div className="row page-section">
        <IndexSolutions />
      </div>
      
      <div className="row sdpage-section">
        <Videologo />
      </div>

      <div className="row page-section bg-light">
        <Aboutus />
      </div>

      <div className="row  bg-primary">
        <Testimonials className="page-section"/>
      </div>
      
      <div className="row page-section">
        <News />
      </div>

      <div className="row page-section">
        <Newsletter />
      </div>
    </div>
  )
}
