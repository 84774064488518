import React from 'react';
import Turn from '../../../images/turn.png';
import HF1 from "./../../../images/products/huskfan/1.jpg";
import HF2 from "./../../../images/products/huskfan/sn/1.jpg";

import { Link } from 'react-router-dom';
import PageHeader from "./../../global/pageheader";
import ProductTile from '../productTile';

export default function Huskfan() {

  const huskfan = [
    {
      id: 1,
      img: HF1,
      title: 'HuskFan',
      link: '/husk-fan'
    },
    {
      id: 2,
      img: HF2,
      title: 'HuskFan - SN',
      link: '/husk-fan-sn'
    },
  ]

  return (
    <div className="container-fluid">
      <PageHeader img={Turn} head="Husk Fan"></PageHeader>
      <div className="container">
        <div className="row text-center">
          {
            huskfan.map((item, i) => {
              return ProductTile({
                i,
                link: item.link,
                title: item.title,
                img: item.img
              })
            })
          }
        </div>
        <div className="my-5">
          <div className="row">
            <h2 className="fw-bold">Why Huskfan ?</h2>
            <p className="">Husk Aspirators are designed to separate husk from the De-hulled paddy. They are equipped with dynamically balanced blower which helps in separation of the husk. The paddy flow through the adjustable flips to deliver husk free rice and paddy. The husk is conveyed through the pipeline to the husk yard.</p>
          </div>

          <div className="row">
            <h2 className="fw-bold">What are the usage Huskfan ?</h2>
            <p className=""> Paddy Huskers are used to remove shells from the rice kernel to obtain the rice out of it.</p>
          </div>

          <div className="row">
            <h2 className="fw-bold">Benefits of Husk Aspirator</h2>
            <div className="">
              <ul>
                <li>Suitable for all types of Paddy.</li>
                <li>Stainless Steel Impellers & baffles for long grains separation.</li>
                <li>Screw conveyor & special hopper for immature paddy separation.</li>
                <li>Better husk sorting by vacuum type separation.</li>
                <li>Better recovery of broken rice and immature grains.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}
