const  keyUri = {

    //BACKEND_URI:'http://localhost:5000/api'
    // BACKEND_URI:'https://seal-app-sgi5k.ondigitalocean.app/api',  
    
    // BACKEND_URI:'https://lobster-app-fvzbk.ondigitalocean.app/api/v2/'
    BACKEND_URI:'https://orca-app-ocw8h.ondigitalocean.app/api'
    

}


let token = localStorage.getItem('token')

const config = {
    headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
    }
  };
  
  
export  {keyUri, config }

