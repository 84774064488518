import React from 'react';
import RCS150 from '../../../images/RCS150.webp';
import RCS200 from '../../../images/RCS200.webp';
import SC from '../../../images/SC.webp';
import BC from '../../../images/BC.webp';
import CC from '../../../images/CC.webp';
import image1 from '../../../images/square.png';

import { Link } from 'react-router-dom'

export default function Graindischarger() {


  const graindischarger = [
    {
      id: 4,
      img: image1,
      title: 'Grading Discharger RCS-300',
      link: '/grading-discharger-rcs-300'
    },
    {
      id: 5,
      img: BC,
      title: 'Grain Mover Belt Conveyer',
      link: '/grain-mover-belt-conveyer'
    },

    {
      id: 6,
      img: CC,
      title: 'Grain Feeder Chain Conveyer',
      link: '/grain-feeder-chain-conveyer'
    },

    {
      id: 7,
      img: SC,
      title: 'Grain Feeder Screw Conveyer',
      link: '/grain-Feeder-screw-conveyer'
    },

  ]

  return (
    <div className="">
      <section style={{ fontFamily: 'poppins' }}>
        <div className="pt-20 bg-gradient-to-r from-green-700  to-blue-700 overflow-hidden radius-for-skewed" >
          <div className=" h-64 mx-auto px-4">
            <div className="relative max-w-md mx-auto text-center">
              <h2 className="text-4xl lg:text-5xl text-white font-bold">Grain Discharger</h2>
            </div>
          </div>
        </div>
      </section>

      <section className="lg:py-2 bg-gray-50 text-slate-700 bg-blueGray-100" style={{ fontFamily: 'Lato' }}>
        {/* <a className="block w-full bg-slate-300" href="#">
              <img className=" sm:h-96 md:h-96 sm:w-full md:w-full object-cover " src={factory} alt=""/>
            </a> */}
        <div className="container px-1 lg:px-36 mb-8 mx-auto">
          {/* <h2 className=" mt-8 text-3xl xl:text-4xl font-heading font-medium text-center text-indigo-900"><b>Grain-Discharger </b></h2> */}
          <div className="max-w-max mx-auto">
            <div className="h-26 w-px bg-white"></div>
          </div>

          <div className="flex items-center mt-12">
            <a className="hidden md:block pr-6 hover:text-darkBlueGray-400" href="#">
            </a>
            <div className="w-full flex flex-wrap justify-center">

              {
                graindischarger.map((item, i) => {
                  return <div key={i} className="w-full sm:w-1/2 xl:w-1/4  mb-6 px-1 xl:mb-0 ">
                    <div className="p-1 xl:px-0 xl:pt-4 xl:pb-10 h-full bg-white rounded-3xl">
                      <a className="block mx-auto mb-8 xl:mb-2 max-w-max h-64" href={'/products' + item.link}>
                        <img className="h-full w-full object-cover" src={item.img} alt="" />
                      </a>
                      <a href="#">
                        <Link to={'/products' + item.link} className="text-xl leading-8 font-heading font-medium hover:underline text-center"><p className="text-center text-indigo-900">{item.title}</p></Link>
                      </a>
                    </div>
                  </div>
                })
              }

            </div>
            <a className="hidden md:block pl-6 hover:text-darkBlueGray-400" href="#">
            </a>
          </div>
          <div className="px-7 mb-5">
            <div className="mt-10">
              <h2 className=" mt-8 text-3xl xl:text-4xl font-heading font-medium text-left md:mr-10 text-indigo-900"><b>Why Grain Discharger ?</b></h2>
              <p className="mt-5 text-justify text-indigo-900">The Crop or Raw Material from farms brought into the mill has to go through the Pre-Cleaning process as there are a lot of Dust, Mud balls, big impurities are present and it should be cleared prior to storage or further process.Pre-Cleaning is used for clearing the dust or big impurities in large quantities and further cleaning and destoning is still required to remove smaller impurities.</p>
            </div>

            <div className="mt-10">
              <h2 className=" mt-8 text-3xl xl:text-4xl font-heading font-medium text-left md:mr-10 text-indigo-900"><b>What are the usage of Grain Discharger ?</b></h2>
              <p className="mt-5 text-justify text-indigo-900">Pre-Cleaners are used to remove dust, Mud Balls, Sachse, rocks, ropes, rodents bodies, etc.</p>
            </div>

            <div className="mt-10">
              <h2 className=" mt-8 text-3xl xl:text-4xl font-heading font-medium text-left md:mr-10 text-indigo-900"><b>What are the benefits of Grain Discharger ?</b></h2>
              <p className="mt-5 text-justify text-indigo-900">It is beneficial to pre-clean the raw material before storage or before sending the crop for further process as the majority of impurities are removed in this process and it also saves the further equipment and machines from wear & tear.</p>
            </div>
          </div>

        </div>
      </section>
    </div>
  )
}
