import IE1284 from "./../../images/products/stoneseparators/eco/1.jpg";
import IE1297 from "./../../images/products/stoneseparators/eco/2.jpg";
import IE1333 from "./../../images/products/stoneseparators/eco/3.jpg";
import IE1360 from "./../../images/products/stoneseparators/eco/4.jpg";
import IE1376 from "./../../images/products/stoneseparators/eco/5.jpg";
import IE1409 from "./../../images/products/stoneseparators/eco/6.jpg";

import IPR0416 from "./../../images/products/stoneseparators/pr/1.jpg";
import IPR0425 from "./../../images/products/stoneseparators/pr/2.jpg";
import IPR0432 from "./../../images/products/stoneseparators/pr/3.jpg";
import IPR0446 from "./../../images/products/stoneseparators/pr/4.jpg";
import IPR0461 from "./../../images/products/stoneseparators/pr/5.jpg";
import IPR0465 from "./../../images/products/stoneseparators/pr/6.jpg";

import IS0196 from "./../../images/products/stoneseparators/s/1.jpg";
import IS0204 from "./../../images/products/stoneseparators/s/2.jpg";
import IS0207 from "./../../images/products/stoneseparators/s/3.jpg";
import IS0214 from "./../../images/products/stoneseparators/s/4.jpg";
import IS0224 from "./../../images/products/stoneseparators/s/5.jpg";
import IS0241 from "./../../images/products/stoneseparators/s/6.jpg";

const ecoImages = [
    { original: IE1284, thumbnail: IE1284 },
    { original: IE1297, thumbnail: IE1297 },
    { original: IE1333, thumbnail: IE1333 },
    { original: IE1360, thumbnail: IE1360 },
    { original: IE1376, thumbnail: IE1376 },
    { original: IE1409, thumbnail: IE1409 },
];

const prImages = [
    { original: IPR0416, thumbnail: IPR0416 },
    { original: IPR0425, thumbnail: IPR0425 },
    { original: IPR0432, thumbnail: IPR0432 },
    { original: IPR0446, thumbnail: IPR0446 },
    { original: IPR0461, thumbnail: IPR0461 },
    { original: IPR0465, thumbnail: IPR0465 },
];

const sImages = [
    { original: IS0196, thumbnail: IS0196 },
    { original: IS0204, thumbnail: IS0204 },
    { original: IS0207, thumbnail: IS0207 },
    { original: IS0214, thumbnail: IS0214 },
    { original: IS0224, thumbnail: IS0224 },
    { original: IS0241, thumbnail: IS0241 },
];

const stoneseparators = [{
    id: 'stone-separator-s-40',
    title: 'Stone Separator - S 40',
    series: 'STONE SEPARATOR - S',
    images: sImages,
    video: "https://www.youtube.com/embed/Rf8HkDra5ug",
    description: 'Stone Separators are principally designed to operate on negative pressure & gravitational power accompanied by sieved trays to separate heavy impurities especially stones. It works according to the specific density of the grain.',
    usp: [' Efficient & precise separation of stones and heavy materials.',
        'Adjustable table inclination and air volume of the machine.',
        'Fitted with imported vibro motors for trouble free, long life and low noise  operation.',
        'Special imported sieves for the perfect cleaning of the grains.',
        'S series models are compatible for de-stoning of different types of grains.'
    ],
    specification: [{ 'Product Name': 'Stone Separator' }, { 'Model': 'S40' }, { 'Capacity': '4 - 6 TPH' }, { 'Power Consumption (HP) (Recommended)': '7.5/1  HP' }, { 'Weight (Kg)': '500' }, { 'Dimensions (L*W*H in mm)': '1500*1235*1996' }],
    category: 'Stone-Separators',
    categoryLink: '/Stone-Separators'

},
{
    id: 'stone-separator-s-80',
    title: 'Stone Separator - S 80',
    series: 'STONE SEPARATOR - S',
    images: sImages,
    video: "https://www.youtube.com/embed/Rf8HkDra5ug",
    description: 'Stone Separators are principally designed to operate on negative pressure & gravitational power accompanied by sieved trays to separate heavy impurities especially stones. It works according to the specific density of the grain.',
    usp: ['Efficient & precise separation of stones and heavy materials.',
        'Adjustable table inclination and air volume of the machine.',
        'Fitted with imported vibro motors for trouble free, long life and low noise  operation.',
        'Special imported sieves for the perfect cleaning of the grains.',
        'S series models are compatible for de-stoning of different types of grains.',
    ], specification: [{ 'Product Name': 'Stone Separator' }, { 'Model': 'S80' }, { 'Capacity': '6 - 8 TPH' }, { 'Power Consumption (HP) (Recommended)': '7.5/1  HP' }, { 'Weight (Kg)': '500' }, { 'Dimensions (L*W*H in mm)': '1500*1535*1996' }],
    category: 'Stone-Separators',
    categoryLink: '/Stone-Separators'

},
{
    id: 'stone-separator-pr-5',
    title: 'Stone Separator - PR 5',
    series: 'STONE SEPARATOR - PR',
    images: prImages,
    video: "https://www.youtube.com/embed/Rf8HkDra5ug",
    description: 'PR Series Stone Separators are principally designed to operate on negative pressure & positive aspiration along with gravitational power accompanied by sieved trays to separate heavy impurities, especially stones. It works according to the specific density of the grain.',
    usp: [
        '2 Way cleaning of the grains.',
        'Ensures 0% wastage by using positive aspiration technology.',
        'Closed circuit aspiration system ensures dust-free environment.',
        'Fitted with imported vibro motors for trouble free, long life and low noise operation.'
    ],
    specification: [{ 'Product, Name': 'Stone Separator' }, { 'Model': 'PR5' }, { 'Capacity': '5 - 6 TPH' }, { 'Power Consumption (HP) (Recommended)': '0.5 x 02nos' }, { 'Weight (Kg)': '635' }, { 'Dimensions (L*W*H in mm)': '2074*860*1960' }],
    category: 'Stone-Separators',
    categoryLink: '/Stone-Separators'

},
{
    id: 'stone-separator-pr-10',
    title: 'Stone Separator - PR 10',
    series: 'STONE SEPARATOR - PR',
    images: prImages,
    video: "https://www.youtube.com/embed/Rf8HkDra5ug",
    description: 'PR Series Stone Separators are principally designed to operate on negative pressure & positive aspiration along with gravitational power accompanied by sieved trays to separate heavy impurities, especially stones. It works according to the specific density of the grain.',
    usp: ['2 Way cleaning of the grains.',
        'Ensures 0% wastage by using positive aspiration technology.',
        'Closed circuit aspiration system ensures dust-free environment.',
        'Fitted with imported vibro motors for trouble free, long life and low noise operation.'
    ],
    specification: [{ 'Product Name': 'Stone Separator' }, { 'Model': 'PR10' }, { 'Capacity': '10 - 12 TPH' }, { 'Power Consumption (HP) (Recommended)': '1 x 02nos.' }, { 'Weight (Kg)': '760' }, { 'Dimensions (L*W*H in mm)': '2083*1357*2034' }],
    category: 'Stone-Separators',
    categoryLink: '/Stone-Separators'

},
{
    id: 'stone-separator-pr-15',
    title: 'Stone Separator - PR 15',
    series: 'STONE SEPARATOR - PR',
    images: prImages,
    video: "https://www.youtube.com/embed/Rf8HkDra5ug",
    description: 'PR Series Stone Separators are principally designed to operate on negative pressure & positive aspiration along with gravitational power accompanied by sieved trays to separate heavy impurities, especially stones. It works according to the specific density of the grain.',
    usp: ['2 Way cleaning of the grains.',
        'Ensures 0% wastage by using positive aspiration technology.',
        'Closed circuit aspiration system ensures dust-free environment.',
        'Fitted with imported vibro motors for trouble free, long life and low noise operation.'
    ],
    specification: [{ 'Product Name': 'Stone Separator' }, { 'Model': 'PR15' }, { 'Capacity': '15 - 17 TPH' }, { 'Power Consumption (HP) (Recommended)': '1.5 x 2nos.' }, { 'Weight (Kg)': '635' }, { 'Dimensions (L*W*H in mm)': '2085*1866*2187' }],
    category: 'Stone-Separators',
    categoryLink: '/Stone-Separators'
},
{
    id: 'stone-separator-eco-5',
    title: 'Stone Separator - ECO 5',
    series: 'STONE SEPARATOR - ECO',
    images: ecoImages,
    video: "https://www.youtube.com/embed/Rf8HkDra5ug",
    description: 'ECO Series Stone separators work on positive aspiration technology. The rice grains are fed into the sieves, underneath which a fan is installed, which sends light Rice upwards and out from the top, whereas the heavy stones are left behind, which are thrown out from a different pass.',
    usp: [
        'Perfect for final cleaning of Rice before packaging',
        'Fully enclosed compact system',
        'Saving in power consumption'
    ],
    specification: [{ 'Product Name': 'Stone Separator' }, { 'Model': 'ECO 5' }, { 'Capacity': '2 - 3 TPH' }, { 'Power Consumption (HP) (Recommended)': '0.5 HP' }, { 'Weight (Kg)': '100' }, { 'Dimensions (L*W*H in mm)': '1100*725*990' }],
    category: 'Stone-Separators',
    categoryLink: '/Stone-Separators'
},
{
    id: 'stone-separator-eco-10',
    title: 'Stone Separator - ECO 10',
    series: 'STONE SEPARATOR - ECO',
    images: ecoImages,
    video: "https://www.youtube.com/embed/Rf8HkDra5ug",
    description: 'ECO Series Stone separators work on positive aspiration technology. The rice grains are fed into the sieves, underneath which a fan is installed, which sends light Rice upwards and out from the top, whereas the heavy stones are left behind, which are thrown out from a different pass.',
    usp: ['Perfect for final cleaning of Rice before packaging',
        'Fully enclosed compact system',
        'Saving in power consumption'],
    specification: [{ 'Product Name': 'Stone Separator' }, { 'Model': 'ECO 10' }, { 'Capacity': '4 - 5 TPH' }, { 'Power Consumption (HP) (Recommended)': '1 HP' }, { 'Weight (Kg)': '180' }, { 'Dimensions (L*W*H in mm)': '1100*1490*1210' }],
    category: 'Stone-Separators',
    categoryLink: '/Stone-Separators'

}];

export default stoneseparators;