import React from 'react'

import BannerInvestors from "./../../images/pages/pageheaders/banner_investors.jpg";
import BannerInvestorsMobile from "./../../images/pages/pageheaders/banner_investors_m.jpg";
import InvestorsImg from '../../images/investors/investors.jpg';
import PageHeader from "../global/pageheader";

export default function InvestorNews() {

    return (
        <div className="container-fluid">
            <PageHeader img={BannerInvestors} img_mobile={BannerInvestorsMobile} head="Investor News"></PageHeader>
            <div className="card mt-2 mb-2 shadow" >
                <div className="card-header">
                    Notice of Board Meeting
                </div>
                <div className="card-body">
                    <div className="mt-2 mb-2" >
                        <a href="/investors/notices/Notice28052024.pdf" className="row ms-5">Notice 28.05.2024</a>
                        <a href="/investors/notices/Notice26062024.pdf" className="row ms-5">Notice 26.06.2024</a>
                        <a href="/investors/notices/Notice31082024.pdf" className="row ms-5">Notice 31.08.2024</a>
                        <a href="/investors/notices/agm05.pdf" className="row ms-5">5th AGM</a>
                    </div>
                </div>
            </div>
            <div className="card mt-2 mb-2 shadow" >
                <div className="card-header">
                    Outcome of Board Meeting
                </div>
                <div className="card-body">
                    <div className="mt-2 mb-2" >
                        <a href="/investors/obm/Outcome28052024.pdf" className="row ms-5">Outcome 28.05.2024</a>
                        <a href="/investors/obm/Outcome26062024.pdf" className="row ms-5">Outcome 26.06.2024</a>
                        <a href="/investors/obm/Outcome31082024.pdf" className="row ms-5">Outcome 31.08.2024</a>
                    </div>
                </div>
            </div>
            <div className="card mt-2 mb-2 shadow" >
                <div className="card-header">
                    Newspaper Publication
                </div>
                <div className="card-body">
                    <div className="mt-2 mb-2" >
                        <a href="/investors/newspaper/predispatchIntimationnewspaperad010924.pdf" className="row ms-5">01.09.2024</a>
                    </div>
                </div>
            </div>
            <div className="card mt-2 mb-2 shadow" >
                <div className="card-header">
                    Closure of Trading Window
                </div>
                <div className="card-body">
                    <div className="mt-2 mb-2" >
                        <a href="/investors/tw/tw30032024.pdf" className="row ms-5">Trading Window 30.03.2024</a>
                    </div>
                </div>
            </div>

            <div className="card mt-2 mb-2 shadow" >
                <div className="card-header">
                    Financials
                </div>
                <div className="card-body">
                    <a href="/investors/financial-reports" className="row ms-5">Financials</a>
                </div>
            </div>
        </div>
    );
}