import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { createSubscribe, subscribeSelector, redirectReady } from '../../api/subscribe';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

export default function Newsletter(props) {
  console.log('prp', props);
  const { register, handleSubmit } = useForm();
  const { success } = useSelector(subscribeSelector)
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const onSubmit = (data) => dispatch(createSubscribe(data, navigate));


  return (
    <div className="text-center text-white shadow rounded gradient">
      <div className="row bg-primary gradient align-items-center " style={{minHeight: '200px'}}>
        <div className="col-md-4 offset-md-2 fs-5">
          <div>
            <h4 className="">Join our Mailing List</h4>
            <p className="">Subscribe to our newsletter for latest updates in the Industry</p>
          </div>
        </div>
        <div className="col-md-4 align-middle my-auto">
          <form className='form ' onSubmit={handleSubmit(onSubmit)} >
            <div className="input-group align-middle">
              <input  {...register("email")} type="text" className="form-control " placeholder="Your email address" aria-label="Your email address" aria-describedby="button-addon2" />
              <button className="btn btn-danger text-white" type="submit" id="button-addon2">Subscribe now</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}