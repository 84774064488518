import { ProductImage } from "./../productcategories/productPage";

export default function MultiProducts(params) {
    const currentProduct = params.currentProduct;

    const productRows = [];
    let headers = [];
    currentProduct?.specification?.map((item, i) => {
        for (let k of Object.keys(item)) {
            headers.push(k);
        }
    });

    return (
        <div className="container mb-4">
            <div className="row" id="landing">
                <div className="clearfix mt-5">
                    <div className="m-2  col-md-6 float-md-end mb-3 ms-md-3">
                        {currentProduct ? (
                            <ProductImage currentProduct={currentProduct} />
                        ) : ''}
                    </div>
                    <div className="m-4">
                        <h2 className="m-4">{currentProduct?.title}</h2>
                        <p className="m-4 text-justify">{currentProduct?.description}</p>
                        <h2 className="m-4">USP of {currentProduct?.title}</h2>
                        <ul className="m-4">
                            {
                                currentProduct?.usp?.map((item, i) => {
                                    return <li key={i}>{item}</li>
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row m-4">
                <h2 className="my-4">Product Specifications</h2>
                <div className="mx-auto">
                    <table className="d-none d-md-block d-sm-none table table-striped table-hover text-center w-100">
                        <tbody>
                            {
                                currentProduct?.specification.map((item, i) => {
                                    return <tr>
                                        {
                                            item.map((row, j) => {
                                                if(i == 0) {
                                                    return <th>
                                                        {row}
                                                    </th>
                                                }
                                                return <td>
                                                    {row}
                                                </td>
                                            })
                                        }
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row m-4">
                {currentProduct?.technology && <div className=''>
                    <h2 className="">Technology & Functioning</h2>
                    {currentProduct?.technology ?
                        currentProduct?.technology?.map((item, i) => {
                            return <p key={i}>{item}</p>
                        })
                        : ''
                    }
                </div>
                }
            </div>
        </div>
    );
}