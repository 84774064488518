import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchOneBlog, fetchAllblog, blogSelector } from '../../api/blog'
import { useParams } from 'react-router-dom'
import { Interweave } from 'interweave';

export default function BlogPage() {

    const dispatch = useDispatch()
    const { current_blog, loading } = useSelector(blogSelector)
    const { id } = useParams()

    console.log("Prop ", id);
    useEffect(() => {
        dispatch(fetchOneBlog(id))
    }, [dispatch])

    return (
        <div className='py-16 md:py-24 bg-white'>
            <section className="container px-4 mx-auto " style={{ fontFamily: 'Lato' }} >
                <div className='md:max-w-4xl mx-auto mb-12'>
                    <p className='mb-4 text-3xl md:text-5xl  text-center leading-tight text-teal-900 font-bold tracking-tighter'>{current_blog?.title}</p>
                    <p className='text-xl text-teal-700  text-center capitalize'>{current_blog?.sub_title}</p>

                    <img className="h-full w-full object-cover rounded-lg mt-5" src={current_blog?.image} alt="" />

                    <div className="px-4 mt-10 text-gray-700" >
                        <Interweave content={current_blog?.description} />
                    </div>
                </div>
            </section>
        </div>
    )
}

