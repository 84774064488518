import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { keyUri, config } from '../key'

const initialState = {
  all_blog: [],
  current_blog: null,
  loading: false,
  hasError: false,
}

export const blogSlice = createSlice({

  name: 'blog',
  initialState,

  reducers: {
    getblog: state => {
      state.loading = true;
    },

    getAll_blog_success: (state, { payload }) => {
      console.log(payload);
      state.loading = false
      state.all_blog = payload
    },

    get_blog_Failure: (state) => {
      state.loading = false
      state.hasError = true
    },

    getCurrentSuccess: (state, { payload }) => {
      state.loading = false
      state.current_blog = payload

    },

  },
})

export const { getblog, getAll_blog_success, getCurrentSuccess, get_blog_Failure } = blogSlice.actions;
export const blogSelector = state => state.blog;

export const fetchAllblog = () => async dispatch => {
  dispatch(getblog())
  try {
    const { data } = await axios.get(keyUri.BACKEND_URI + `/blog?sort=date&order=desc`)
    console.log({ data });
    dispatch(getAll_blog_success(data));

  } catch (error) {
    dispatch(get_blog_Failure())
  }
};



export const fetchOneBlog = (id) => async dispatch => {

  dispatch(getblog())
  try {

    const { data } = await axios.get(keyUri.BACKEND_URI + `/blog/${id}`, config)

    console.log({ data });

    dispatch(getCurrentSuccess(data));
  } catch (error) {

    dispatch(get_blog_Failure())
  }
};



export default blogSlice.reducer;