import React from 'react'

import BannerInvestors from "./../../images/pages/pageheaders/banner_investors.jpg";
import BannerInvestorsMobile from "./../../images/pages/pageheaders/banner_investors_m.jpg";
import InvestorsImg from '../../images/investors/investors.jpg';
import PageHeader from "../global/pageheader";

export default function ShareHoldingPattern() {

    return (
        <div className="container-fluid">
            <PageHeader img={BannerInvestors} img_mobile={BannerInvestorsMobile} head="Shareholding Pattern"></PageHeader>
            <div className="card mt-2 mb-2 shadow" >
                <div className="card-header">
                   Share Hoding Pattern
                </div>
                <div className="card-body">
                    <div className="mt-2 mb-2" >
                    <a href="/investors/shpattern/Shareholdingpattern13032024.pdf"  className="row ms-5">Pattern 13.03.2024</a>
                    <a href="/investors/shpattern/Shareholdingpattern31032024.pdf" className="row ms-5">Pattern 31.03.2024</a>
                 
                    </div>
                </div>
            </div>
        </div>
    );
}