import { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchOneJob, jobSelector } from "../../api/jobs";
import { Interweave } from "interweave";
import { NavLink } from "react-router-dom";

import factory from '../../images/job.png'
import PageHeader from "../global/pageheader";

export default function JobPage() {
    const dispatch = useDispatch()
    const { currentjob } = useSelector(jobSelector)
    const { id } = useParams()

    console.log(currentjob)

    useEffect(() => {
        dispatch(fetchOneJob(id))
    }, [dispatch])

    return (
        <div className="container">
            <PageHeader img={factory}></PageHeader>
            <div className='text-center'><NavLink to="/">Home</NavLink> / <NavLink to="/Careers">Careers</NavLink> / <NavLink to={`/jobs/${currentjob?._id}`}>{currentjob?.title}</NavLink></div>
            {currentjob ? (
                <div className="card shadow m-5 mb-5">
                    <div className="card-body">
                        <p className='display-6'>{currentjob?.sub_title}</p>
                        <div className="mx-2">
                            <Interweave content={currentjob?.description} />
                        </div>
                    </div>
                    <div className="row mx-auto w-50 mb-3">
                        <a href="/form-one" className="btn btn-primary">Apply Now</a>
                    </div>
                </div>
            ) : ''}
        </div>
    )

}






