import React from 'react';
import ST4 from '../../../images/products/silkypolisher/st4/3.png';
import ST6 from '../../../images/products/silkypolisher/st6/1.jpg';
import Turn from '../../../images/turn.png';

import { Link } from 'react-router-dom';
import PageHeader from '../../global/pageheader'
import ProductTile from '../productTile';

export default function Silkypolisher() {

  const silkypolisher = [
    {
      id: 1,
      img: ST4,
      title: 'Silky Polisher ST-4',
      link: '/silky-polisher-st-4'
    },
    {
      id: 3,
      img: ST6,
      title: 'Silky Polisher ST-6',
      link: '/silky-polisher-st-6'
    },
  ]

  return (
    <div className="container-fluid">
      <PageHeader img={Turn} head="Silky Polisher"></PageHeader>

      <div className="container">
        <div className="row">
          {
            silkypolisher.map((item, i) => {
              return ProductTile({
                i,
                link: item.link,
                title: item.title,
                img: item.img
              })
            })
          }
        </div>
        <div className="my-5">
          <div className="row">
            <h2 className=" fw-bold">Why Silky Polisher ?</h2>
            <p className="">Silky Polisher is required after the whitening process to give the rice grain its shining appearance. The rice grains, which become un-even in texture during the whitening process after removal of bran layer, are polished to give it an even and shiny texture and the fully polished product leaves the machine through its outlet.</p>
          </div>

          <div className="row">
            <h2 className="fw-bold">What are the usage of  Silky Polisher ?</h2>
            <p className="">Silky polisher is used to polish the rice giving it a Shiny and Even texture.</p>
          </div>

          <div className="row">
            <h2 className="fw-bold">What are the benefits of Silky Polisher ?</h2>
            <p className="">Silky polisher is used to polish the rice making it acceptable for market with a shiny surface. It removes all the uneven texture from the rice after the whitening process has been completed.</p>
          </div>
        </div>
      </div >
    </div>
  )
}
