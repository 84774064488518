import React from 'react'

import BannerInvestors from "./../../images/pages/pageheaders/banner_investors.jpg";
import BannerInvestorsMobile from "./../../images/pages/pageheaders/banner_investors_m.jpg";
import PageHeader from "../global/pageheader";
import BoardComposition from './boardComposition';


export default function CFCS() {

    return (
        <div className="container-fluid">
            <PageHeader img={BannerInvestors} img_mobile={BannerInvestorsMobile} head="CFO/CS"></PageHeader>
            <div className="container">
                <div className="card mt-2 mb-2 shadow text-center " >
                    <div className="card-header">
                        CFO/CS
                    </div>
                    <div className="card-body mt-5 ms-5">
                        <div className="row fw-bold">CFO/CS</div>
                        <div className="row mt-1">Mrs. Shweta Baisla</div>
                        <div className="row">(Chief Financial Officer)</div>
                    </div>
                        <div className="card-body mt-2 ms-5">
                        <div className="row">Ms.Supriya</div>
                        <div className="row">(Company Secretory)</div>
                    </div>
                </div>
            </div>
        </div>
    );
}