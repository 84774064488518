import React, { useLayoutEffect } from 'react'
import Gradingg from '../../../images/Gradingg.png'
import LG from '../../../images/products/lengthgrader/5.jpg'

import PageHeader from '../../global/pageheader'
import ProductTile from '../productTile'

export default function Lengthgrader() {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const lengthgrader = [
    {
      id: 3,
      img: LG,
      title: 'Length Grader LG',
      link: '/length-grader-lg-01'
    },


  ]

  return (
    <div className="container-fluid">
      <PageHeader img={Gradingg} head="Length Grader"> </PageHeader>
      <div className="container">
        <div className="row">
          {
            lengthgrader.map((item, i) => {
              return ProductTile({
                i,
                link: item.link,
                title: item.title,
                img: item.img
              })
            })
          }

        </div>
        <div className="my-5">
          <div className="row mt-2">
            <h2 className="fw-bold">Why Length Grader ?</h2>
            <p className="">Grader is used to separate rice on the basis of thickness, Rice is gravity fed into the round sieves which continuously rotates. The small fraction rice which is under size passes through the screen & is discharged through the hopper & the remaining rice are delivered to the outlet for further processing.</p>
          </div>

          <div className="row mt-2">
            <h2 className="fw-bold">What are the usage of Length Grader ?</h2>
            <p className="">
              <ul>
                <li>Length Grader is used to separate the fine rice based on the length of the grain into different quality as per requirement.</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
