import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { keyUri, config } from '../key'

const initialState = {
  all_events: [],
  current_events: null,
  loading: false,
  hasError: false,
}

export const eventsSlice = createSlice({

  name: 'events',
  initialState,

  reducers: {
    getevents: state => {
      state.loading = true;
    },

    getAll_events_success: (state, { payload }) => {
      console.log(payload);
      state.loading = false
      state.all_events = payload
    },

    get_events_Failure: (state) => {
      state.loading = false
      state.hasError = true
    },

    getCurrentSuccess: (state, { payload }) => {
      state.loading = false
      state.current_events = payload

    },

  },
})

export const { getevents, getAll_events_success, getCurrentSuccess, get_events_Failure } = eventsSlice.actions;

export const eventsSelector = state => state.events;

export const fetchAllevents = (id) => async dispatch => {
  dispatch(getevents())
  try {
    const { data } = await axios.get(keyUri.BACKEND_URI + `/events?filter=${id}`)
    console.log({ data });
    dispatch(getAll_events_success(data));


  } catch (error) {
    dispatch(get_events_Failure())
  }
};

export const fetchOneEvents = (id) => async dispatch => {
  dispatch(getevents())
  try {
    const { data } = await axios.get(keyUri.BACKEND_URI + `/event/${id}`, config)

    console.log({ data });

    dispatch(getCurrentSuccess(data));
  } catch (error) {

    dispatch(get_events_Failure())
  }
};



export default eventsSlice.reducer;