import React, { useLayoutEffect } from 'react'
import HG from "./../../images/pages/aboutus/herald-global.png";
import PB from "./../../images/pages/aboutus/prestigous-brands.png";
import IO from "./../../images/pages/aboutus/industry-outlook.png";


export default function Awards() {
  return (
    <div className="container mb-5">
      <div className="row">
        <div className="col text-center border-bottom mb-2">
          <span className="display-6">Awards & Recognitions</span>
        </div>
      </div>
      <div className="row ">
        <div className="col-4 tex-center">
          <img src={IO} className="img-responsive rounded shadow my-4 ms-2" height={'300px'} />

          <div className="fw-bold mt-2">Top 10 Industrial Service Providers</div>
        </div>
        <div className="col-8 text-center border-start">
          <img src={HG} className="img-responsive rounded shadow my-4 me-4" height={'300px'} />
          <img src={PB} className="img-responsive rounded shadow my-4 ms-2" height={'300px'} />
          <div className="fw-bold mt-2">Prestigious Brands Asia 2023</div>
        </div>
      </div>
    </div>
  )
}