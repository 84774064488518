import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { keyUri } from '../key';

export const initialState = {

    loading: false,
    hasErrors: false,
    all_jobs: [],
    currentjob: null,
}

export const JobSlice = createSlice({
    name: "jobs",
    initialState,
    reducers: {

        getJob: state => {

            state.loading = true
        },

        getJobSuccess: (state, { payload }) => {
            state.loading = false
            state.all_jobs = payload

        },

        getCurrentJob: (state, { payload }) => {
            console.log({ k: payload });

            state.loading = false
            state.currentjob = payload

        },
        getJobFailure: (state, { payload }) => {
            state.loading = false
            state.all_jobs = payload

        },
    }
})

export const { getJob, getJobSuccess, getCurrentJob, getJobFailure } = JobSlice.actions
export const jobSelector = state => state.jobs
export default JobSlice.reducer

const config = {
    headers: {
        'Content-Type': 'application/json'
    }
};

export const fetchAllJob = () => async dispatch => {
    const key = "Job";
    dispatch(getJob());

    try {
        const { data } = await axios.get(keyUri.BACKEND_URI + '/jobs');
        dispatch(getJobSuccess(data))
    } catch ({ response }) {
        dispatch(getJobFailure())
    }
}

export const fetchOneJob = (id) => async dispatch => {
    dispatch(getJob())
    try {
        const { data } = await axios.get(keyUri.BACKEND_URI + `/job/${id}`);
        dispatch(getCurrentJob(data))
    } catch ({ response }) {
        dispatch(getJobFailure())
    }
}








