import React, { useState, useLayoutEffect } from 'react'
import Nared from '../images/nared.png'
import Shweta from '../images/shweta.JPG'
import Vasu from '../images/vas.png'

import { NavLink } from 'react-router-dom'

import BannerManagement from "./../images/pages/pageheaders/banner_management.jpg";
import BannerManagementMobile from "./../images/pages/pageheaders/banner_management_m.jpg";
import PageHeader from "./global/pageheader"

function TeamMember(props) {
  return (
    <div className="card shadow col-sm-12 col-md-6 m-4" >
      <div className="card-header text-center">
        <h3 className="m-1">{props.name}</h3>
      </div>
      <div className="card-body">
        <div className="row justify-content-center">
          <img className="img-fluid" src={props.img} style={{ maxHeight: '200px', maxWidth: '200px' }}></img>
          <div className="card-title text-center fw-bold">{props.title}</div>
        </div>
        <p className="mt-3 mb-2">{props.desc}</p>
        <br />
      </div>
      <div className="card-footr mx-auto">
        <a className="btn btn-primary float-right m-3"
          href={props.navTo}>Read More...</a>
      </div>
    </div>
  )
}

export default function Teams() {
  const [imgs, setImg] = useState(Nared);

  return (
    <div className="container-fluid ">
      <PageHeader img={BannerManagement} img_mobile={BannerManagementMobile} head="Management Team"></PageHeader>
      <div className="mt-5 row justify-content-center">
        <div className="card-group" style={{ maxWidth: '900px' }}>
          <TeamMember img={Nared} name="Narendra Kumar" title="Founder" desc="“Agriculture is vital for the nation’s development, and so are the people related to it. “
Mr Narendra Kumar founded Sona Foods, now known as Sona Machinery, in 1995 to empower the food processing industry of India with its customized rice milling plants and solutions that fit customers’ resources and market trends.“" navTo="/narendra#manage-info"></TeamMember>
          <TeamMember img={Vasu} name="Vasu Naren" title="Chairman &amp; Managing Director" navTo="/vasu#manage-info" desc="”With the modernization of agricultural technologies, the food output has increased many folds”. Mr Vasu Naren, the Chairman &amp; Managing Director of Sona Machinery, provided a new direction to the company with his enthusiasm and progressive convictions.“"></TeamMember>
        </div>
      </div>
    </div>
  )
}
