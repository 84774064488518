import React from 'react'

import BannerInvestors from "./../../images/pages/pageheaders/banner_investors.jpg";
import BannerInvestorsMobile from "./../../images/pages/pageheaders/banner_investors_m.jpg";
import PageHeader from "./../global/pageheader";
import BoardComposition from './boardComposition';


export default function RTA() {

    return (
        <div className="container-fluid">
            <PageHeader img={BannerInvestors} img_mobile={BannerInvestorsMobile} head="Investors"></PageHeader>
            <div className="container">
                <div className="card mt-2 mb-2 shadow text-center " >
                    <div className="card-header">
                        RTA Information
                    </div>
                    <div className="card-body mt-5 ms-5">
                        <div className="row mt-1 fw-bold">Maashitla Securities Pvt. Limited</div>
                        <div className="row">451, Krishna Apra Business Square, Netaji Subhash Place, Pitampura, Delhi-110034                        </div>
                        <div className="row mt-1 fw-bold">Contact</div>
                        <div className="row">011-45121798/ 01145121795/ 01145121796
                        </div>                         <div className="row mt-1 fw-bold">Email</div>
                        <div className="row">contact@maashitla.com

                        </div>                        
                    </div>
                </div>

            </div>
        </div>
    );
}