
import I1603 from "./../../images/products/blending/1.jpg";
import I1634 from "./../../images/products/blending/2.jpg";
import I1646 from "./../../images/products/blending/3.jpg";
import I1677 from "./../../images/products/blending/4.jpg";
import I1724 from "./../../images/products/blending/5.jpg";
import i6 from "./../../images/products/blending/6.jpg";

const blenderImages = [
    { original: I1603, thumbnail: I1603 },
    { original: I1603, thumbnail: I1634 },
    { original: I1646, thumbnail: I1646 },
    { original: I1677, thumbnail: I1677 },
    { original: I1724, thumbnail: I1724 },
]

const blenders = [
    {
        id: 'blending-RKB30A',
        title: 'FRK BLENDING MACHINE',
        series: 'BLENDING MACHINE',
        images: blenderImages,
        video: "https://www.youtube.com/embed/Rf8HkDra5ug",
        description: "Weighing, Mixing and Bagging can be achieved in Single Station. With the accurate weighing, RKB30A " +
            " Provides perfect blending with Stainless Steel Ribbon Blender that helps to provide a homogeneous " +
            " mixture without damaging rice.",
        usp: ['HMI Display to set the required blending percentage & mixing time',
            'Easy Calibration Process',
            'Reports can be stored in USB',
            'Complete Automatic Machine',
            'Easy operation and negligible maintenance'],
        specification: [{ 'Product Name': 'Blending' }, { 'Model': 'RKB30A' }, { 'Capacity (TPH) ': '4 - 10' }, { 'Mixer Motor': '1 HP' }, { 'Air Presure': '10 Bar' }, { 'Accuracy': '% 0.1 / Batch' }, { 'Voltage(V) / Frequency (Hz)': '415 / 50' }],
        bisCertified: true,
        category: 'Blender',
        categoryLink: '/multi-grader'
    },
]

export default blenders;