
import { Link } from 'react-router-dom';

export default function ProductCard(props) {

  return (
    <div className="col m-2">
      <div className="row">
        <Link to={props.furl ? props.tgt : '/products' + props.tgt} >
          <img src={props.img} className="img-fluid shadow" style={{ maxHeight: '200px', maxWidth: '200px' }}></img>
        </Link>
      </div>
      <div className="row d-flex justify-content-center">
        <Link to={props.furl ? props.tgt : '/products' + props.tgt} >
          <span className="btn btn-primary btn-sm mt-2 mb-2 ">{props.desc ? props.desc : 'Details'}</span>
        </Link>
      </div>
    </div>
  )

}