import React, { useLayoutEffect } from 'react';
import SHEL from '../../../images/products/paddyhusker/1.jpg';
import Turn from '../../../images/turn.png';

import { Link } from 'react-router-dom';
import PageHeader from '../../global/pageheader';
import ProductTile from '../productTile';

export default function Paddyhusker() {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const paddyhusker = [
    {
      id: 2,
      img: SHEL,
      title: 'Paddy Husker Shel - 125',
      link: '/paddy-husker-shel-125'
    },
  ]

  return (
    <div className="container-fluid">
      <PageHeader img={Turn} head="Paddy Husker"></PageHeader>

      <div className="container">
        <div className="row">

          {
            paddyhusker.map((item, i) => {
              return ProductTile({
                i,
                link: item.link,
                title: item.title,
                img: item.img
              })
            })
          }
        </div>
        <div className="my-5">
          <div className="row">
            <h2 className="fw-bold">Why Paddy Husker ?</h2>
            <p className="">Paddy De Husker or Paddy Sheller is used to break open the Paddy Shell in order to obtain rice. The husker is one of the crucial machines as it has to be regularly monitored and adjusted according to the flow of the paddy and adequate pressure has to be maintained to get the optimum output of Rice.</p>
          </div>
          <div className="row">
            <h2 className="fw-bold">What are the usage of Paddy Husker ?</h2>
            <p className="">Paddy Huskers are used to remove shells from the rice kernel to obtain the rice out of it.</p>

          </div>
        </div>
      </div>
    </div>
  )
}
