import React from 'react'
import Vasu1 from '../../images/vas.png'
import BannerManagement from "./../../images/pages/pageheaders/banner_management.jpg";
import BannerManagementMobile from "./../../images/pages/pageheaders/banner_management_m.jpg";
import PageHeader from "./../global/pageheader";

export default function Vasu() {
  return (
    <div className="container">
    <PageHeader img={BannerManagement} img_mobile={BannerManagementMobile} head="Management Team"></PageHeader>
      <div className="clearfix mt-5">
        <div className="m-2 col-md-6 float-md-end mb-3 ms-md-3">
          <img className=" img-fluid rounded " src={Vasu1} alt="" />
          <div className="row">
            <span className="text-center fw-bold  fs-5">Vasu Naren</span>
            <span className="text-center fs-6">Chairman &amp; Managing Director</span>
          </div>
        </div>
        <div class="text-justify">
          <p className="">“With the modernization of agricultural technologies, the food output has increased many folds”.</p>
          <p id="manage-info">Mr. Vasu Naren is the Chairman &amp; Managing Director of Sona Machinery Ltd. Today,
            Sona Machinery is one of the country’s leading Original Equipment Manufacturers
            (OEM) and supplier of a diversified range of Agri-Machinery Processing &amp; EPC
            Solutions for Grain based Distilleries. The Group has its presence and reaches not
            only across several cities in India but also across the Globe.</p>
          <p>
            Sona Machinery, under the leadership of Mr. Naren, has crafted itself into a high-
            tech world-class manufacturing facility in Tronica City, Ghaziabad, India with a
            sprawling 10000 sq. mt. of the production facility under its belt, 350+ professionals,
            an ISO 9001:2015 certification and served 10000+ customers in many countries
            across Globe and a Network of Sales and Service Professionals Pan India.</p>
          <p>
            Mr. Naren has a rich experience of over a decade in the Engineering Industry and is
            responsible to lead the company from being an Agro-machinery manufacturer to now
            providing EPC Solutions for Grain Based Distilleries Industry (turn-key plant set-up
            solutions in Unloading &amp; Milling). As the torchbearer of the Group, he is heavily
            invested in identifying and unlocking the untapped markets to provide premium, cost-
            effective high-end technology machinery to the much-needed millers and eventually
            contribute to the drive “food for all”.
          </p>

          <p>
            He leads with a vision to expand and leverage Sona Machinery’s 27+ year legacy
            while still preserving the old values on which Sona Machinery was established –
            Superior affordable customized equipment &amp; solutions and on-time turnkey delivery.
            It has helped Sona Machinery being recognised at several National &amp; International
            Industry Forums and won accolades like <span class="fw-bold">“Top 10 Industrial Service Providers of
            2022” </span>by Industry Outlook, <span class="fw-bold"> “Prestigious Brand of Asia – Agri Machinery
            Manufacturing” </span> by Barc Asia.
          </p>
        </div>
      </div>
    </div>
  )
}
