
import S49919 from "./../../images/products/silkypolisher/st4/1.png";
import S49945 from "./../../images/products/silkypolisher/st4/2.png";
import S49949 from "./../../images/products/silkypolisher/st4/3.png";
import S49971 from "./../../images/products/silkypolisher/st4/4.png";

import S60276 from "./../../images/products/silkypolisher/st6/1.jpg";
import S60278 from "./../../images/products/silkypolisher/st6/2.jpg";
import S60282 from "./../../images/products/silkypolisher/st6/3.jpg";

const st4Images = [
    { original: S49919, thumbnail: S49919 },
    { original: S49945, thumbnail: S49945 },
    { original: S49949, thumbnail: S49949 },
    { original: S49971, thumbnail: S49971 },
]

const st6Images = [
    { original: S60276, thumbnail: S60276 },
    { original: S60278, thumbnail: S60278 },
    { original: S60282, thumbnail: S60282 },
]

const silkypolisher = [
    
    {
        id: 'silky-polisher-st-4',
        title: 'Silky Polisher ST-4',
        series: 'Silky Polisher ST',
        images: st4Images,
        video: "https://www.youtube.com/embed/Rf8HkDra5ug",
        description: "The rice falls into the inlet and is conveyed through the polishing process with the help of a feed screw. A uniform water film is applied on the rice grains via a mixing tube using a nozzle. The rice is gently rubbed against itself by a rotating cam shaft, through which the surface is made shiny by polishing.",
        usp: ['Precise Stainless steel sieve that ensures the accuracy of separation', 'An integrated cleaning brush counter rotates with the drum to avoid choking of slots', 'Additional conditioner is added to this model which provides more moisture to the rice, resulting in better shine, texture & output', 'Customized selection of sieves to suit various application'],
        specification: [{ 'Product Name': 'Silky Polisher' }, { 'Model': 'ST 4' }, { 'Capacity': '3 - 4 TPH' }, { 'Power Consumption (HP) (Recommended)': '60 HP' }, { 'Weight (Kg)': '1100' }, { 'Dimensions (L*W*H in mm)': '1624*700*1550' }],
        technology: ['The rice falls into the inlet and is conveyed through the polishing process with the help of a feed screw. A uniform water film is applied on the rice grains via a mixing tube using a nozzle. The rice is gently rubbed against itself by a rotating cam shaft, through which the surface is made shiny by polishing.'],
        category: 'Silky Polisher',
        categoryLink: '/silky-polisher'
    },
    {
        id: 'silky-polisher-st-6',
        title: 'Silky Polisher ST-6',
        series: 'Silky Polisher ST',
        images: st6Images,
        video: "https://www.youtube.com/embed/Rf8HkDra5ug",
        description: "Silky Polisher is used to polish the rice and giving it a shining appearance, it is used after the whitening of rice grains. The rice falls into the inlet and is conveyed through the polishing process with the help of a feed screw and the fully polished product leaves the machine through the outlet. The starch produced during the process removed from the rice is collected through the aspiration system and thrown out.",
        usp: [
            "Precise Stainless steel sieve that ensures the accuracy of separation",
"An integrated cleaning brush counter that rotates with the drum to avoid choking of slots",
"An Additional conditioner is added to this model which provides more moisture to the rice, resulting in better shine, texture & output",
"It has a customized selection of sieves to suit various applications as per your process and requirements"
        ],
        specification: [{ 'Product Name': 'Silky Polisher' }, { 'Model': 'ST 6' }, { 'Capacity': '6-7 TPH' }, { 'Power Consumption (HP) (Recommended)': '75+5 HP' }, { 'Weight (Kg)': '1500' }, { 'Dimensions (L*W*H in mm)': '1624*700*2100' }],
        technology: ["The rice falls into the inlet and is conveyed through the polishing process with the help of a feed screw. A uniform water film is applied on the rice grains via a mixing tube using a nozzle. The rice is gently rubbed against itself by a rotating cam shaft, through which the surface is made shiny by polishing."],
        category: 'Silky Polisher',
        categoryLink: '/silky-polisher'
    },
];

export default silkypolisher;