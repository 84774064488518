import HFI2076 from "./../../images/products/huskfan/1.jpg";
import HFI2085 from "./../../images/products/huskfan/2.jpg";
import HFI2097 from "./../../images/products/huskfan/3.jpg";
import HFI2108 from "./../../images/products/huskfan/4.jpg";
import HFI2116 from "./../../images/products/huskfan/5.jpg";
import HFI2144 from "./../../images/products/huskfan/6.jpg";
import HFSN from "./../../images/products/huskfan/sn/1.jpg";

const huskFanImages = [
    { original: HFI2076, thumbnail: HFI2076 }, 
    { original: HFI2085, thumbnail: HFI2085 }, 
    { original: HFI2097, thumbnail: HFI2097 }, 
    { original: HFI2108, thumbnail: HFI2108 }, 
    { original: HFI2116, thumbnail: HFI2116 }, 
    { original: HFI2144, thumbnail: HFI2144 }, 
]

const husFanSnImaes = [
    { original: HFSN, thumbnail: HFSN }, 
]

const huskfan = [
    {
        id: 'husk-fan',
        title: 'HuskFan',
        series: 'HUSK FAN',
        images: huskFanImages,
        video: "https://www.youtube.com/embed/Rf8HkDra5ug",
        description: 'Husk Aspirators are designed to separate husk from the De-hulled paddy. They are equipped with dynamically balanced blower which helps in separation of the husk. The paddy flow through the adjustable flips to deliver husk free rice and paddy. The husk is conveyed through the pipeline to the husk yard.',
        usp: [
            'Suitable for all types of Paddy.',
            'Stainless Steel Impellers & baffles for long grains separation.',
            'Screw conveyor & special hopper for immature paddy separation.',
            'Better husk sorting by vacuum type separation.',
            'Better recovery of broken rice and immature grains.',
            'Enclosed body for dust-free surroundings'
        ],
        specification: [{ 'Product Name': 'Husk Fan' }, { 'Model': 'HuskFan' }, { 'Capacity': '4 - 6 TPH' }, { 'Speed (RPM)': '1440' }, { 'Power Consumption (HP) (Recommended)': '5 HP' }, { 'Weight (Kg)': '180' }, { 'Dimensions (L*W*H in mm)': '2046*1570*1330' }],
        technology: ['Husk Aspirators are equipped with dynamically balanced blower which helps in separation of the husk. The paddy flow through the adjustable flips to deliver husk free rice and paddy. The husk is conveyed through the pipeline to the husk yard.'],
        category: 'Husk Fan',
        categoryLink: '/husk-fan'
    },
    {
        id: 'husk-fan-sn',
        title: 'HuskFan-SN',
        series: 'HUSK FAN',
        images: husFanSnImaes,
        video: "https://www.youtube.com/embed/Rf8HkDra5ug",
        description: 'Husk Aspirators are designed to separate husk from the De-hulled paddy. They are equipped with dynamically balanced blower which helps in separation of the husk. The paddy flow through the adjustable flips to deliver husk free rice and paddy. The husk is conveyed through the pipeline to the husk yard.',
        usp: ['Suitable for all types of Paddy.',
        'Stainless Steel Impellers & baffles for long grains separation.',
        'Screw conveyor & special hopper for immature paddy separation.',
        'Better husk sorting by vacuum type separation.',
        'Better recovery of broken rice and immature grains.'
        ],specification: [{ 'Product Name': 'Husk-Fan' }, { 'Model': 'HuskFan-SN' }, { 'Capacity': '4 - 5 TPH' }, { 'Speed (RPM)': '1440' }, { 'Power Consumption (HP) (Recommended)': '5 HP' }, { 'Weight (Kg)': '180' }, { 'Dimensions (L*W*H in mm)': '1518*2046*1328' }],
        technology: ['Huskers function on the technology of breaking open the shells for the paddy by pressing it in between two rubber rolls (to better understand it we can take the example of Peanut. We press open the peanut shell by pressing it with our fingers to obtain the nut out of it). The paddy is fed using a vibro motor in the straight line into the rubber rolls, which crunch the paddy and open it from both the ends. There is an aspiration system installed in the machine, which sucks the light weighted husk and dust from the crop.'],
        category: 'Husk Fan',
        categoryLink: '/husk-fan'
    },
];

export default huskfan;