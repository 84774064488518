import MultiProducts from "../multiproducts";

import I3513 from "./../../../images/products/materialhandling/screwconveyor/1.jpg";
import I3519 from "./../../../images/products/materialhandling/screwconveyor/2.jpg";
import I3522 from "./../../../images/products/materialhandling/screwconveyor/3.jpg";
import I3530 from "./../../../images/products/materialhandling/screwconveyor/4.jpg";
import I3535 from "./../../../images/products/materialhandling/screwconveyor/5.jpg";
import I3541 from "./../../../images/products/materialhandling/screwconveyor/6.jpg";

let screwImages = [
    { original: I3513, thumbnail: I3513 },
    { original: I3519, thumbnail: I3519 },
    { original: I3522, thumbnail: I3522 },
    { original: I3530, thumbnail: I3530 },
    { original: I3535, thumbnail: I3535 },
    { original: I3541, thumbnail: I3541 },
];

const data =

{
    id: 'Screw_Conveyor',
    title: 'Screw Conveyor',
    series: 'SC',
    images: screwImages,
    video: "",
    description: 'Screw conveyors are used to convey bulk material from almost any position (vertical , horizontal , or at an incline ) and are one of the most reliable methods of conveyance for moving materials.',
    usp: ['Discharge Spouts', 'Supporting Feet & Saddles', 'Durable', 'Quick installation & no regular maintenance'],
    specification: [
        ['Grain Feeder', 'Model', 'Capacity Flour (TPH)', 'Capacity Bran (TPH)'],
        ['Grain Feeder', 'SC 200', '5', '5'],
        ['Grain Feeder', 'SC 250', '10', '10'],
        ['Grain Feeder', 'SC 300', '18', '18'],
    ],
    category: 'Screw Conveyor',
    categoryLink: '/products/screw-conveyor-sc-300'
}



export default function ScrewConveyors() {

    return (
        <MultiProducts currentProduct={data}></MultiProducts>
    )
}