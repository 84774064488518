import React, { useLayoutEffect } from 'react'
import Gradingg from '../../../images/Gradingg.png'
import PCS from '../../../images/products/precleaners/pcs/4.jpg'
import PCS2 from '../../../images/products/precleaners/pcs2dr/4.jpg'
import HC from  '../../../images/products/precleaners/hc/1.jpg'
import DRC25 from '../../../images/products/precleaners/drc/5.jpg'
import PageHeader from '../../global/pageheader'
import ProductTile from '../productTile'

export default function Precleaner() {

  const precleaner = [
    {
      id: 1,
      img: DRC25,
      title: 'Pre Cleaner - DRC 25',
      link: '/pre-cleaner-drc-25'
    },
    {
      id: 2,
      img: HC,
      title: 'Pre Cleaner - HC Series',
      link: '/pre-cleaner-hc-60'
    },
    {
      id: 3,
      img: PCS,
      title: 'Pre Cleaner - PCS',
      link: '/pre-cleaner-pcs-dr'
    },
    {
      id: 4,
      img: PCS2,
      title: 'Pre Cleaner - PCS 2DR',
      link: '/pre-cleaner-pcs-2dr',
    },
  ]

  return (
    <div className="container">
      <PageHeader img={Gradingg} head="Pre Cleaner"></PageHeader>
      <div className="row my-4">

        {
          precleaner.map((item, i) => {
            return ProductTile({
              i, 
              link: item.link,
              title: item.title,
              img: item.img,
              furl: item.furl
            })
          })
        }
      </div>
      <div className="">
        <div className="row mt-4">
          <h2 className="fw-bold">Why Pre Cleaner ?</h2>
          <p className="">The Crop or Raw Material from farms brought into the mill has to go through the Pre-Cleaning process as there are a lot of Dust, Mud balls, big impurities are present and it should be cleared prior to storage or further process.Pre-Cleaning is used for clearing the dust or big impurities in large quantities and further cleaning and destoning is still required to remove smaller impurities.</p>
        </div>
        <div className="row mt-4">
          <h2 className="fw-bold ">What are the Usage of Pre Cleaner ?</h2>
          <p className="">Pre-Cleaners are used to remove dust, Mud Balls, Sachse, rocks, ropes, rodents bodies, etc.</p>

        </div>
        <div className="row mt-4">
          <h2 className="fw-bold">What are the benefits of Pre Cleaner ?</h2>
          <p className="">It is beneficial to pre-clean the raw material before storage or before sending the crop for further process as the majority of impurities are removed in this process and it also saves the further equipment and machines from wear & tear.</p>
        </div>
      </div>
    </div >
  )
}
