import React from 'react';
import Gradingg from '../../../images/Gradingg.png';
import SIZER from '../../../images/products/sizer/1.jpg';

import { Link } from 'react-router-dom';
import PageHeader from "./../../global/pageheader";
import ProductTile from '../productTile';

export default function Sizer() {

  const sizer = [
    {
      id: 2,
      img: SIZER,
      title: 'Sizer',
      link: '/sizer-612'
    },


  ]

  return (
    <div className="container-fluid">
      <PageHeader img={Gradingg} head="Sizer"></PageHeader>
      <div className="container">
        <div className="row">

          {
            sizer.map((item, i) => {
              return ProductTile({
                i,
                link: item.link,
                title: item.title,
                img: item.img
              })
            })
          }
        </div>
        <div className="my-5">
          <div className="row">
            <h2 className=" fw-bold">Why Sizer ?</h2>
            <p className="">Sizer is used to separate polished rice into different types of rice based on their thickness in the agricultural industry. This machine also separates the oversize rice, half broken and small broken from head rice. The machine is specifically designed and best suited for Raw rice, Steam rice, Par-boiled rice, Boiled rice, and Basmati rice</p>
          </div>

          <div className="row">
            <h2 className=" fw-bold">What are the usage of Sizer ?</h2>
            <p className="">The sizer in rice processing efficiently separates grains based on size. Gravity-fed into rotating sieves, smaller fractions pass through the screen and are discharged via the hopper, while properly-sized rice moves to the outlet for further processing, ensuring a uniform and high-quality end product.</p>
          </div>

          <div className="row">
            <h2 className="fw-bold">What are the benefits of Sizer ?</h2>
            <div className="ms-5">
              <li>Measure grain size & shape for grading & quality control.</li>
              <li>Separate grains by thickness for consistent quality & meeting specifications.</li>
              <li>Remove broken and damaged grains, improving final product quality.</li>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
