import React from 'react'

import BannerInvestors from "./../../images/pages/pageheaders/banner_investors.jpg";
import BannerInvestorsMobile from "./../../images/pages/pageheaders/banner_investors_m.jpg";
import InvestorsImg from '../../images/investors/investors.jpg';
import PageHeader from "../global/pageheader";
import Vasu from './../../images/vas.png';
import Shweta from './../../images/shweta.JPG';
import avMale from "./../../images/avMale.png";
import avFemale from "./../../images/avFemale.png";
import { Popover } from "bootstrap";

function BoardMember(props) {
    return (
        <div className="col centered text-center mt-2 " >
            <a data-bs-toggle="popover" data-bs-content={props.desc} trigger="hover" style={{ minWidth: '400px', maxWidth: '400px' }}>

                <img className="align-center" src={props.img} style={{ borderRadius: '50%', maxHeight: '200px', maxWidth: '200px' }}></img>
                <div className="row text-center centered mt-2">
                    <div className=""> {props.name}</div>
                    <div className="fw-bold mt-2">{props.title}</div>
                </div>
            </a>

        </div>
    )
}

export default class BoardComposition extends React.Component {

    componentDidMount() {
        [...document.querySelectorAll('[data-bs-toggle="popover"]')]
            .forEach(el => new Popover(el, {
                trigger: 'hover',
                placement: 'bottom'
            }));
    }

    render() {
        return (
            <div className="container-fluid">
                <PageHeader img={BannerInvestors} img_mobile={BannerInvestorsMobile} head="Board Composition"></PageHeader>
                <div className="card mt-2 mb-2 shadow" >
                    <div className="card-header">
                        Board Composition
                    </div>
                    <div className="card-body">
                        <div className="mt-2 mb-2" >
                            <div className="row d-flex justify-content-around">
                                <BoardMember className="mt-2" img={avMale} name="Mr. Vasu Naren" title="Promoter, Executive - Chairman & Managing Director" desc="Mr. Vasu Naren is one of the promoters of our company and has been on the Board since the beginning. With approximately 8 years of experience in milling equipment manufacturing, he offers valuable industry insights. His tenure on the Board reflects his dedication and expertise, making him an essential part of our team." />
                                <BoardMember className="mt-2" img={avFemale} name="Mrs. Shweta Baisla" title="Promoter & Executive - WTD & CFO" desc="Mrs. Shweta Baisla is a key promoter in our company. With a Master's degree in Business Administration from the University of Delhi and 3 years of industry experience. Her combination of education and experience makes her an invaluable asset in driving our company forward." />
                                <BoardMember className="mt-2" img={avMale} name="Mr. Naman Jain" title="Independent, Non-Executive" desc="Mr. Naman Jain is a fellow member of the Institute of Chartered Accountant of India. He has wide experience of 7 years in the filed of finance and taxation matters. " />
                                <BoardMember className="mt-2" img={avMale} name="Mr. Preet Kumar" title="Independent, Non-Executive" desc="Mr. Preet Kumar is an Associate member of the Institute of Company Secretaries of India. He is having overall work experience in the field of Corporate Laws and Corporate Restructuring." />
                                <BoardMember className="mt-2" img={avMale} name="Mr. Akash Kumar Bansal " title="Independent, Non Executive" desc="Mr. Akash Kumar Bansal is an Associate member of the Institute of Chartered Accountant of India.  He has an overall experience  in consultancy related to finance and direct taxation. " />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
