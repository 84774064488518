import React from 'react'
import Nared from '../../images/nared.png'
import BannerManagement from "./../../images/pages/pageheaders/banner_management.jpg";
import BannerManagementMobile from "./../../images/pages/pageheaders/banner_management_m.jpg";
import PageHeader from "./../global/pageheader";

export default function Narendra() {

  return (
    <div className="container">
    <PageHeader img={BannerManagement} img_mobile={BannerManagementMobile} head="Management Team"></PageHeader>
      <div className="clearfix mt-5">
        <div className="m-2 col-md-6 float-md-end mb-3 ms-md-3">
          <img className=" img-fluid rounded " src={Nared} alt="" />
          <div className="row">
          <span className="text-center fw-bold fs-5">Narendra Kumar</span>
          <span className="text-center fs-6">Founder</span>
          </div>
        </div>
        <p className="" id="manage-info">“Agriculture is vital for the nation’s development, and so are the people related to it “</p>
        <p className=""> Mr Narendra Kumar founded Sona Foods, now known as Sona Machinery, in 1995 to empower the food processing industry of India with its customized rice milling plants and solutions that fit customers’ resources and market trends. He saw the potential in the agro-processing sector and decided to explore its zenith further.</p>
        <p className=""> Mr Kumar, the Chairman of Sona Machinery, an ardent believer in his philosophy to ‘empower agro innovation; for the society at large’, started his striving journey from the small workshop in the lanes of an industrial area in Delhi. Today, the company has touched significant statures from the humble beginnings of machine manufacturing with its presence in more than seven nations worldwide. Armed with his arduous work and determination to excel, he consistently explored ways to improvise the machines and develop new technologies for the upliftment of the agro-processing industry of the nation and contribute towards the drive “food for all”. With very little information and awareness regarding the unorganized milling market back then, he carved the way to an internationally acclaimed rice mill machinery manufacturer with his sheer determination and compassion.</p>
        <p className=""> A notable achievement of the company was when the business moved out of the workshop to a manufacturing facility in Tronica City in Ghaziabad with modern manufacturing machines and experienced professionals. Since then, it has been scaling new heights with every passing moment. His determination to arm the millers with superior and affordable milling equipment for the betterment of the larger society, backed by his business acumen, has laid the strong foundation for the Sona Machinery to compete with the leaders in manufacturing machines on an international platform.</p>
        <p className=""> His extensive years of expertise and experience propelled success & acclamation for its top-of-line technology and gained traction for Sona Machinery in its journey to be Asia’s best milling equipment manufacturer. He visualizes possibilities and overcomes all the potential hurdles and challenges that cross his path of excellence.</p>
      </div>
    </div>
  )
}

