import React, { useLayoutEffect } from 'react';
import Spear2 from '../../images/spear2.png';
import Qualified from '../../images/legend-qualified.png';
import India from '../../images/legend-india.png';
import Years from '../../images/legend-years.png';
import Three from '../../images/legend-three.png';
import PageHeader from "./../global/pageheader";

export default function Spares() {
  return (
    <div className="container-fluid">
      <PageHeader img={Spear2} head="Original Spares & Service Support"> </PageHeader>
      <div className="container">
      <div className="row mt-4">
        <div className="col-sm-12 col-md-5 text-justify">
          <h3>Sona Machinery Solutions</h3>
          <h5 className="my-2 mt-5 ">One Stop Solution for Original Spares & Services Support</h5>
          <p className="">Sona Machinery is a customer-centric organization committed to providing authentic spare products to its millers to ensure customer satisfaction and benefit. In the agro-processing industry,
            The constant functioning of machines leads to wear and tear of various components which requires the replacement of the worn-out parts of the heavy machinery.
            which requires the replacement of the worn-out parts of the heavy machinery.Without this, the plant’s entire production and operation are hampered,Sona Machinery's Original spare parts are readily available and can be accessed through our prompt service support, dealer network, or directly.
          </p>
        </div>
        <div className="col-sm-12 col-md-3 mx-auto">
          <div className="row">
            <div className="card my-2 shadow">
              <div className="card-image text-center my-2"><img src={Qualified} className="img-fluid" style={{ maxHeight: '120px' }}></img></div>
              <div className="card-title fw-bold">Team of highly qualified engineers</div>
              <p className="">For Production of High-quality modern machines, consultancy, material handling setup, fabrication, installation & Training. </p>
            </div>
            <div className="card my-2 shadow">
              <div className="card-image text-center my-2"><img src={Years} className="img-fluid" style={{ maxHeight: '120px' }}></img></div>
              <div className="card-title fw-bold">27 years of manufacturing experience</div>
              <p className="">Expertise in Engineering, Erection, Supervision, and Commissioning<br /><br /></p>
            </div>
          </div>
        </div><div className="col-sm-12 col-md-3 mx-auto">
          <div className="row">
            <div className="card my-2 shadow">
              <div className="card-image text-center my-2"><img src={India} className="img-fluid" style={{ maxHeight: '120px' }}></img></div>
              <div className="card-title fw-bold">Pan India leader in the agro-processing industry</div>
              <p className="">We have solutions for aromatic basmati rice and non-basmati medium & short grains <br /><br /><br /></p>
            </div>
            <div className="card my-2 shadow">
              <div className="card-image text-center my-2"><img src={Three} className="img-fluid" style={{ maxHeight: '120px' }}></img></div>
              <div className="card-title fw-bold">Sona Machinery range from 3 to 10 TPH</div>
              <p className="">Cost-effective fixed price and tailored timeline to customers for a hassle-free experience <br /><br /></p>
            </div>
          </div>
        </div>
      </div>
      <div className="row my-3 mt-4" >
        <h3 className="">Sona Machinery is an innovative team</h3>
        <p className="">of professionals dedicated to improving the world through our solutions. In our quest to serve the customers the best of the products, the spare parts offered by Sona Machinery are of the highest quality, which increases the machine’s life at a reasonable and affordable price for the millers. </p>
      </div>
      <div className="row my-4">
        <h3 className="">The quality of products as per Standard</h3>
        <p className=" "> Using superior-quality original Sona Machinery spare parts in the machines will enhance the life expectancy of the products and avoid downtime. Besides, authentic and original high-quality spare parts, and transparent business engagements, we provide timely delivery to our customers with easy payment options. These are some factors that help enhance the productivity of the processing machinery and result in better yields. The spare parts are readily available through Sona Machinery’s Pan-India Dealer Network, Sales, and Service Support.</p>
      </div>
      </div>
    </div>
  )
}
