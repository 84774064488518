import { Link } from "react-router-dom";
import IMG404 from "../images/404.png"

export default function NotFound() {
    return (
        <div>
            <div className="row">
                <img src={IMG404} className="img-fluid content-center mx-auto" alt="" />
            </div>
        </div>
    )
}