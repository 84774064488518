import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'

import {keyUri} from '../key'
import {toast} from 'react-toastify'

export const initialState = {

    loading: false,
    hasErrors: false,
    resume:[],
    currentResume:null,
}

export const resumeSlice = createSlice({
  name:"resume",
  initialState,
  reducers:{

      getResume: state =>{

          state.loading = true
      },

      getResumeSuccess: (state, {payload}) =>{
        console.log("Inside this")
          state.loading = false
          state.resume = payload
          
      },

      getCurrentResume: (state, {payload}) =>{
          state.loading = false
          state.currentResume = payload
          
      },
      getResumeFailure: (state, {payload}) =>{

          state.loading = false
          state.resume = payload
          
      },

  }
})


export const {getResume, getResumeSuccess, getCurrentResume, getResumeFailure } = resumeSlice.actions

export const resumeSelector = state => state.resume

export default resumeSlice.reducer

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
};

export const createResume = (values)=> async dispatch =>{
  console.log(values);
  const key = "resume"
  dispatch(getResume())
    try{
      const {data} = await axios.post(keyUri.BACKEND_URI +'/resume', values, config);

      dispatch(fetchAllResume())
        toast.success('Thank you for your Intrest. We will get back to you soon', {
          position: "top-center",
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
          // window.location.reload()

    }
    catch(error){
      dispatch(getResumeFailure())
        toast.error('Sorry Something Went Wrong!!Please try again Later', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
    }
}

export const  fetchAllResume = () => async dispatch =>{
  const key = "resume"
  dispatch(getResume())
  
try {

    const {data} = await axios.get(keyUri.BACKEND_URI +'/resume');
    console.log(data)
    dispatch(getResumeSuccess(data))

} catch ({response}) {

    dispatch(getResumeFailure())
    console.log(response)

}

}



export const  fetchOneResume = (id) => async dispatch =>{

  dispatch(getResume())
  
try {

    const {data} = await axios.get(keyUri.BACKEND_URI +`/resume/${id}`);
    dispatch(getCurrentResume(data))

} catch ({response}) {

    dispatch(getResumeFailure())
  //   response.data && message.error({ content: response.data.msg, key, duration: 2 });

}

}



 