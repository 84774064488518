import React,{useLayoutEffect} from 'react'
import { NavLink } from "react-router-dom";
import factory from '../../../images/job.png'


export default function Salesexecutive() {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
},[]);

  return (
    <div>
<a className="block w-full bg-slate-300" href="#">
              <img className=" sm:h-96 md:h-1/3 sm:w-full md:w-full object-cover " src={factory} alt=""/>
            </a>
       
<div className="section py-12 bg-white px-12 md:px-64" style={{fontFamily:"Montserrat"}}>

  <div className="container">
    <div className="has-mw-3xl text-blue-900 mx-auto">
    <div className=' mb-8 text-lg font-normal '><NavLink to="/">Home</NavLink> / <NavLink to="/Careers">Careers</NavLink> / <NavLink to="#">Sales-Executive</NavLink></div>
  
      
      <h4 className=" text-3xl font-semibold pb-8" >Job Description</h4>
      <h5 className="text-lg font-medium ">We are looking to hire a dedicated content writer to create content for blogs, articles, product descriptions, 
social media, and the company website. The content writer's responsibilities include evaluating analytics to 
adjust content as needed, regularly updating the company's website, and promoting the company blog by 
pitching articles to various third-party platforms. You should also be able to follow editorial guidelines when 
creating content.
</h5>
      <p className="text-lg mt-4 font-medium ">To be successful as a content writer, you should keep abreast of the latest SEO techniques. Ultimately, a top performing Content Writer should be able to contribute to the development of strategies that will increase 
reader engagement.</p>
      <p className="text-lg  mt-4 font-medium ">Now that I am a UX designer, I notice that some designers tend to forget that wireframes are equally creative and technical. We are responsible for designing great ideas, but we are also responsible for creating product specifications. I admit that there can be so many details to remember that it&rsquo;s easy to lose track. To save time and energy for myself, I gathered all of my years of wireframing knowledge into a single checklist that I refer to throughout the process. And now I am sharing this knowledge with you, so that you can get back to being creative.</p>
    
      <p className=" text-3xl font-semibold pb-8 pt-8" >Responsibilities</p>

      <ul className='list-disc font-semibold pl-2 md:pl-8'>
        <li> Conducting in-depth research on industry-related topics in order to develop original content. </li>
        <li> Developing content for blogs, articles, product descriptions, social media, and the company website. </li>

        <li> Assisting the marketing team in developing content for advertising campaigns.</li>

        <li> Proofread content for errors and inconsistencies. </li>

      </ul>

    
      <p className=" text-3xl font-semibold pb-8 pt-8" >Basic Requirements</p>

      <ul className='list-disc font-semibold pl-2 md:pl-8'>
        <li>Bachelor's degree in communications, marketing, english, journalism, or related field.
 </li>
        <li>Proven content writing or copywriting experience. </li>

        <li>Working knowledge of content management systems.</li>

        <li> A portfolio of published articles. </li>
        <li>Excellent writing and editing skills.</li>
        <li>The ability to work in a fast-paced environment.</li>

      </ul>
      <div className="sm:flex items-center"><a className=" mt-5 inline-block w-full sm:w-auto py-4 px-6 mb-4 sm:mb-0  text-center font-heading font-medium text-base text-white bg-sky-600 hover:bg-indigo-900 border-sky-600 hover:border-green-600  rounded-lg transition duration-200" href="/form-one">Apply Now</a></div>


    </div>
  </div>
</div>
    </div>
  )
}
