import React, { useLayoutEffect } from 'react'


export default function Footer() {
  return (
    <footer className="container-fluid border-top border-none mt-4 pt-4" style={{ fontFamily: 'Poppins' }} >
      <div className="row">
        <div className="col-md-4 col-sm-12">
          <h4 className="mb-5 pb-3 border-bottom border-primary w-75">
            Commitment with Quality
          </h4>
          <div>
            <p>We believe in following a routing to deliver the best services and products to our customers.</p>
            <p> With a health mindset and to deliver the best. We have 100% customer satisfaction</p>
          </div>
        </div>
        <div className="col-md-4 col-sm-12 mb-5">
          <h4 className="mb-5 pb-3 border-bottom border-primary w-75">
            Our Expertise
          </h4>
          <div>
            <ul className="m-2">
              <li className="m-2">
                <span>
                  <a href="/turnkey-rice-mill-solutions">
                    Turnkey Rice Mills Setup</a>
                </span></li>
              <li className="m-2">
                <span>
                  <a href="/grain-machinery">Grain Processing Setup</a>
                </span></li>
              <li className="m-2">
                <span>
                  <a href="/epc-solutions-for-grain-unloading-and-milling">Grain Based Distillery Solutions</a>
                </span></li>
              <li className="m-2">
                <span>
                  <a href="">Bulk Material Handing</a>
                </span></li>
              <li className="m-2">
                <span>
                  <a href="/engineering-and-designing-consultation">Engineering and Designing Consultation</a>
                </span></li>
            </ul>
          </div>
        </div>
        <div className="col-md-4 col-sm-12">
          <h4 className="mb-5 pb-3 border-bottom border-primary w-75">
            Contact Us
            <span className="block max-w-xs h-0.5 bg-sky-600"></span>
          </h4>
          <div className="row">
            <i className="bi bi-geo-alt-fill col-1"></i>
            <span className="col-10">
              <p>F-16 & C-1, Sector A-3, Tronica City, Loni</p>
              <p>Ghaziabad, U.P. </p>
              <p>India 201102</p>
            </span>
          </div>
          <div className="row mt-2">
            <i className="bi bi-envelope-at col-1"></i>
            <span className="col-10">
             <a href="mailto:inquiry@sonamachinery.com">inquiry@sonamachinery.com</a>
            </span>
          </div>
          <div className="row mt-4">
            <i className="bi bi-telephone col-1"></i>
            <span className="col-10">
              <a href="tel:+91-9599-002201">+91-9599-002201</a>
            </span>
          </div>
        </div>
      </div>
      <div className="row mx-auto text-center d-md-none d-sm-block mt-4">
        <div className="col">
      <a href="https://www.linkedin.com/company/sonamachinery/?originalSubdomain=in" className="btn btn-primary btn-primary mx-1" type="button">
              <i className="bi bi-linkedin"></i>
            </a>
            <a href="https://www.instagram.com/sonamachinery/?hl=en" className="btn btn-sm btn-primary mx-1" type="button">
              <i className="bi bi-instagram"></i>
            </a>
            <a href="https://www.facebook.com/Sonamachineryltd" className="btn btn-sm btn-primary mx-1" type="button">
              <i className="bi bi-facebook"></i>
            </a>
            <a href="https://www.youtube.com/@sonamachinery" className="btn btn-primary btn-sm mx-1" type="button">
              <i className="bi bi-youtube"></i>
            </a>
            <a href="https://twitter.com/SonaMachinery" className="btn btn-primary btn-sm mx-1" type="button">
              <i className="bi bi-twitter"></i>
            </a>
            </div>
      </div>
      <div className="row mt-4 mx-auto">
        <div className="mt-1 text-center" >
          <p className="text-md font-semibold"> <a href="/terms">Terms And Conditions</a> | © 2024 Sona Machinery Ltd. All rights reserved</p>
        </div>
      </div>
    </footer>
  )
}



