import React from 'react';
import RPS20 from '../../../images/products/paddyseparators/rps20/1.jpg';
import RPS40 from '../../../images/products/paddyseparators/rps40/1.jpg';
import Turn from '../../../images/turn.png';

import { Link } from 'react-router-dom';
import PageHeader from '../../global/pageheader';
import ProductTile from '../productTile';

export default function PaddySeparator() {

  const PaddySeparator = [
    {
      id: 1,
      img: RPS20,
      title: 'Paddy Separator RPS-20',
      link: '/paddy-separator-rps-20'
    },
    {
      id: 2,
      img: RPS40,
      title: 'Paddy Separator RPS-40',
      link: '/paddy-separator-rps-40'
    },
    {
      id: 3,
      img: RPS40,
      title: 'Paddy Separator RPS-60',
      link: '/paddy-separator-rps-60'
    },
  ]

  return (
    <div className="container-fluid">
      <PageHeader img={Turn} head="Paddy Separator"></PageHeader>
      <div className="container">
        <div className="row my-4">
          {
            PaddySeparator.map((item, i) => {
              return ProductTile({
                i,
                link: item.link,
                title: item.title,
                img: item.img
              });
            })
          }
        </div>
        <div className="my-5">
          <div className="row">
            <h2 className="mt-2 fw-bold"><b>Why Paddy & Rice Separator ?</b></h2>
            <p className="">After De-husking of rice, Rice is required to be separated from paddy, for which paddy & rice separator machine is used. It works on the principle of constant to and fro movement of inclined indented trays. It separate the material in to 3 parts ie. Brown rice, Husk & Mixture. Mixture is than sent back to the huskers  for reprocessing.</p>
          </div>

          <div className=" row mt-3">
            <h2 className=" fw-bold"><b>What are the usage of Paddy & Rice Separator ?</b></h2>
            <p className="">Paddy Separator is used to separate rice from paddy.</p>
          </div>

          <div className="row mt-2">
            <h2 className="fw-bold"><b>What are the benefits of Paddy & Rice Separator ?</b></h2>
            <p className="">
              <ul>
                <li>Higher output with adjustable speed (optional).</li>
                <li>Advanced electrical panels which automatically controls the start and stop of
                  the machine according to the availability of the raw material.</li>
                <li>Stainless steel trays to minimize wear & tear and longevity of the machine.</li>
                <li>Special Sensors for Automatic operation.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
