import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from "react-bootstrap/Form";
import Logo from '../../images/logo.png';
import "./../../navbar.css";
import MenuItems from './MenuItems';
import menuList from "./menuItemList";
import { Component, useEffect, setState } from 'react';
import $ from 'jquery'
import GoogleTranslate from './googleTranslate';

class SonaNavbar extends Component {
  constructor() {
    super();
    this.state = {
      currentToggleState: false
    }
  }

  toggleState = () => {
    this.setState({
      currentToggleState: !this.state.currentToggleState
    });
  }

  render() {
    let menus;

    let handleDocumentClick = (e) => {
      const container = $('#mainNavBar');
      if (container && e.target !== container && ($(e.target).parents('#mainNavBar').length < 1)) { // && !container.contains(e.target)) {
        if (this.state.currentToggleState) {
          this.toggleState();
        }
      }

      return true;
    }

    $(() => {
      $(document).on('click', (e) => handleDocumentClick(e))
    });

    return (
      <div>
        <Navbar bg="primary"  expand="lg" className="d-flex flex-row-reverse bg-primary" style={{fontWeight: 'bold'}} container>
          <div className="ms-2 bf-info" style={{ "minWidth": "100px" }}>
            <div id="google_translate_element" className='ms-2'></div>
          </div>
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end" style={{ marginLeft: '-40px' }}>
            <a href="#" className="btn btn-primary">Call Us: +91-9599-00-2201</a>
            <a href="https://www.linkedin.com/company/sonamachinery/?originalSubdomain=in" className="btn btn-primary btn-primary mx-1" type="button">
              <i className="bi bi-linkedin"></i>
            </a>
            <a href="https://www.instagram.com/sonamachinery/?hl=en" className="btn btn-sm btn-primary mx-1" type="button">
              <i className="bi bi-instagram"></i>
            </a>
            <a href="https://www.facebook.com/Sonamachineryltd" className="btn btn-sm btn-primary mx-1" type="button">
              <i className="bi bi-facebook"></i>
            </a>
            <a href="https://www.youtube.com/@sonamachinery" className="btn btn-primary btn-sm mx-1" type="button">
              <i className="bi bi-youtube"></i>
            </a>
            <a href="https://twitter.com/SonaMachinery" className="btn btn-primary btn-sm mx-1" type="button">
              <i className="bi bi-twitter"></i>
            </a>
          </Navbar.Collapse >
        </Navbar>
        <Navbar bg="light" expand="lg" className="gradient px-2" expanded={this.state.currentToggleState} onToggle={this.toggleState} id="mainNavBar" >
          <Navbar.Brand href="/" className='ms-5'>
            <img src={Logo} alt="" width="100px" height="60px" />
          </Navbar.Brand>
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center" style={{ marginLeft: '-40px' }}>
            <ul className="menus navbar-nav">
              {menuList.map((menu, index) => {
                const depthLevel = 0;
                return (
                  <MenuItems
                    items={menu}
                    key={index}
                    depthLevel={depthLevel} 
                  />
                );
              })}
              <a href="/catalogue-request" className="btn btn-sm btn-primary shadow mt-1">Download Catalogue</a>
            </ul>
          </Navbar.Collapse>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </Navbar>
      </div>
    );
  }
}

export default SonaNavbar;