import { motion, useScroll } from "framer-motion"
import React from 'react'
import iimg from '../../images/iimg.png';
import df1 from '../../images/pages/homepage/df1.jpg'
import df1Mob from '../../images/pages/homepage/df1_mob.jpg'
import teamPhoto from "../../images/pages/homepage/teamphoto.jpg";
import teamPhotoMob from "../../images/pages/homepage/teamphoto_mob.jpg";

const About = () => {
  return (
    <div className="container-fluid ">
      <div className="d-sm-block d-md-none">
        <div className="row" style={{
          'background-image': `url(${df1Mob})`,
          backgroundSize: '110% 110%',
          backgroundPosition: 'center'
        }}>
          <div className="ms-1 mb-3 mt-1 page-section-header">
            <h3 className="mt-5">About Company</h3>
            <img src={teamPhoto} className="img-fluid img-responsive shadow mt-4"></img>
          </div>
          <div className="text-justify my-1">
            <h5 className="fs-4 my-3">Leaders in Agri-Machinery Manufacturing Industry | Sona Machinery</h5>
            <p className="mt-2 my-2 mb-3">
              Sona Machinery Ltd, Pan India Leader and Original Equipment Manufacturer for Agro-processing  machinery for Complete Rice Mill Projects & Grain Processing Machinery
              and Grain Based Distillery - Unloading & Milling Sections was founded by visionary, Mr. Narender Kumar, who flagged the start of its legacy driven journey.
            </p>
            <div className="mb-5" style={{ minHeight: '40px' }}>
              <a href="/about-us" className="fw-bold link-light link-underline-opacity-0" >More About Us</a>
            </div>
          </div>
        </div>

      </div>

      <div className="d-none d-md-block">
        <div className="row" style={{
          'background-image': `url(${df1})`,
          backgroundSize: '110% 110%',
          backgroundPosition: 'center'
        }}>
          <div className="row align-items-center">
            <div className="col-4 ms-5 mb-5 page-section-header">
              <h3 className="">About Company</h3>
              <img src={teamPhoto} className="img-fluid img-responsive shadow mt-4" width={'600px'}></img>
            </div>
            <div className="col-4 text-justify align-items-center">
              <h5 className="fs-4 mt-3 my-5">Leaders in Agri-Machinery Manufacturing Industry | Sona Machinery</h5>
              <div className="align-items-center my-4">
                <p className="my-3">
                  Sona Machinery Ltd, Pan India Leader and Original Equipment Manufacturer for Agro-processing  machinery for Complete Rice Mill Projects & Grain Processing Machinery
                  and Grain Based Distillery - Unloading & Milling Sections was founded by visionary, Mr. Narender Kumar, who flagged the start of its legacy driven journey.
                </p>
                <a href="/about-us" className="mt-5 mb-5 fw-bold link-dark link-underline-opacity-0" >More About Us</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About;