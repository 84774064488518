import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Link, useParams } from 'react-router-dom'
import { eventsSelector, fetchAllevents } from '../../api/events'

import BannerEvents from "./../../images/pages/pageheaders/banner_events.jpg";
import BannerEventsMobile from "./../../images/pages/pageheaders/banner_events_m.jpg";
import PageHeader from "./../global/pageheader";

function EventCard(props) {
  return (
    <div className="m-2 col-sm-6 col-md-4 mx-auto" key={props?._id}>
      <Link to={`/event/${props?._id}`}>
        <div className="card m-4 shadow mx-auto text-center">
          <div className="card-body" style={{ maxHeight: '400px' }}>
            <img src={props.image} className="mx-auto img-fluid rounded " style={{ maxHeight: '200px' }} height={'200px'} alt="Sona Eevent"></img>
            <div className="fs-6 m-4 text-center">{props.title}</div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default function Index(props) {
  const dispatch = useDispatch();
  let { id } = useParams();
  const { all_events } = useSelector(eventsSelector);

  if (props.id) {
    id = props.id;
  }

  useEffect(() => {
    dispatch(fetchAllevents(id));
  }, [id])

  const eventCards = [];
  all_events.forEach((ev) => eventCards.push(EventCard(ev)));

  return (
    <div className=''>
      <PageHeader img={BannerEvents} img_mobile={BannerEventsMobile} head="Our Events"></PageHeader>
      <div className="row">
        {eventCards}
      </div>
    </div>
  )
}
