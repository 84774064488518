import React from 'react'

export default function Thankyou() {

    return (
        <div className="container">
            <div className='row mx-auto text-center my-5'>
                <h1 className="font-bold text-2xl text-teal-900 mb-6">Thank You</h1>
                <p>Thank you for Reaching out, We have recieved the required information and will be in touch shortly.</p>
                <h1 className="font-bold text-xl text-teal-900 mt-5 my-6">Contact Us</h1>
                <p>If you have any other questions or suggestions about our Privacy Policy, do not hesitate to contact us.</p>
                
            </div>
            <div className="row mb-5">
                <div className="col text-center">
                <a href="/" className="btn btn-primary w-25 align-middle">Home</a></div>
                </div>
        </div>
    );
}