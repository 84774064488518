import React from 'react'

import TrustedByBrandsList from '../global/trustedbybrandsList';

const SectionThree = () => {
       return (
              <div className="row my-2">
                     <div className="col-sm-12 col-md-12 align-middle">
                            <TrustedByBrandsList photos="3" />
                     </div>
              </div>
       );
}

export default SectionThree;