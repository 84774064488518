import { configureStore } from '@reduxjs/toolkit';
import blogReducer from './blog'
import newsReducer from './news'
import jobReducer from './jobs'
import eventReducer from './events'
import subscribeReducer from './subscribe'
import cvReducer from './cv'
import contactReducer from './contactenquiry'


export default configureStore({
  reducer: {
     blog:blogReducer,
     news:newsReducer,
     jobs:jobReducer,
     events:eventReducer,
     subscribe:subscribeReducer,
     resume:cvReducer,
     contactEnquiry:contactReducer,
  },
});