import React, { useEffect, useState, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchOneNews, fetchAllnews, newsSelector } from '../../api/news'
import { useParams } from 'react-router-dom'
import { Interweave } from 'interweave';
import PageHeader from "./../global/pageheader";
import Ourevents from '../../images/ourevents.jpg';

export default function NewsPage() {
    const dispatch = useDispatch()
    const { current_news, loading } = useSelector(newsSelector)
    const { id } = useParams()

    useEffect(() => {
        dispatch(fetchOneNews(id))
    }, [dispatch])

    return (
        <div className="container">
            <PageHeader img={Ourevents} head="Our Latest News"></PageHeader>
            {current_news ?
            <div className='bg-white'>
                <img className="img-fluid" src={current_news?.image} alt="" />

                <p className=' mb-4 text-2xl md:text-3xl  text-left leading-tight text-teal-900 font-bold tracking-tighter'>{current_news?.title}</p>
                    <p className='mb-2 text-xl md:text-2xl  text-left leading-tight  text-gray-700 font-bold tracking-tighter'>{current_news?.sub_title}</p>
                    <div className=" mt-10 text-slate-800">
                    <Interweave className="container" content={current_news?.description} />
                    <a href={current_news?.link}  className="btn btn-primary" target="_blank">Source Credit Link</a>
                </div>
            </div>
            : 
            <div className="my-5 text-center">
                <span className="fs-1">Loading..</span>
            </div>
            }
        </div>
    )
}

