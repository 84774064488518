import React from 'react';
import AboutText from './abouttext';
import PageHeader from "./../global/pageheader";
import BannerAbout from "./../../images/pages/pageheaders/banner_aboutus.jpg";
import BannerAboutM from "./../../images/pages/pageheaders/banner_aboutus_m.jpg";
import TrustedByBrandsList from '../global/trustedbybrandsList';
import History from "./../../images/history.jpg";
import Awards from './awards';

export default function index() {
  return (
    <div className="container-fluid">
      <PageHeader img={BannerAbout} img_mobile={BannerAboutM} head={'About Us'} ></PageHeader>
      <AboutText />
      <Awards />
      <div className="text-center">
        <div className="row mt-5">
          <h3>From Old Archives</h3>
        </div>
        <img src={History} className="img-responsive rounded mt-2 mb-4" style={{ maxWidth: '90%' }}></img>
      </div>
      <TrustedByBrandsList photos="4" />
    </div>
  )
}
