import React, { useLayoutEffect } from 'react'
import Gradingg from '../../../images/Gradingg.png'
import factory from '../../../images/factory.jpg'
import PCS from '../../../images/products/finecleaners/pcs.webp'
import VCS from "./../../../images/products/finecleaners/vcs/1.jpg"
import FINE from '../../../images/products/finecleaners/1.jpg'
import PageHeader from '../../global/pageheader'
import ProductTile from '../productTile'

export default function Finecleaner() {


  const finecleaner = [
    {
      id: 3,
      img: VCS,
      title: 'Fine Cleaner - VCS',
      link: '/fine-cleaner-vcs-10'
    },
    {
      id: 5,
      img: FINE,
      title: 'Fine Cleaner - Fine',
      link: '/fine-cleaner-fine-12'
    }
  ]

  return (
    <div className="container">
      <PageHeader img={Gradingg} head="Fine Cleaner"></PageHeader>
      <div className="row">
        {
          finecleaner.map((item, i) => {
            return ProductTile({
              i, 
              link: item.link,
              title: item.title,
              img: item.img
            })
          })
        }

      </div>

      <div className="">
        <div className="row mt-4">
          <h2 className="fw-bold">Why Fine Cleaner ?</h2>
          <p className="">Fine Cleaner comes next to pre cleaners, it has more fine sieves and is consider to remove the remaining impurities from the paddy, which comes out after parboiling and drying. The machine is required to removed the lighter impurities like, dead paddy, dust, husk, etc</p>
        </div>
        <div className="row mt-4">
          <h2 className="fw-bold ">What are the Usage of Fine Cleaner ?</h2>
          <p className="">The machinery is required after pre cleaning or parboiling and drying to remove the remaining impurities from the paddy. It removes all the dust, dead paddy, husk and other impurities at this stage and also useful as it sheds the weight on other machinery and reduce wear & tear by removing unwanted particles.</p>

        </div>
        <div className="row mt-4">
          <h2 className="fw-bold">What are the benefits of Fine Cleaner ?</h2>
          <p className="">It is beneficial to Fine-cleaner the raw material before storage or before sending the crop for further process as the majority of impurities are removed in this process and it also saves the further equipment and machines from wear & tear.</p>
        </div>
      </div>
    </div >
  )
}
