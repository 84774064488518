
import i1 from "./../../../images/products/materialhandling/chainconveyor/1.jpg";
import i2 from "./../../../images/products/materialhandling/chainconveyor/2.jpg";


const bucketImages = [
    {original : i1, thumbnail: i1},
    {original : i2, thumbnail: i2},
]

const chainconveyor = [
    {
        id: 'chain-conveyor-cc-150',
        title: 'Chain Conveyor 150',
        series: 'CC',
        images: bucketImages,
        video: "",
        description: 'The chain conveyor has a series of gears connected into a continuous system by the chain. Chain conveyors are used for moving products down an assembly line and/or around a manufcaturing or warehousing facility',
        usp: ['Easy to use'],
        specification: [
            { 'Product Name': 'Grain Feeder' }, { 'Model': 'CC 150' }, { 'Capacity Paddy (TPH)': 'NA' }, { 'Capacity Rice (TPH)': '12' },
        ],
        technology: bucketImages,
        category: 'Chain Conveyor',
        categoryLink: '/products/chain-conveyor-cc-300'
    },
    {
        id: 'chain-conveyor-cc-200',
        title: 'Chain Conveyor 200',
        series: 'CC',
        images: bucketImages,
        video: "",
        description: 'The chain conveyor has a series of gears connected into a continuous system by the chain. Chain conveyors are used for moving products down an assembly line and/or around a manufcaturing or warehousing facility',
        usp: ['Easy to use'],
        specification: [
            { 'Product Name': 'Grain Feeder' }, { 'Model': 'CC 200' }, { 'Capacity Paddy (TPH)': '25' }, { 'Capacity Rice (TPH)': '30' },
        ],
        technology: bucketImages,
        category: 'Chain Conveyor',
        categoryLink: '/products/chain-conveyor-cc-300'
    },
    {
        id: 'chain-conveyor-cc-250',
        title: 'Chain Conveyor 250',
        series: 'CC',
        images: bucketImages,
        video: "",
        description: 'The chain conveyor has a series of gears connected into a continuous system by the chain. Chain conveyors are used for moving products down an assembly line and/or around a manufcaturing or warehousing facility',
        usp: ['Easy to use'],
        specification: [
            { 'Product Name': 'Grain Feeder' }, { 'Model': 'CC 250' }, { 'Capacity Paddy (TPH)': '30' }, { 'Capacity Rice (TPH)': '45' },
        ],
        technology: [],
        category: 'Chain Conveyor',
        categoryLink: '/products/chain-conveyor-cc-300'
    },
    {
        id: 'chain-conveyor-cc-300',
        title: 'Chain Conveyor 300',
        series: 'CC',
        images: bucketImages,
        video: "",
        description: 'The chain conveyor has a series of gears connected into a continuous system by the chain. Chain conveyors are used for moving products down an assembly line and/or around a manufcaturing or warehousing facility',
        usp: ['Easy to use'],
        specification: [
            { 'Product Name': 'Grain Feeder' }, { 'Model': 'CC 300' }, { 'Capacity Paddy (TPH)': '45' }, { 'Capacity Rice (TPH)': '55' },
        ],
        technology: [],
        category: 'Chain Conveyor',
        categoryLink: '/products/chain-conveyor-cc-300'
    },
]

export default chainconveyor;