import React, { useLayoutEffect } from 'react'

import { storage } from '../global/store'
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { createResume } from '../../api/cv';

export default function Contactone() {

  const [file, setFile] = useState(null)
  const [resume, setResume] = useState("")
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0);

  const [imgUrl, setImgUrl] = useState(null);
  const [progresspercent, setProgresspercent] = useState(0);
  const [visible, setVisible] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch()



  const uploadFile = async (file) => {
    const uploadTask = storage.ref(`files/${file.name}`).put(file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        //
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (error) => console.log(error),
      () => {
        storage
          .ref("files")
          .child(file.name)
          .getDownloadURL()
          .then((url) => {
            console.log(url);
            setResume(url)
            console.log("The resume is", resume)
          });
      }
    );
  }

  const onSubmit = async (value) => {

    console.log({ value })
    console.log(resume)
    dispatch(createResume({ ...value, resume: resume }))
  };

  return (

    <div className="container">
      <div className="row text-center">
        <h2>Submit Your CV</h2>
      </div>
      <div className="row ">
        <div className="col-sm-12 col-md-6 mx-auto text-center">
          <form onSubmit={handleSubmit(onSubmit)} className="form form-responsive">
            <input {...register('name', { required: true })} className="form-control my-4" type="text" id="name" placeholder="Name" />
            {errors.last_name?.type === 'required'}
            <input {...register('last_name', { required: true })} className="form-control my-4" type="text" id="last_name" placeholder="Last Name" />
            <input {...register('email', { required: true })} className="form-control my-4" type="text" id="email" placeholder="Email Address*" />
            {errors.email?.type === 'required'}
            <input {...register('phone_number', { required: true })} className="form-control my-4" type="text" id="phone_number" placeholder="Phone*" />
            {errors.phone_number?.type === 'required'}
            <input {...register('country', { required: true })} className="form-control my-4" type="text" id="country" placeholder="Country" />
            {errors.country?.type === 'required'}
            <input {...register('state', { required: true })} className="form-control my-4" type="text" id="state" placeholder="State" />
            <input {...register('city', { required: true })} className=" form-control my-4" type="text" id="city" placeholder="City*" />
            {errors.state?.type === 'required'}
            <select {...register('department', { required: true })} className="form-control dropdown" type="text" id="department" placeholder="Select An Option*">
              <option value="Select An Option" selected disabled>Select An Option</option>
              <option value="Accounts">Accounts</option>
              <option value="HR">HR</option>
              <option value="Sales">Sales</option>
              <option value="Designing">Designing</option>
              <option value="Production">Production</option>
              <option value="Dispatch">Dispatch</option>
              <option value="Marketing">Marketing</option>
              <option value="Quality">Quality</option>
              <option value="Purchase">Purchase</option>
              <option value="Store">Store</option>
              <option value="Other">Other</option>
            </select>
            {errors.Source?.type === 'required' && "Department is required"}
            <textarea {...register('post_applying_for', { required: true })} className="form-control my-4" type="text" id="post-applying-for" placeholder="Post Applying for*"></textarea>
            <input  {...register('resume')} onChange={(event) => uploadFile(event.target.files[0])} type="file" id="myfile" name="resume" placeholder="Select a File*" />
            {errors.state?.type === 'required'}

            { progress > 0 ?
            <h2>Uploading done {progress}%</h2>
            : ''}
            <button className="btn btn-primary form-control my-4">Submit</button>
          </form>
          {visible && <p className='text-indigo-800 font-semibold text-2xl '>Thanks for your enquiry, We will get in touch with you.</p>}

        </div >
      </div>
    </div>
  )
}
