import { createSlice } from '@reduxjs/toolkit';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios'
import { keyUri, config } from '../key'

export const initialState = {

  loading: false,

  hasErrors: false,
  all_contact: [],
  currentenquiry: null,
}

export const contactEnquirySlice = createSlice({
  name: "contactEnquiry",
  initialState,
  reducers: {

    getContactEnquiry: state => {

      state.loading = true
    },

    getContactEnquirySuccess: (state, { payload }) => {
      console.log(payload)
      state.loading = false
      state.all_contact = payload
    },

    getCurrentContactEnquiry: (state, { payload }) => {
      console.log({ k: payload });

      state.loading = false
      state.currentenquiry = payload

    },
    getContactEnquiryFailure: (state) => {

      state.loading = false
      // state. enquiry = payload

    },

  }
})

export const { getContactEnquiry, getContactEnquirySuccess, getCurrentContactEnquiry, getContactEnquiryFailure } = contactEnquirySlice.actions

export const contactEnquirySelector = state => state.contactEnquiry
export const createContactEnquiry = (values,navigate) => async dispatch => {
  const key = "ContactEnquiry"
  dispatch(getContactEnquiry())
  try {
    const data = await axios.post(keyUri.BACKEND_URI + '/contactenquiry', values, config);
    dispatch(getContactEnquirySuccess(data))
    toast.success('Your enquiry has been submitted successfully, we will get in touch with you', {
      position: "top-center",
      autoClose: 10000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    if(navigate) {
      navigate('/thankyou');
    }
  } catch ({ response }) {
    toast.error(' You have already sent your enquiry!', {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(getContactEnquiryFailure())
  }

}
export default contactEnquirySlice.reducer










