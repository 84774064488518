
import i1 from "./../../../images/products/materialhandling/bucketconveyor/1.jpg";
import i2 from "./../../../images/products/materialhandling/bucketconveyor/2.jpg";
import i3 from "./../../../images/products/materialhandling/bucketconveyor/3.jpg";
import i4 from "./../../../images/products/materialhandling/bucketconveyor/4.jpg";
import i5 from "./../../../images/products/materialhandling/bucketconveyor/5.jpg";
import i6 from "./../../../images/products/materialhandling/bucketconveyor/6.jpg";

const bucketImages = [
    {original : i1, thumbnail: i1},
    {original : i2, thumbnail: i2},
    {original : i3, thumbnail: i3},
    {original : i4, thumbnail: i4},
    {original : i5, thumbnail: i5},
    {original : i6, thumbnail: i6},
];

const bucketeleveator = [
    {
        id: 'bucket_elevator_rcs_150',
        title: 'Rice Mill Bucket elevator RCS 150',
        series: 'RCS',
        images: bucketImages,
        video: "",
        description: 'This machine works to move grains from one place to another. This machine is designed in such a way that its current discharges positively and the force acting on it is centrifugal force, spaced buckets dig into the material at input and then throw the material out at discharge.',
        usp: ['Easy Operation', 'Absolute alignment', 'Robust Design', 'High Quality', 'Provide maximum production'],
        specification: [
            { 'Product Name': 'Rice Mill Bucket Elevator' }, {'Model': 'RCS 150'}, { 'Capacity Paddy (TPH)': '10' }, { 'Capacity Rice (TPH)': '15' },
        ],
        technology: [],
        category: 'Bucket Elevator',
        categoryLink: '/products/bucket_elevator_rcs_300'
    },
    {
        id: 'bucket_elevator_rcs_200',
        title: 'Rice Mill Bucket elevator RCS 200',
        series: 'RCS',
        images: bucketImages,
        video: "",
        description: 'This machine works to move grains from one place to another. This machine is designed in such a way that its current discharges positively and the force acting on it is centrifugal force, spaced buckets dig into the material at input and then throw the material out at discharge.',
        usp: ['Easy Operation', 'Absolute alignment', 'Robust Design', 'High Quality', 'Provide maximum production'],
        specification: [
            { 'Product Name': 'Rice Mill Bucket Elevator' }, {'Model': 'RCS 200'}, { 'Capacity Paddy (TPH)': '20' }, { 'Capacity Rice (TPH)': '26' },
        ],
        technology: [],
        category: 'Bucket Elevator',
        categoryLink: '/products/bucket_elevator_rcs_300'
    },
    {
        id: 'bucket_elevator_rcs_250',
        title: 'Rice Mill Bucket elevator RCS 250',
        series: 'RCS',
        images: bucketImages,
        video: "",
        description: 'This machine works to move grains from one place to another. This machine is designed in such a way that its current discharges positively and the force acting on it is centrifugal force, spaced buckets dig into the material at input and then throw the material out at discharge.',
        usp: ['Easy Operation', 'Absolute alignment', 'Robust Design', 'High Quality', 'Provide maximum production'],
        specification: [
            { 'Product Name': 'Rice Mill Bucket Elevator' }, {'Model': 'RCS 250'}, { 'Capacity Paddy (TPH)': '32' }, { 'Capacity Rice (TPH)': '40' },
        ],
        technology: [],
        category: 'Bucket Elevator',
        categoryLink: '/products/bucket_elevator_rcs_300'
    },
    {
        id: 'bucket_elevator_rcs_300',
        title: 'Rice Mill Bucket elevator RCS 300',
        series: 'RCS',
        images: bucketImages,
        video: "",
        description: 'This machine works to move grains from one place to another. This machine is designed in such a way that its current discharges positively and the force acting on it is centrifugal force, spaced buckets dig into the material at input and then throw the material out at discharge.',
        usp: ['Easy Operation', 'Absolute alignment', 'Robust Design', 'High Quality', 'Provide maximum production'],
        specification: [
            { 'Product Name': 'Rice Mill Bucket Elevator' }, {'Model': 'RCS 300'}, { 'Capacity Paddy (TPH)': '40' }, { 'Capacity Rice (TPH)': '50' },
        ],
        technology: [],
        category: 'Bucket Elevator',
        categoryLink: '/products/bucket_elevator_rcs_300'
    },
]


export default bucketeleveator;