import hc1 from "./../../images/products/precleaners/hc/1.jpg";
import hc2 from "./../../images/products/precleaners/hc/2.jpg";
import hc3 from "./../../images/products/precleaners/hc/3.jpg";
import hc4 from "./../../images/products/precleaners/hc/4.jpg";
import hc5 from "./../../images/products/precleaners/hc/5.jpg";
import hc6 from "./../../images/products/precleaners/hc/6.jpg";

import drc1 from "./../../images/products/precleaners/drc/1.jpg";
import drc2 from "./../../images/products/precleaners/drc/2.jpg";
import drc3 from "./../../images/products/precleaners/drc/3.jpg";
import drc4 from "./../../images/products/precleaners/drc/4.jpg";
import drc5 from "./../../images/products/precleaners/drc/5.jpg";
import drc6 from "./../../images/products/precleaners/drc/6.jpg";

import pcs1 from "./../../images/products/precleaners/pcs/1.jpg";
import pcs2 from "./../../images/products/precleaners/pcs/2.jpg";
import pcs3 from "./../../images/products/precleaners/pcs/3.jpg";
import pcs4 from "./../../images/products/precleaners/pcs/4.jpg";
import pcs5 from "./../../images/products/precleaners/pcs/5.jpg";
import pcs6 from "./../../images/products/precleaners/pcs/6.jpg";


import pcsdr1 from "./../../images/products/precleaners/pcs2dr/1.jpg";
import pcsdr2 from "./../../images/products/precleaners/pcs2dr/2.jpg";
import pcsdr3 from "./../../images/products/precleaners/pcs2dr/3.jpg";
import pcsdr4 from "./../../images/products/precleaners/pcs2dr/4.jpg";
import pcsdr5 from "./../../images/products/precleaners/pcs2dr/5.jpg";
import pcsdr6 from "./../../images/products/precleaners/pcs2dr/6.jpg";

const drcImages = [
    { original: drc1, thumbnail: drc1 },
    { original: drc2, thumbnail: drc2 },
    { original: drc3, thumbnail: drc3 },
    { original: drc4, thumbnail: drc4 },
    { original: drc5, thumbnail: drc5 },
    { original: drc6, thumbnail: drc6 },
]

const drc2Images = [
    { original: drc4, thumbnail: drc4 },
]

const hcImages = [
    { original: hc1, thumbnail: hc1 },
    { original: hc2, thumbnail: hc2 },
    { original: hc3, thumbnail: hc3 },
    { original: hc4, thumbnail: hc4 },
    { original: hc5, thumbnail: hc5 },
    { original: hc6, thumbnail: hc6 },
]

const pcsImages = [
    { original: pcs1, thumbnail: pcs1 },
    { original: pcs2, thumbnail: pcs2 },
    { original: pcs3, thumbnail: pcs3 },
    { original: pcs4, thumbnail: pcs4 },
    { original: pcs5, thumbnail: pcs5 },
    { original: pcs6, thumbnail: pcs6 },
]

const pcsDrImages = [
    { original: pcsdr1, thumbnail: pcsdr1 },
    { original: pcsdr2, thumbnail: pcsdr2 },
    { original: pcsdr3, thumbnail: pcsdr3 },
    { original: pcsdr4, thumbnail: pcsdr4 },
    { original: pcsdr5, thumbnail: pcsdr5 },
    { original: pcsdr6, thumbnail: pcsdr6 },
]

const precleaners = [

    {
        id: 'pre-cleaner-hc-60',
        title: 'PRE CLEANER - HC 60',
        series: 'PRE CLEANER - HC',
        images: hcImages,
        video: "https://www.youtube.com/embed/Rf8HkDra5ug",
        description: 'Pre-Cleaner is considered the next option in preliminary cleaning of the grain equipped with two inbuilt sieved decks and an inbuilt aspiration, designed to function to and fro motion to get efficiency of cleaning, further to use for parboiling & drying.',
        usp: ['5 Way cleaning', 'Larger Screen Area', 'Long Life Machine', 'Easy Servicing'],
        specification: [{ 'Product Name': 'Pre Cleaner' }, { 'Model': 'HC60' }, { 'Capacity': '15-18 TPH' }, { 'Speed (RPM)': '150' }, { 'Power Consumption (HP) (Recommended)': '7.5HP/2' }, { 'Weight (Kg)': '1600' }, { 'Dimensions (L*W*H in mm)': '2940*1660*3200' }],
        technology: ['The technology used for HC 60 is Acentric movement of the sieves with an air aspiration system.', 'The grain is passed through a hopper into a rotary sieve drum, where mostly big rocks, ropes, mud balls and other big impurities are removed, then it is passed to two wooden sieve decks, which moves backward & forward continuously, thus clearing out other remaining big impurities.', 'Aspiration systems are also present at the required places to suck the dust and other lighter impurities from the crop.'],
        category: 'Pre-Cleaner',
        categoryLink: '/pre-cleaner'

    },
    {
        id: 'pre-cleaner-hc-70',
        title: 'PRE CLEANER - HC 70',
        series: 'PRE CLEANER - HC',
        images: hcImages,
        video: "https://www.youtube.com/embed/Rf8HkDra5ug",
        description: 'Pre-Cleaner is considered the next option in preliminary cleaning of the grain equipped with two inbuilt sieved decks and an inbuilt aspiration, designed to function to and fro motion to get efficiency of cleaning, further to use for parboiling & drying.',
        usp: ['5 Way cleaning', 'Larger Screen Area', 'Long Life Machine', 'Easy Servicing'],
        specification: [{ 'Product Name': 'Pre Cleaner' }, { 'Model': 'HC70' }, { 'Capacity': '20-25 TPH' }, { 'Speed (RPM)': '150' }, { 'Power Consumption (HP) (Recommended)': '7.5HP/2' }, { 'Weight (Kg)': '1750' }, { 'Dimensions (L*W*H in mm)': '2960*1920*3200' }],
        technology: ['The technology used for HC 70 is Acentric movement of the sieves with an air aspiration system.', 'The grain is passed through a hopper into a rotary sieve drum, where mostly big rocks, ropes, mud balls and other big impurities are removed, then it is passed to two wooden sieve decks, which moves backward & forward continuously, thus clearing out other remaining big impurities.', 'Aspiration systems are also present at the required places to suck the dust and other lighter impurities from the crop.'],
        category: 'Pre-Cleaner',
        categoryLink: '/pre-cleaner'
    },
    {
        id: 'pre-cleaner-drc-25',
        title: 'ROTARY DRUM CLEANER - DRC 25',
        series: 'ROTARY DRUM CLEANER - DRC',
        images: drcImages,
        video: "https://www.youtube.com/embed/Rf8HkDra5ug",
        description: 'Due to its large size, drum cleaner is also known as a rotary cleaner. It stands under the preliminary cleaning sections & is remarkably famous for rotating constantly to clean as well as separating abnormal particles.',
        usp: ['Low maintenance', 'Easy servicing', 'Clean heavy rubbles & impurities', 'It finishes a major chunk in a single go'],
        specification: [{ 'Product Name': 'Rotary Drum Cleaner' }, { 'Model': 'DRC25' }, { 'Capacity': '50-60 TPH' }, { 'Speed (RPM)': '20' }, { 'Power Consumption (HP) (Recommended)': '5HP/21RPM' }, { 'Weight (Kg)': '3000' }, { 'Dimensions (L*W*H in mm)': '5450*1820*3000' }],
        technology: ['The technology used for DRC 25 is of continuously rotation of Drum Sieves on a set inclination with an air aspiration system.', 'The Grain from the field is dropped into the inner drum, which has big enough holes that can pass the grain along with smaller impurities out of it, however the underneath sieve has smaller hole sizes, which only allow dust or smaller impurities to pass through it. The mechanism is simple of two drum sieves rotating one in to the another with inner sieve filtering Oversized Impurities and underneath sieve filtering undersized impurities. The machine is equipped with aspiration system to suck all the dust particles and threw it away.'],
        category: 'Pre-Cleaner',
        categoryLink: '/pre-cleaner'

    },
    {
        id: 'pre-cleaner-pcs-dr',
        title: 'Grain Cleaner - PCS - 1',
        series: 'PRE CLEANER - PCS',
        images: pcsImages,
        video: "https://www.youtube.com/embed/Rf8HkDra5ug",
        description: 'Grains Cleaner or Paddy Cleaner is designed to utilize between post parboiling & pre-milling of the Grain equipped with wooden sieved decks to eliminate the impurities for further processing of the Grain',
        usp: [
            'Provided with closed circuit dust aspiration system.',
            'Robust design and long-lasting structure.',
            'Low maintenance and high accuracy of the machine.',
            'Special grade metal sieves and high density wooden laminated frames used.',
            'Machine has an integral fan, fixed-speed drives, and motors.',
            'It could be used on multiple grains.'
        ],
        specification: [{ 'Product Name': 'Pre Cleaner' }, { 'Model': 'PCS 1' }, { 'Capacity Paddy (TPH) ': '4 - 6' }, { 'Power Consumption (HP) (Recommended)': '2+7.5' }, { 'Weight (Kg)': '1250' }, { 'Dimensions (L*W*H in mm)': '2960*1890*2415' }],
        category: 'Pre-Cleaner',
        categoryLink: '/pre-cleaner'

    },
    {
        id: 'pre-cleaner-pcs-2dr',
        title: 'Grains Pre-Cleaner-PCS2DR',
        series: 'PRE CLEANER - PCS',
        images: pcsDrImages,
        video: "https://www.youtube.com/embed/Rf8HkDra5ug",
        description: 'Pre-Cleaner is considered the next option in preliminary cleaning of the grain equipped with two inbuilt sieved decks and an inbuilt aspiration, designed to function to and fro motion to get efficiency of cleaning, further to use for parboiling & drying.',
        usp: [
            'Robust design and long-lasting structure', 'Low maintenance and high accuracy of the machine', 'Special grade metal sieves and wooden laminated frames used', 'Machine has an integral fan, fixed speed drives and motores', 'Inbuilt drum cleaner to remove heavy impurities post-harvesting of the grain'],
        specification: [{ 'Product Name': 'Pre Cleaner' }, { 'Model': 'PCS 2DR' }, { 'Capacity': '8 - 10' }, { 'Power Consumption (HP) (Recommended)': '2+7.5' }, { 'Weight (Kg)': '1400' }, { 'Dimensions (L*W*H in mm)': '2960*2260*3060' }],
        technology: ['It is equipped with an additional Drum Sieve, which is useful to remove oversized particles. Therefore, in some milling processes it is used as pre-cleaner as well.'],
        category: 'Pre-Cleaner',
        categoryLink: '/pre-cleaner'

    },
];

export default precleaners;
