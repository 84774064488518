
import { Link } from "react-router-dom";

export default function SeriesProduct(props) {
    return (
        <div key={props.i} className="col text-center">
            <div className="card">
                <div className="card-header">{props.title}</div>
                <div className="card-body">{props.item.description}</div>
                <Link to={'/products/' + props.link + "#landing"} className=" mt-4 text-center">
                    Read More
                </Link>
            </div>
        </div>
    );
}
