import React, { useEffect } from 'react'

import { eventsSelector, fetchAllevents } from '../../api/events'
import { newsSelector, fetchHomenews } from '../../api/news'
import { useDispatch, useSelector } from 'react-redux'


function EvItem(props) {
  return (
    <div className="col-md-4 col-sm-1 bg-light" key={props.toref}>
      <div className="row text-center">
        <img className="m-2 img-fluids rounded " height={'250px'} width={'200px'} src={props.img} ></img>
      </div>
      <div className="row my-2" style={{maxHeight:'50px', minHeight:'50px'}}>
        <div className="mx-3 mb-2">{props.title}</div>
      </div>
      <div className="row my-2 ">
          <div><a className="link-dark mt-2 mx-3" href={props.toref}>Read More</a></div>
        </div>
    </div>
  )
}

export default function SectionBlog() {
  const { all_events, loading } = useSelector(eventsSelector)
  const { all_news } = useSelector(newsSelector)

  const dispatch = useDispatch()
  useEffect(() => {
    //dispatch(fetchAllevents())
    dispatch(fetchHomenews())
  }, [])

  const events = [];
  all_news.slice(0, 3).forEach((ev) => {
    console.log('ev', ev);
    const val = (
      <EvItem type="News" title={ev.title} img={ev.image} toref={'/newspage/' + ev._id}></EvItem>
    )
    events.push(val)
  });

  return (
    <div className="container-fluid my-2">
      <div className="row text-center page-section-header">
        <h2>Latest News from Sona Machinery</h2>
      </div>
      <div className="mt-4 row d-flex justify-content-center ">
        {events}
      </div>
    </div>
  )
}