
import i1 from "./../../../images/products/materialhandling/beltconveyor/1.jpg";
import i2 from "./../../../images/products/materialhandling/beltconveyor/2.jpg";
import i3 from "./../../../images/products/materialhandling/beltconveyor/3.jpg";
import i4 from "./../../../images/products/materialhandling/beltconveyor/4.jpg";
import i5 from "./../../../images/products/materialhandling/beltconveyor/5.jpg";
import i6 from "./../../../images/products/materialhandling/beltconveyor/6.jpg";

const conveyorimages = [
    { original: i1, thumbnail: i1 },
    { original: i2, thumbnail: i2 },
    { original: i3, thumbnail: i3 },
    { original: i4, thumbnail: i4 },
    { original: i5, thumbnail: i5 },
    { original: i6, thumbnail: i6 }
];

const beltconveyor = [
    {
        id: 'belt-conveyor-bc-450',
        title: 'Belt Conveyor 450',
        series: 'BC',
        images: conveyorimages,
        video: "",
        description: 'A belt conveyor system consists of two or more pulley, with a closed loop of carrying medium- the conveyer belt- that rotates about them, this is used to transport large volumes of resources & agricultural materials, such as grain, seeds, feed etc.',
        usp: ['Durable', 'Expeditious', 'Easy to use', 'Perfectly creates uniform partitions'],
        specification: [
            { 'Product Name': 'Grain Mover' }, { 'Model': 'BC 450' }, { 'Capacity (TPH)': '15' }, { 'Capacity Rice (TPH)': '20' },
        ],
        technology: [],
        category: 'Belt Conveyor',
        categoryLink: '/products/belt-conveyor-bc-450'
    },
    {
        id: 'belt-conveyor-bc-600',
        title: 'Belt Conveyor 600',
        series: 'BC',
        images: conveyorimages,
        video: "",
        description: 'A belt conveyor system consists of two or more pulley, with a closed loop of carrying medium- the conveyer belt- that rotates about them, this is used to transport large volumes of resources & agricultural materials, such as grain, seeds, feed etc.',
        usp: ['Durable', 'Expeditious', 'Easy to use', 'Perfectly creates uniform partitions'],
        specification: [
            { 'Product Name': 'Grain Mover' }, { 'Model': 'BC 600' }, { 'Capacity (TPH)': '24' }, { 'Capacity Rice (TPH)': '30' },
        ],
        technology: [],
        category: 'Belt Conveyor',
        categoryLink: '/products/belt-conveyor-bc-600'
    },
];

export default beltconveyor;