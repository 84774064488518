import RPI0053 from "./../../images/products/paddyseparators/rps20/1.jpg";
import RPI0054 from "./../../images/products/paddyseparators/rps20/2.jpg";
import RPI0056 from "./../../images/products/paddyseparators/rps20/3.jpg";
import RPI0058 from "./../../images/products/paddyseparators/rps20/4.jpg";
import RPI0059 from "./../../images/products/paddyseparators/rps20/5.jpg";
import RPI0078 from "./../../images/products/paddyseparators/rps20/6.jpg";

import rp401 from "./../../images/products/paddyseparators/rps40/1.jpg";
import rp402 from "./../../images/products/paddyseparators/rps40/2.jpg";
import rp403 from "./../../images/products/paddyseparators/rps40/3.jpg";
import rp404 from "./../../images/products/paddyseparators/rps40/4.jpg";
import rp405 from "./../../images/products/paddyseparators/rps40/5.jpg";
import rp406 from "./../../images/products/paddyseparators/rps40/6.jpg";

const rpsImages = [
    { original: RPI0053, thumbnail: RPI0053 },
    { original: RPI0054, thumbnail: RPI0054 },
    { original: RPI0056, thumbnail: RPI0056 },
    { original: RPI0058, thumbnail: RPI0058 },
    { original: RPI0059, thumbnail: RPI0059 },
    { original: RPI0078, thumbnail: RPI0078 },
]

const rps40Images = [
    { original: rp401, thumbnail: rp401 },
    { original: rp402, thumbnail: rp402 },
    { original: rp403, thumbnail: rp403 },
    { original: rp404, thumbnail: rp404 },
    { original: rp405, thumbnail: rp405 },
    { original: rp406, thumbnail: rp406 },
]

const rps60Images = [
    { original: rp401, thumbnail: rp401 },
    { original: rp402, thumbnail: rp402 },
    { original: rp403, thumbnail: rp403 },
    { original: rp404, thumbnail: rp404 },
    { original: rp405, thumbnail: rp405 },
    { original: rp406, thumbnail: rp406 },
]

const paddyseparators = [

    {
        id: 'paddy-separator-rps-20',
        title: 'Paddy & Rice Separator RPS-20',
        series: 'PADDY & RICE SEPARATORS RPS',
        images: rpsImages,
        video: "https://www.youtube.com/embed/Rf8HkDra5ug",
        description: 'After De-husking of rice, Rice is required to be separated from paddy, for which paddy & rice separator machine is used. It works on the principle of constant to and fro movement of inclined indented trays. It separate the material in to 3 parts ie. Brown rice, Husk & Mixture. Mixture is than sent back to the huskers  for reprocessing',
        usp: [
            'Higher output with adjustable speed (optional).',
            'Advanced electrical panels which automatically controls the start and stop of the machine according to the availability of the raw material.',
            'Stainless steel trays to minimize wear & tear and for longevity of the machine.',
            'Special Sensors for Automatic operation.'
        ],
        specification: [{ 'Product Name': 'Paddy & Rice Seperators' }, { 'Model': 'RPS 20' }, { 'Capacity': '4 - 5 TPH' }, { 'Power Consumption (HP) (Recommended)': '3 HP' }, { 'Weight (Kg)': '500' }, { 'Dimensions (L*W*H in mm)': '1675*1510*2060' }],
        technology: ['It works on the principle of constant to and fro movement of inclined-indented trays. It separate the material in to 3 parts i.e. Brown rice, Husk & Mixture of both.'],
        category: 'Paddy Separators',
        categoryLink: '/paddy-separators'
    },
    {
        id: 'paddy-separator-rps-40',
        title: 'Paddy Separator RPS-40',
        series: 'PADDY & RICE SEPARATORS RPS',
        images: rps40Images,
        video: "https://www.youtube.com/embed/Rf8HkDra5ug",
        description: 'Paddy Separator works on accurate gravity granularity and friction to segregate the mixture into three parts paddy, Brown Rice, and a mixture of paddy and brown rice by inclined specially shaped intents vibrating trays .',
        usp: [
            'Higher output with adjustable speed (optional).',
            'Advanced electrical panels which automatically controls the start and stop of the machine according to the availability of the raw material.',
            'Stainless steel trays to minimize wear & tear and for longevity of the machine.',
            'Special Sensors for Automatic operation.'
        ],
        specification: [{ 'Product Name': 'Paddy & Rice Seperators' }, { 'Model': 'RPS 40' }, { 'Capacity': '8 - 10 TPH' }, { 'Power Consumption (HP) (Recommended)': '5 HP' }, { 'Weight (Kg)': '1120' }, { 'Dimensions (L*W*H in mm)': '1585*2025*2250' }],
        technology: ['It works on the principle of constant to and fro movement of inclined-indented trays. It separate the material in to 3 parts i.e. Brown rice, Husk & Mixture of both.'],
        category: 'Paddy Seperators',
        categoryLink: '/paddy-separators'
    },
    {
        id: 'paddy-separator-rps-60',
        title: 'Paddy & Rice Separator RPS-60',
        series: 'PADDY & RICE SEPARATORS RPS',
        images: rps60Images,
        video: "https://www.youtube.com/embed/Rf8HkDra5ug",
        description: 'Paddy Separator works on accurate gravity granularity and friction to segregate the mixture into three parts paddy, Brown Rice, and a mixture of paddy and brown rice by inclined specially shaped intents vibrating trays .',
        usp: [
            'Higher output with adjustable speed (optional).',
            'Advanced electrical panels which automatically controls the start and stop of the machine according to the availability of the raw material.',
            'Stainless steel trays to minimize wear & tear and for longevity of the machine.',
            'Special Sensors for Automatic operation.'
        ],
        specification: [{ 'Product Name': 'Paddy & Rice Seperators' }, { 'Model': 'RPS 60' }, { 'Capacity': '8 - 10 TPH' }, { 'Power Consumption (HP) (Recommended)': '5 HP' }, { 'Weight (Kg)': '1120' }, { 'Dimensions (L*W*H in mm)': '1675*1910*2080' }],
        technology: ['It works on the principle of constant to and fro movement of inclined-indented trays. It separate the material in to 3 parts i.e. Brown rice, Husk & Mixture of both.'],
        category: 'Paddy Seperators',
        categoryLink: '/paddy-separators'
    },
]

export default paddyseparators; 