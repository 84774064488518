import React, { useLayoutEffect } from 'react'
import HC from '../../images/products/precleaners/hc/2.jpg'
import DRC25 from '../../images/products/precleaners/drc/1.jpg'

import S40 from '../../images/products/stoneseparators/s/1.jpg'
import SECO from "./../../images/products/stoneseparators/eco/4.jpg";
import SPR from "./../../images/products/stoneseparators/pr/3.jpg";

import FLOURSIFTER from "./../../images/products/floursifter/1.jpg"

import BC from '../../images/products/materialhandling/beltconveyor/1.jpg'
import BELEV from "./../../images/products/materialhandling/bucketconveyor/1.jpg"
import CHAINCOVEYOR from "./../../images/products/materialhandling/chainconveyor/1.jpg"
import SCREWCONVEYOR from "./../../images/products/materialhandling/screwconveyor/1.jpg"


import VCS from '../../images/products/finecleaners/vcs/1.jpg'

import SC from '../../images/products/materialhandling/screwconveyor/2.jpg'
import RCS150 from '../../images/RCS150.webp'
import RPS200 from '../../images/products/paddyseparators/rps20/1.jpg'
import CC from '../../images/products/materialhandling/chainconveyor/1.jpg'
import Distillery1 from '../../images/Distillery1.png'
import Qualified from '../../images/legend-qualified.png'
import India from '../../images/legend-india.png'
import Years from '../../images/legend-years.png'
import Three from '../../images/legend-three.png'
import Why from '../../images/Why.png'
import Scope from '../../images/Scope.png'
import PageHeader from '../global/pageheader'
import ProductCard from '../products/productCard'
import TrustedByBrandsList from '../global/trustedbybrandsList';
import EthanolProcess from "./../../images/ethanol-process.jpg"
import FeatureImage from './featureImageRow';
import BCML_RECOM from "./../../images/pages/solutionspage/graindistillery/bcml-commendation.jpeg";
import BCML_SIGN from "./../../images/pages/solutionspage/graindistillery/SignOffSnap.png";
import OM_ETHANOL from "./../../images/pages/solutionspage/graindistillery/omsonsethanol.jpg";

export default function Drainbased() {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const [display] = React.useState(false);

  return (
    <div className="container-fluid">
      <PageHeader img={Distillery1} head="EPC solutions for Grain Based Distilleries" head2="Unloading and Milling Sections"></PageHeader>
      <div className="container">
        <div className="row mt-4 mx-4 mx-auto">
          <div className="col-sm-6 col-md-5 mt-4 text-justify">
            <h4>Sona Machinery Solutions</h4>
            <h6>One Stop solution for Grain-Based Distillery</h6>
            <p>
              We offer EPC solutions for Grain Based Distillery Unloading & Milling sections. With the government policy of increasing ethanol production by 2025 under the scheme of E20, many new installations for grain-based distilleries are on the surge. We provide a complete end-to-end solution for the milling section, from the unloading of the grain to pre-mash.
            </p>
            <ul className="">
              <li >
                Market Leader in Grain Milling for Grain-based ethanol distilleries (60– 500 KLPD)
              </li>
              <li className="">
                Grain Pre-cleaners and Material Handling Equipment from 3 TPH – 125 TPH
              </li>
              <li className="">
                Trusted for Quality
              </li>
              <li className="">
                Several Grain Distillery Projects Delivered
              </li>
              <li className="">
                Prompt Service Support
              </li>
              <li className="">
                Easily Available Original Spares
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-3">
            <div className="card m-2 p-2 shadow">
              <div className="card-image text-center"><img src={Qualified} className="img-fluid rounded" alt="" style={{ maxHeight: '150px', maxWidth: '150px' }} /></div>
              <div >
                <h5>Team of Highly Qualified Engineers</h5>
                <p>Sona Machinery provides a complete end-to-end solution for the milling section, from the unloading of the grain to pre-mash. <br /><br /><br /></p>
              </div>
            </div>
            <div className="card m-2 p-2 shadow">
              <div className="card-image text-center"><img src={Years} className="img-fluid rounded" alt="" style={{ maxHeight: '150px', maxWidth: '150px' }} /></div>
              <div>
                <h5>27 years of Manufacturing Experience</h5>
                <p>Apart from Grain-Based Ethanol Distillery Manufacturing & Solution, we also manufacture almost all the machinery and equipment required <br /></p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-3 ">
            <div className="card m-2 p-2 shadow">
              <div className="card-image text-center"><img src={India} className="img-fluid rounded" alt="" style={{ maxHeight: '150px', maxWidth: '150px' }} /></div>
              <div>
                <h5>Pan India leader in the Agro-processing Industry</h5>
                <p>like Grain Cleaning Machines, Destoning Machines, Material Handling Equipment, and Pre Fabricated Flour Silos.<br /></p>
              </div>
            </div>
            <div className="card m-2 p-2 shadow">
              <div className="card-image text-center"><img src={Three} className="img-fluid rounded" alt="" style={{ maxHeight: '150px', maxWidth: '150px' }} /></div>
              <div>
                <h5>Solutions from 60 KLPD to 500 KLPD Grain Miling</h5>
                <p>Cost-effective fixed price and tailored timeline to customers for a hassle-free experience <br /><br /></p>
              </div>
            </div>
          </div>
        </div>
        <div className="card text-center mt-5 shadow">
          <div className="card-header"><h5>Customer Speak</h5></div>
          <div className="card-body">
            <div className="row text-centered">

              <div className='col'>
                <a href="/omsonsethanol.jpg" target="_blank" >
                  <img src={OM_ETHANOL} class="img-thumbnail shadow rounded" style={{ minHeight: '300px', maxHeight: '300px', maxWidth: '200px' }} />
                </a>
              </div>
              <div className='col'>
                <a href="/bcml-commendation.jpeg" target="_blank">
                  <img src={BCML_RECOM} class="img-thumbnail shadow rounded" style={{ minHeight: '300px', maxHeight: '300px', maxWidth: '200px' }} /></a>
              </div>
              <div className='col'>
                <a href="/bcml-project-signoff.pdf" target="_blank" >
                  <img src={BCML_SIGN} class="img-thumbnail shadow rounded" style={{ minHeight: '300px', maxHeight: '300px', maxWidth: '200px' }} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <FeatureImage head="Ethanol Process Flow Diagram" img={EthanolProcess} className="mt-2 mb-2" scale={'2'}></FeatureImage>
        <div className="row mx-auto my-4">
          <div className="col my-5">
            <h3>Customized Solutions for Grain Based Distilleries - Unloading & Milling</h3>
            <p>
              Sona Machinery has been invigorating the grain-based distillery industry by providing high-quality Unloading, Milling & Material Handling solutions. with a strong emphasis on the manufacturing process, through quality testing laboratories and a qualified & experienced team of Engineers.
            </p>
          </div>
          <div className="col">
            <div className="card shadow m-2 p-2">
              <div className="card-img text-center">
                <img src={Why} alt="" className='img-fluid rounded m-4' style={{ maxHeight: '100px' }} />
              </div>
              <div className="card-title text-center">
                <h5> Why Us ?</h5></div>
              <ul className='list-disc text-lg text-gray-900'>
                <li>27 Years of experience in Grain Industry.</li>
                <li>80% of Machinery & Equipment are manufactured in-house.</li>
                <li>Strategically built internal infrastructure.</li>
                <li>More than 10000 happy customers <br />in India & abroad.</li>
                <li>Strong Manufacturing capabilities <br />equipped with cutting-edge technology.</li>
                <li>10500 sq. meters of manufacturing <br />area and Warehouse.</li>
                <br /><br />
              </ul>
            </div>
          </div>
          <div className="col">

            <div className="card shadow m-2 p-2">
              <div className="card-img text-center">
                <img src={Scope} alt="" className='img-fluid rounded m-4' style={{ maxHeight: '100px' }} />
              </div>
              <div className="card-title text-center">

                <h5>Scope Of work</h5></div>
              <ul className='list-disc text-lg text-gray-900'>
                <li>Process Engineering</li>
                <li>Machinery Supply & installation</li>
                <li>Structure Fabrication</li>
                <li>Grain Storage Silos</li>
                <li>Pre-fabricated Flour Silos</li>
                <li>Complete Electrical Erection & <br></br>Commissioning</li>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </ul>
            </div>
          </div>
        </div>
        <div className="row text-center my-5">
          <h2>Our Products</h2>
        </div>
        <div className="row m-2 text-center">

          <ProductCard img={DRC25} tgt="/pre-cleaner-drc-25" desc="Pre Cleaner - DRC"></ProductCard>
          <ProductCard img={HC} tgt="/pre-cleaner-hc-60" desc="Pre Cleaner - HC Series"></ProductCard>

          <ProductCard img={S40} tgt="/stone-separator-s-80" desc="Stone Separator-S Series"></ProductCard>
          <ProductCard img={SPR} tgt="/stone-separator-pr-10" desc="Stone Separator-PR Series"></ProductCard>{/* 
        <ProductCard img={SECO} tgt="/Stone_Seperator_ECO_10"></ProductCard> */}


          <ProductCard img={FLOURSIFTER} tgt="/flour-sifter" desc="Flour Sifter"></ProductCard>

          <ProductCard furl={true} img={BC} tgt="/material-handling/belt-conveyor" desc="Belt Conveyor"></ProductCard>
          <ProductCard furl={true} img={BELEV} tgt="/material-handling/rice-mill-bucket-elevator" desc="Bucket Elevator"></ProductCard>
          <ProductCard furl={true} img={CHAINCOVEYOR} tgt="/material-handling/chain-conveyor" desc="Chain Conveyor"></ProductCard>
          <ProductCard furl={true} img={SCREWCONVEYOR} tgt="/material-handling/screw-conveyor" desc="Screw Conveyor"></ProductCard>
        </div>

        {/* ------------------------------------------------------------------- */}

        <div>
          <TrustedByBrandsList photos='3'></TrustedByBrandsList>
        </div>

      </div>
    </div>
  )
}

