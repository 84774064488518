import React, { useLayoutEffect } from 'react'
import Gradingg from '../../../images/Gradingg.png'
import factory from '../../../images/factory.jpg'
import S from '../../../images/products/stoneseparators/s/1.jpg';
import ECO from '../../../images/products/stoneseparators/eco/1.jpg';
import STONE_PRS from './../../../images/products/stoneseparators/pr/1.jpg'

import { Link } from 'react-router-dom';
import PageHeader from "./../../global/pageheader";
import ProductTile from '../productTile'

export default function Stoneseparator() {

  const stoneseparator = [
    {
      id: 1,
      img: S,
      title: 'Stone Separator - S Series',
      link: '/stone-separator-s-40'
    },
    {
      id: 3,
      img: STONE_PRS,
      title: 'Stone Separator - PR Series',
      link: '/stone-separator-pr-5'
    },
    {
      id: 6,
      img: ECO,
      title: 'Stone Separator - ECO Series',
      link: '/stone-separator-eco-5'
    }
  ]


  return (
    <div className="container">
      <PageHeader img={Gradingg} head="Stone Separator"></PageHeader>
      <div className="row my-4">
        {
          stoneseparator.map((item, i) => {
            return ProductTile({
              i,
              link: item.link,
              title: item.title,
              img: item.img
            })
          })
        }
      </div>
      <div className="my-5">
        <div className="row mt-2">
          <h2 className="fw-bold mb-2">Why Stone Separator ?</h2>
          <p className="">Stone Separator Machine is used for efficient separation of stones, heavy particles, glass, and other high-density (heavier) from paddy, grains, seeds etc.</p>

        </div>
        <div className="row mt-2">
          <h2 className="fw-bold mb-2">What are the usage of Stone Separator ?</h2>
          <p className="">Stone Separators are used to remove dust, stones, mud balls, Sachets, threads, metallic items, etc. from the grain.</p>

        </div>
        <div className="row mt-2">
          <h2 className="fw-bold mb-2">What are the benefits of Stone Separator ?</h2>
          <p className="">
            <ul>
              <li>Efficient & precise separation of stones and heavy materials.
                <li>Adjustable table inclination and air volume of the machine.</li>
                <li>Fitted with imported vibro motors for trouble free, long life and low noise operation.</li>
                <li>Special imported sieves for the perfect cleaning of the grains.</li>
              </li>
            </ul>
          </p>

        </div>
      </div>
    </div>
  )
}
