import React from 'react';
import Loadable from '@loadable/component';

import banner1 from '../../images/pages/homepage/banners/1.jpg';
import banner2 from '../../images/pages/homepage/banners/2.jpg';
import banner3 from '../../images/pages/homepage/banners/3.jpg';
import banner4 from '../../images/pages/homepage/banners/4.jpg';

import banner1mb from '../../images/pages/homepage/banners/1_mobile.jpg';
import banner2mb from '../../images/pages/homepage/banners/2_mobile.jpg';
import banner3mb from '../../images/pages/homepage/banners/3_mobile.jpg';
import banner4mb from '../../images/pages/homepage/banners/4_mobile.jpg';

const OwlCarousel = Loadable(() => import('react-owl-carousel3'));

const CarouselItem = (props) => {
       let plink = '';

       if (props.link) {
              plink = <a href={props.link} className="text-white position-absolute top-70 start-5"><h5>Read More</h5></a>
       }

       if (props.link && props.isMob) {
              plink = <a href={props.link} className="text-white position-absolute top-85 start-10"><h5>Read More</h5></a>
       }



       return (
              <div className='item'>
                     <div className="text-center position-relative">
                            <img src={props.img} className="rounded" style={{ minHeight: '75vh' }}></img>
                            {plink}
                     </div>
              </div>
       )
}
const MainCarousel = () => {
       const options = {
              loop: true,
              nav: false,
              dots: false,
              autoplay: true,
              merge: true,
              smartSpeed: 2000,
              autoplayTimeout: 4000,
              responsive: {
                     0: {
                            items: 1,
                     }
              }
       }

       return (
              <div>
                     <OwlCarousel
                            className="mt-0 owl-carousel owl-theme d-sm-block d-md-none"
                            {...options}
                     >
                            <CarouselItem img={banner1mb} isMob={true} idn="b1" className="item" />
                            <CarouselItem img={banner2mb} isMob={true} idn="b2" className="item" />
                            <CarouselItem img={banner3mb} isMob={true} idn="b3" link={'/epc-solutions-for-grain-unloading-and-milling'} className="item" />
                            <CarouselItem img={banner4mb} isMob={true} idn="b4" link={'/turnkey-rice-mill-solutions'} className="item" />
                     </OwlCarousel>

                     <OwlCarousel
                            className="mt-0 owl-carousel owl-theme d-none d-md-block"
                            {...options}
                     >
                            <CarouselItem img={banner1} idn="b1" className="item" />
                            <CarouselItem img={banner2} idn="b2" className="item" />
                            <CarouselItem img={banner3} idn="b3" link={'/epc-solutions-for-grain-unloading-and-milling'} className="item" />
                            <CarouselItem img={banner4} idn="b4" link={'/turnkey-rice-mill-solutions'} className="item" />
                     </OwlCarousel>
              </div>
       )
}


export default MainCarousel;