import React, { useLayoutEffect } from 'react' 
import Business from '../../src/images/business.jpg'
import BannerVision from "./../images/pages/pageheaders/banner_vision.jpg";
import BannerVisionMobile from "./../images/pages/pageheaders/banner_vision_m.jpg";

import Whyus from '../../src/images/whyus.jpg'
import Benefits from '../../src/images/benefits.jpg'
import PageHeader from "./global/pageheader"

function SectionBusinessScope() {
  return (
    <div className="row my-4">
      <div className="col-md-6 col-sm-12 align-middle d-flex  align-items-center">
        <img src={Business} height={'300px'} className="img-fluid rounded mt-3"></img>
      </div>
      <div className="col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
        <div className="card shadow">
          <div className="card-header bg-primary  text-white"><h5>Scope of Business </h5></div>
          <ul className='list-group light-group-flush'>
            <li className="list-group-item list-group-item-action" > Innovation in Agro-processing & Grain Milling (Grain Based Distilleries & their EPC) industry.</li>
            <li className="list-group-item list-group-item-action">Conceptualization & Customization</li>
            <li className="list-group-item list-group-item-action">Research, Design & Development</li>
            <li className="list-group-item list-group-item-action">Product Delivery, Installation & Commissioning</li>
            <li className="list-group-item list-group-item-action">Prompt After-Sales Service Support</li>
            <li className="list-group-item list-group-item-action"> Turn-key Solutions</li>
            <li className="list-group-item list-group-item-action">Easily available Sona Original Spares</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

function SectionBenefits() {
  return (
    <div className="row my-4 ">
      <div className="col-md-6 col-sm-12">
        <div className="card shadow  bg-primary">
          <div className="card-header  text-white"><h5>Our Benefits</h5></div>
          <ul className='list-group light-group-flush'>
              <li className="list-group-item list-group-item-action">Our cutting-edge latest technology solutions provide higher yields of the final processed product.</li>
              <li className="list-group-item list-group-item-action">Arrest wastage of raw materials while processing.</li>
              <li className="list-group-item list-group-item-action">Export quality finishing with excellent cooking characteristics.</li>
              <li className="list-group-item list-group-item-action">Better Taste and Aroma preservation technique.</li>
              <li className="list-group-item list-group-item-action">Minimized cost of operations.</li>
              <li className="list-group-item list-group-item-action">Reduced manpower requirement.</li>
              <li className="list-group-item list-group-item-action">Promise of increment in profits using our solutions.</li>
            </ul>
        </div>
      </div>
      <div className="col-md-6 col-sm-10 align-middle d-flex justify-content-center align-items-center">
        <img className="img-fluid rounded" src={Benefits} height={'400px'}></img>
      </div>
    </div>
  )
}

function SectionWhyUs() {
  return (
    <div>
      <div className="row my-4">
        <div className="col-md-6 col-sm-12 align-middle d-flex justify-content-center align-items-center">
          <img src={Whyus} className="img-fluid rounded mt-2"></img>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="card shadow  bg-primary">
            <div className="card-header text-white"><h5 >Why Us ?</h5></div>
              <ul className='list-group light-group-flush'>
                <li className="list-group-item list-group-item-action">Sensitivity to pricing and efficiency of products.</li>
                <li className="list-group-item list-group-item-action">A host of customized Product Solutions at one-stop</li>
                <li className="list-group-item list-group-item-action">Drive to innovate and focus on R&D</li>
                <li className="list-group-item list-group-item-action">Reduced Delivery time</li>
                <li className="list-group-item list-group-item-action">Skilled, Dedicated & Well Trained Team of 350+ Professionals to assist</li>
                <li className="list-group-item list-group-item-action">One-step shop for Customized Productsand Turnkey Solutions in Agro-processing</li>
                <li className="list-group-item list-group-item-action">Our belief in Customer Delight</li>
              </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function Mission() {
  return (
    <div>
      <div className='container-fluid'>
        <PageHeader img={BannerVision} img_mobile={BannerVisionMobile}  head="Vision & Mission"></PageHeader>
      </div>
      <div className="container">
        <div className="row text-center">
          <h2>Sona Machinery</h2>
        </div>
        <div className="row text-justify align-middle mx-5 mt-3">
          <h6 className="">We aim at providing smart technology solutions and facilitating the growth of the Agro-processing & Grain Milling (Grain Based Distilleries & their EPC) industry in India and Globally, while contributing to the supply of world class food products and to society. </h6>
        </div>
        
        <SectionBusinessScope></SectionBusinessScope>
        <SectionBenefits></SectionBenefits>
        <SectionWhyUs></SectionWhyUs>
      </div>
    </div>
  )
}
