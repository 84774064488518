import NI8831 from "./../../images/products/ricewhitener/neo/1.jpg";
import NI8834 from "./../../images/products/ricewhitener/neo/2.jpg";
import NI8840 from "./../../images/products/ricewhitener/neo/3.jpg";
import NI8843 from "./../../images/products/ricewhitener/neo/4.jpg";
import NI8856 from "./../../images/products/ricewhitener/neo/5.jpg";
import NI8863 from "./../../images/products/ricewhitener/neo/6.jpg";

import PI0004 from "./../../images/products/ricewhitener/pro/1.jpg";
import PI9982 from "./../../images/products/ricewhitener/pro/2.jpg";
import PI9983 from "./../../images/products/ricewhitener/pro/3.jpg";
import PI9988 from "./../../images/products/ricewhitener/pro/4.jpg";
import PI9990 from "./../../images/products/ricewhitener/pro/5.jpg";
import PI9993 from "./../../images/products/ricewhitener/pro/6.jpg";


const neoImages = [
    { original: NI8831, thumbnail: NI8831 },
    { original: NI8834, thumbnail: NI8834 },
    { original: NI8840, thumbnail: NI8840 },
    { original: NI8843, thumbnail: NI8843 },
    { original: NI8856, thumbnail: NI8856 },
    { original: NI8863, thumbnail: NI8863 },
]

const proImages = [
    { original: PI0004, thumbnail: PI0004 },
    { original: PI9982, thumbnail: PI9982 },
    { original: PI9983, thumbnail: PI9983 },
    { original: PI9988, thumbnail: PI9988 },
    { original: PI9990, thumbnail: PI9990 },
    { original: PI9993, thumbnail: PI9993 },
]

const ricewhitener = [
    {
        id: 'rice-whitener-pro-4s',
        title: 'Rice Whitener Pro-4s',
        series: 'RICE WHITENER PRO',
        images: proImages,
        video: "https://www.youtube.com/embed/Rf8HkDra5ug",
        description: 'Rice whitener is used to removing the bran layer, Rice Whiteners works on the principle of friction, the brown rice is equally fed by distributor into the machine installed with the abrasive stones. Here, brown rice moves downward along with abrasive stones and the Bran is removed by friction in between the rice. The bran which is now in powder form ooze out from the sieves, which then sucked out by aspiration system into the brancleaner machine.',
        usp: ['Whiteners are designed for superior output of whitened rice with less percentage of broken', 'Provides uniform whiteness on all the grains', 'Mounted with a differential type manometer for accurate control of chamber pressure', 'Capable of handling Raw, Steamed and Parboiled Rice'],
        specification: [{ 'Product Name': 'Rice Whitener' }, { 'Model': 'PRO 4S' }, { 'Capacity': '4 - 5 TPH' }, { 'Power Consumption (HP) (Recommended)': '30 HP' }, { 'Weight (Kg)': '1100' }, { 'Dimensions (L*W*H in mm)': '1560*620*1480' }],
        technology: ['Huskers function on the technology of breaking open the shells for the paddy by pressing it in between two rubber rolls (to better understand it we can take the example of Peanut. We press open the peanut shell by pressing it with our fingers to obtain the nut out of it). The paddy is fed using a vibro motor in the straight line into the rubber rolls, which crunch the paddy and open it from both the ends. There is an aspiration system installed in the machine, which sucks the light weighted husk and dust from the crop.'],
        category: 'Rice Whitener',
        categoryLink: '/rice-whitener'
    },
    {
        id: 'rice-whitener-pro-5s',
        title: 'Rice Whitener Pro-5s',
        series: 'RICE WHITENER PRO',
        images: proImages,
        video: "https://www.youtube.com/embed/Rf8HkDra5ug",
        description: 'Rice whitener is used to removing the bran layer, Rice Whiteners works on the principle of friction, the brown rice is equally fed by distributor into the machine installed with the abrasive stones. Here, brown rice moves downward along with abrasive stones and the Bran is removed by friction in between the rice. The bran which is now in powder form ooze out from the sieves, which then sucked out by aspiration system into the brancleaner machine.',
        usp: ['Whiteners are designed for superior output of whitened rice with less percentage of broken', 'Provides uniform whiteness on all the grains', 'Mounted with a differential type manometer for accurate control of chamber pressure', 'Capable of handling Raw, Steamed and Parboiled Rice'],
        specification: [{ 'Product Name': 'Rice Whitener' }, { 'Model': 'PRO 5S' }, { 'Capacity': '5 - 6 TPH' }, { 'Power Consumption (HP) (Recommended)': '40 HP' }, { 'Weight (Kg)': '1300' }, { 'Dimensions (L*W*H in mm)': '1570*690*1500' }],
        technology: ['Huskers function on the technology of breaking open the shells for the paddy by pressing it in between two rubber rolls (to better understand it we can take the example of Peanut. We press open the peanut shell by pressing it with our fingers to obtain the nut out of it). The paddy is fed using a vibro motor in the straight line into the rubber rolls, which crunch the paddy and open it from both the ends. There is an aspiration system installed in the machine, which sucks the light weighted husk and dust from the crop.'],
        category: 'Rice Whitener',
        categoryLink: '/rice-whitener'
    },
    {
        id: 'rice-whitener-pro-6s',
        title: 'Rice Whitener Pro-6s',
        series: 'RICE WHITENER PRO',
        images: proImages,
        video: "https://www.youtube.com/embed/Rf8HkDra5ug",
        description: 'Rice whitener is used to removing the bran layer, Rice Whiteners works on the principle of friction, the brown rice is equally fed by distributor into the machine installed with the abrasive stones. Here, brown rice moves downward along with abrasive stones and the Bran is removed by friction in between the rice. The bran which is now in powder form ooze out from the sieves, which then sucked out by aspiration system into the brancleaner machine.',
        usp: ['Whiteners are designed for superior output of whitened rice with less percentage of broken', 'Provides uniform whiteness on all the grains', 'Mounted with a differential type manometer for accurate control of chamber pressure', 'Capable of handling Raw, Steamed and Parboiled Rice'],
        specification: [{ 'Product Name': 'Rice Whitener' }, { 'Model': 'PRO 6S' }, { 'Capacity': '8 - 10 TPH' }, { 'Power Consumption (HP) (Recommended)': '60 HP' }, { 'Weight (Kg)': '1500' }, { 'Dimensions (L*W*H in mm)': '1580*800*1700' }],
        technology: ['Huskers function on the technology of breaking open the shells for the paddy by pressing it in between two rubber rolls (to better understand it we can take the example of Peanut. We press open the peanut shell by pressing it with our fingers to obtain the nut out of it). The paddy is fed using a vibro motor in the straight line into the rubber rolls, which crunch the paddy and open it from both the ends. There is an aspiration system installed in the machine, which sucks the light weighted husk and dust from the crop.'],
        category: 'Rice Whitener',
        categoryLink: '/rice-whitener'
    },

    {
        id: 'rice-whitener-neo-5',
        title: 'Rice Whitener Neo-5',
        series: 'RICE WHITENER NEO',
        images: neoImages,
        video: "https://www.youtube.com/embed/Rf8HkDra5ug",
        description: "Rice Whitener's work on the principle of friction, where the brown rice is equally fed by a distributor into the machine which has abrasive stones installed in it. The brown rice moves downward along with abrasive stones and the Bran is removed by friction in between the rice. The bran which is now in a powder form comes out through the sieves, which is then sucked out by aspiration system into the bran cleaner machine.",
        usp: [
            "Whitener's are designed for superior output of whitened rice with less percentage of broken",
            "Provides uniform whiteness on all the grains",
            "Equipped with a differential type manometer for accurate control of chamber pressure",
            "Capable of handling Raw, Steamed and Parboiled Rice"
        ],
        specification: [{ 'Product Name': 'Rice Whitener' }, { 'Model': 'Neo 5' }, { 'Capacity': '4 - 5 TPH' }, { 'Power Consumption (HP) (Recommended)': '40 HP' }],
        category: 'Rice Whitener',
        categoryLink: '/rice-whitener'
    },
    {
        id: 'rice-whitener-neo-7',
        title: 'Rice Whitener Neo-7',
        series: 'RICE WHITENER NEO',
        images: neoImages,
        video: "https://www.youtube.com/embed/Rf8HkDra5ug",
        description: "Rice Whitener's work on the principle of friction, where the brown rice is equally fed by a distributor into the machine which has abrasive stones installed in it. The brown rice moves downward along with abrasive stones and the Bran is removed by friction in between the rice. The bran which is now in a powder form comes out through the sieves, which is then sucked out by aspiration system into the bran cleaner machine.",
        usp: [
            "Whitener's are designed for superior output of whitened rice with less percentage of broken",
            "Provides uniform whiteness on all the grains",
            "Equipped with a differential type manometer for accurate control of chamber pressure",
            "Capable of handling Raw, Steamed and Parboiled Rice"
        ],
        specification: [{ 'Product Name': 'Rice Whitener' }, { 'Model': 'Neo 7' }, { 'Capacity': '6 - 7 TPH' }, { 'Power Consumption (HP) (Recommended)': '60 HP' }],
        category: 'Rice Whitener',
        categoryLink: '/rice-whitener'
    },
];

export default ricewhitener;
