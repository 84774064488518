import React from 'react'

import BannerInvestors from "./../../images/pages/pageheaders/banner_investors.jpg";
import BannerInvestorsMobile from "./../../images/pages/pageheaders/banner_investors_m.jpg";
import InvestorsImg from '../../images/investors/investors.jpg';
import PageHeader from "../global/pageheader";

export default function InvestorGrievances() {

    return (
        <div className="container-fluid">
            <PageHeader img={BannerInvestors} img_mobile={BannerInvestorsMobile} head="Investor Grievance"></PageHeader>
            <div className="card mt-2 mb-2 shadow" >
                <div className="card-header">
                    Investor Grievance
                </div>
                <div className="card-body">
                    <div className="m-5" >
                        <div className="row fw-bold">
                            Contact Information of the official Who is responsible for assisting and handling investor grievance
                        </div>
                        <div className="row">
                            Ms. Supriya <br/>
                            F-16, C-1 Tronica City, Loni Industrial Area, Ghaziabad 201102
                        </div>
                        <div className="row fw-bold mt-5">
                            Email Id
                        </div>
                        <div className="row">
                            cs@sonamachinery.com
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}