import React from 'react';
import NEO from '../../../images/products/ricewhitener/neo/2.jpg';
import PRO from '../../../images/products/ricewhitener/pro/1.jpg';
import Turn from '../../../images/turn.png';

import { Link } from 'react-router-dom';
import PageHeader from "./../../global/pageheader";
import ProductTile from '../productTile';

export default function Ricewhitener() {

  const ricewhitener = [
    {
      id: 3,
      img: PRO,
      title: 'Rice Whitener Pro',
      link: '/rice-whitener-pro-5S'
    },
    {
      id: 5,
      img: NEO,
      title: 'Rice Whitener Neo',
      link: '/rice-whitener-neo-7'
    },
  ]

  return (
    <div className="container-fluid">
      <PageHeader img={Turn} head="Rice Whitener"></PageHeader>
      <div className="container">
        <div className="row">
          {
            ricewhitener.map((item, i) => {
              return ProductTile({
                i,
                link: item.link,
                title: item.title,
                img: item.img
              })
            })
          }
        </div>
        <div className="my-5">
          <div className="row mt-2">
            <h2 className="fw-bold">Why Rice Whitener ?</h2>
            <p className="">Rice whitener is used to removing the bran layer, Rice Whiteners works on the principle of friction, the brown rice is equally fed by distributor into the machine installed with the abrasive stones. Here, brown rice moves downward along with abrasive stones and the Bran is removed by friction in between the rice. The bran which is now in powder form ooze out from the sieves, which then sucked out by aspiration system into the bran cleaner machine.</p>
          </div>
          <div className="row mt-2">
            <h2 className="fw-bold">What are the usage of Rice Whitener ?</h2>
            <p className="">Rice whiteners used to remove bran layer from the rice.</p>
          </div>
          <div className="row mt-2">
            <h2 className="fw-bold">What are the benefits of Rice Whitener ?</h2>
            <p className="">
              <ul>
                <li>Whiteners are designed for superior output of whitened rice with less percentage of broken</li>
                <li>Provides uniform whiteness on all the grains</li>
                <li>Mounted with a differential type manometer for accurate control of chamber pressure</li>
                <li>Capable of handling Raw, Steamed and Parboiled Rice</li>
              </ul>
            </p>
          </div>
        </div>
      </div >
    </div>
  )
}
