import React from 'react'
import SonaNavbar from './navbar'
import Footer from './footer'

export default function Appwrap({ children }) {
  return (
    <div>
      <SonaNavbar />
      {children}
      <Footer />
    </div>
  )
}
