import React, { useState, useRef } from 'react'
import Logo from '../src/images/logo.png'
import Home from './components/HomePage/index'
import Contact from './components/contact'
import Teams from './components/teams'
import About from './components/AboutUs/index'
import Careers from './components/Careers/index'
import Contentwriter from './components/jobs/content/contentwriter'
import Salesexecutive from './components/jobs/content/salesexecutive'
import Qualitycheck from './components/jobs/content/qualitycheck'
import Precleaner from './components/products/cleaner/precleaner'
import Finecleaner from './components/products/cleaner/finecleaner'
import Stoneseperator from './components/products/cleaner/stoneseperator'
import Formone from './components/Form/formone'


import Paddyhusker from './components/products/millers/paddyhusker'
import Huskfan from './components/products/millers/huskfan'

import PaddySeparator from './components/products/millers/paddyseperator'
import Ricewhitener from './components/products/millers/ricewhite'
import Silkypolisher from './components/products/millers/silkypolish'

import Sizer from './components/products/grading/sizer'
import Multigrader from './components/products/grading/multigrader'
import Lengthgrader from './components/products/grading/lengthgrader'

import Graindischarger from './components/products/material/graindischarger'

import Product from './components/productcategories/productPage'

import Blogs from './components/blogs'
import BlogsPage from './components/blogs/blogPage'

import News from './components/NewsAndEvents/news'
import NewsPage from './components/NewsAndEvents/newsPage'

import JobPage from './components/Careers/jobPage';

import Events from './components/Events/index'
import EventsPage from './components/Events/eventPage';

import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from "react-router-dom";
import Appwrap from './components/global/appwrap'

import Mission from './components/mission'
import Turnkeysolution from './components/solutionsPages/turnkeysolution';
import Graindistillery from './components/solutionsPages/graindistillery'
import Grainmachinery from './components/solutionsPages/grainmachinery'
import Design from './components/solutionsPages/design'
import Spares from './components/solutionsPages/spares'
import Investors from './components/investors/investors'

import Narendra from './components/Teaminfo/narendra'
import Vasu from './components/Teaminfo/vasu'
import Swetha from './components/Teaminfo/swetha'
import NotFound from './components/notfound';
import SonaTerms from './components/Terms/terms';
import Thankyou from './components/ThankYou/thankyour';
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';

import BeltConveyor from "./components/solutionsPages/materialhandling/beltconveyor";
import BucketElevator from "./components/solutionsPages/materialhandling/bucketelevator";
import ChainConveyor from "./components/solutionsPages/materialhandling/chainconveyor";
import ScrewConveyor from "./components/solutionsPages/materialhandling/screwconveyor";

import CatalogueRequest from './components/cataloguerequest'
import PreCleanerPcs from './components/solutionsPages/precleanerpcs'

import { FloatingWhatsApp } from 'react-floating-whatsapp'
import FinancialReports from './components/investors/financialReports'
import BoardComposition from './components/investors/boardComposition'
import ShareHoldingPattern from "./components/investors/shareHoldingPattern";
import Committees from "./components/investors/committees";
import InvestorNews from "./components/investors/investorNews";
import InvestorContact from './components/investors/investorContact'
import InvestorGrievances from './components/investors/investorGrievances'
import CFCS from './components/investors/cfcs'
import RTA from './components/investors/rta'


const pageInfo = {
  "/": {
    "title": "Sona Machinery - Leading Rice Mill Machinery & Grain Based Distillery Manufacturer & Supplier",
    "description": "Automatic Rice Mill Machinery Manufacturer, Grain Based Distillery & Grain Handling System for Grain Distilleries Manufacturers & Suppliers & exporters in India, Sri Lanka, Bangladesh, Nepal, United States, Canada, Brazil, China, Kenya, Nigeria, Tanzania, Uganda, & United Arab Emirates.",
    "keywords": "rice mill machinery manufacturer, grain processing machinery, grain based distillery, rice mill project, grain based ethanol plant manufacturers in india, distillery equipment manufacturers, rice milling machine"
  },
  "/turnkey-rice-mill-solutions": {
    "title": "Rice Mill Project, Automatic Turnkey Rice Mill Plant Setup, Rice Milling Machinery Manufacturer & Supplier in India",
    "description": "Sona Machinery: A Top Original Equipment Manufacturer of Fully Automatic Turnkey Rice Mill Plant, Rice Mill Machinery and Equipments in India and provides wide range of turnkey machinery Solutions for processing aromatic basmati rice, non-basmati medium and short grains.",
    "keywords": "rice mill manufacturer, fully automatic rice mill plant cost, rice processing mill, rice processing machine supplier, rice mill machine manufacturer, rice milling equipment"
  },
  "/epc-solutions-for-grain-unloading-and-milling": {
    "title": "Grain Based Distillery Plant, Grain Handling System, Ethanol Plant, Grain Unloading & Milling Section Equipment Manufacturer in India",
    "description": "Sona Machinery: EPC solution for Grain-Based distillery plants - Unloading & Milling Sections, Ethanol plants, Grain handling System Machinery & Equipments Manufacturer and supplier in India, Provides Grain Unloading and Milling Solutions.",
    "keywords": "grain based ethanol plant, grain based distillery plant manufacturers, distillery equipment manufacturers, grain handling equipment"
  },
  "/pre-cleaner": {
    "title": "Top Rice and Paddy Pre Cleaner Machine Manufacturer, Supplier in India",
    "description": "Sona Machinery One of the top Manufacturer and Supplier of Rice Pre Cleaner Machine, Paddy Cleaner and Destoner Machine. To know the price, models, capacity and more call us at:  +91-9599-002201.",
    "keywords": "rice pre cleaner machine, grain pre cleaner machine, padde pre cleaner machine, manufacturer of pre cleaner machine, grain handling system, rice milling machine"
  },
  "/fine-cleaner": {
    "title": "Rice Fine Cleaner Machine, Grain & Seed Machine Manufacturer",
    "description": "Sona Machinery: India's Top Manufacturers and Suppliers of Modern Rice, Grain & Seed Fine Cleaning Machines and Equipments. To know or order contact: +91-9599-002201.",
    "keywords": "Rice Fine Cleaner, Rice Cleaner, rice grain processing machine"
  },
  "/stone-separator": {
    "title": "Stone Separator Machine, Rice & Grain Stone Separator Manufacturer & Supplier India",
    "description": "Looking to buy Rice Stone Separator, Grain Stone Separator machine and equipments? Contact Sona Machinery, one of the top manufacturer and supplier of Stone Separator, Rice Mill and Grain Based Distillery products in India.",
    "keywords": "rice destoner machine, paddy cleaner and destoner, paddy destoner machine, grain cleaning machine, rice milling machine"
  },
  "/paddy-husker": {
    "title": "Paddy Husker Machine, Rice Processing Paddy Separator Machine Manufacturer",
    "description": "Elevate your rice processing with our efficient Paddy Husker Machine supremely used for de-husking the paddy from rice and other food grains.The machine plays an important role in rice processing project.",
    "keywords": "paddy husker machine, paddy separator machine, paddy husker rice milling machine, rice dehusking machine, rice processing machine"
  },
  "/contact": {
    "title": "Contact Sona Machinery: Rice Mill Plant, Grain Processing Machine, Seed & Feed Processing Machinery",
    "description": "Planning to Setup a Rice Mill and Grain Based Distillery Plant? Contact Sona Machinery Top Manufacturer & Supplier of Grain Based Distillery - Unloading and Milling Machinery",
    "keywords": "Rice Mill Machinery, Rice processing machinery, Rice Mill Plant, Rice Processing Plant, manufacturers, suppliers, exporters, traders, dealers, manufacturing companies, retailers, producers, Food Grains & Nut Processing Machine India, Rice milling machinery manufacturers, Japanese rice milling plant machine suppliers, automatic rice mill machines, rice milling equipment, Sona Machinery PVT LTD"
  },
  "/husk-fan": {
    "title": "Huskfan, Paddy, Rice & Grain Husking Automatic Fan, Equipment Manufacturer",
    "description": "Elevate your paddy & grain processing with our efficient and automatic Huskfan Machine supremely used for de-husking the paddy from rice and other food grains.The machine plays an important role in rice processing project.",
    "keywords": "Huskfan, Huskfan Rice milling equipment, Husk separation Machine, Huskfan product manufacturer"
  },
  "/paddy-separators": {
    "title": "Paddy Separator Machine, Rice Separator Machine Manufacturer in India",
    "description": "Rice and Paddy Seperator Machine Manufacturer and Supplier in India, Sona Machinery would be trusted partner for those seeking premium rice and paddy separation solutions to amplify their production processes.",
    "keywords": "paddy separator machine, paddy to rice machine"
  },
  "/rice-whitener": {
    "title": "Rice Whitener Milling Machine, Equipment Manufacturer & Supplier India",
    "description": "Sona Machinery a reliable partner for those seeking top rice whitener machine & equipment to elevate their processing operations. We Manufacture and supply advanced rice whitener milling machine equipment, catering to the evolving needs of the rice processing industry.",
    "keywords": "rice whitener machine,rice whitener price,rice mill whitener machine, rice milling machine"
  },
  "/silky-polisher": {
    "title": "Silky Rice Polisher Machine Manufacturer & Supplier in India",
    "description": "We and Sona Machinery a top rated manufacturer and supplier of high-quality silky rice polisher machines, catering to the rice processing industry's demands. Our Silky Rice Polisher machines, designed to optimize processing operations and deliver premium polished rice.",
    "keywords": "silky rice polisher machine price, rice polisher machine, rice miliing machine, rice polishing machine"
  },
  "/sizer": {
    "title": "Rice Sizer Machine, Industrial Sizer Machine Manufacturer & Supplier India",
    "description": "Sona Machinery, a leading manufacturer and supplier of advanced industrial sizer machines, we are dedicated to delivering cutting-edge solutions that optimize material sizing processes across various industries.",
    "keywords": "rice sizer machine, rice mill machinery"
  },
  "/multi-grader": {
    "title": "Rice & Grain Multi Grader Machine Manufacturer & Supplier in India",
    "description": "Sona Machinery, a trusted provider of high-quality industrial Multi Grader Rice Machine, which optimizes operations, increases yields, and ensures consistent and reliable grading results.",
    "keywords": "Multi Grader, Industrial Multi Grader, Industrial Multi Grader Machine Suppliers, Industrial Multi Grader Machine Manufacturer"
  },
  "/length-grader": {
    "title": "Length Grader Rice Milling Machine, Best Manufacturer & Supplier India",
    "description": "Optimize product quality and efficiency with a state-of-the-art Length Grader Machine, Sona Machinery is a trusted provider of high-quality industrial rice milling equipment, including the Length Grader, which ensures consistent and reliable grading results.",
    "keywords": "Length Grader, Industrial Length Grader, Industrial Length Grader Machine Suppliers, Length Grader Machine Manufacturer"
  },
  "/products/blending-RKB30A": {
    "title": "FRK Blending Machine, Blending Machine for Rice Mill, Best Manufacturers",
    "description": "Elevate your rice milling process with Sona Machinery's advanced FRK Rice blending machine. fully automatic Certified Blender. A perfect fortification for various blends of rice varieties for superior quality and customer satisfaction. Optimize your production efficiency with precision mixing technology.",
    "keywords": "frk blending machine, blending machine for rice mill, rice mill machinery, blending machine manufacturers"
  },
  "/material-handling/rice-mill-bucket-elevator": {
    "title": "Grain Handling Bucket Elevator, Rice Mill Bucket Elevator Machine",
    "description": "Enhance your rice mill operations with Sona Machinery's efficient Rice Mill Bucket Elevator. Seamlessly transport rice grains vertically, optimizing space and workflow. Trust in advanced technology, unmatched superior quality to elevate your productivity and streamline the rice processing journey.",
    "keywords": "grain handling bucket elevator, rice and grain handling system, grain material handling equipement, rice mill bucket elevator, industrial grain milling machine"
  },
  "/material-handling/belt-conveyor": {
    "title": "Belt Conveyor, Rice & Grain Mill Conveyor, Industrial Conveyor Handling System Manufacturer",
    "description": "Improve efficiency, save time, and optimize your rice milling workflow, Sona Machinery, We manufacture and supplies high quality rice & grain belt conveyor hanlding system for loading and unloading. Best Material Handling Equipment for Rice Mills and Grain Based Distilleries.",
    "keywords": "grain belt conveyor, rice mill conveyor, rice and grain handling system, industrial grain milling machine"
  },
  "/material-handling/chain-conveyor": {
    "title": "Chain Conveyor, Grain Handling Chain Conveyor, Best Rice Mill Chain Conveyor Supplier in India",
    "description": "Sona Machinery a Leading Chain Conveyor Manufacturers in India offering innovative solutions for material handling. Explore reliable, custom-built conveyor systems for Grain Based Distillery industry to Elevate efficiency. Best Material Handling Equipment for Rice Mills and Grain Based Distilleries.",
    "keywords": "grain chain conveyor, rice chain conveyor, rice and grain handling system, industrial grain milling machine"
  },
  "/material-handling/screw-conveyor": {
    "title": "Screw Conveyor Manufacturers, Best Rice Mill Screw Conveyor Supplier in India",
    "description": "Screw Conveyor; Elevate your production process with precision engineering. Sona Machinery's a top most leading manufacturer and supplier in Inda expertly crafted Screw Conveyor solutions for Grain Unloading & Milling. Best Material Handling Equipment for Rice Mills and Grain Based Distilleries.",
    "keywords": "grain screw conveyor, rice screw conveyor, rice and grain handling system, industrial grain milling machine"
  },
  "/products/flour-sifter": {
    "title": "Industrial Flour Sifter Machine for Grain Based Distilleries, Best Flour Sifter Machine Supplier in India",
    "description": "Industrial Flour Sifter Machine; Sona Machinery's a top most leading manufacturer and supplier in India for Industrial Flour Sifter Machine for Grain Based Distillery - Unloading & Milling. Best Material Handling Equipment for Rice Mills and Grain Based Distilleries.",
    "keywords": "material handling equipment, grain handling equipment, flour milling machine, industrial grain milling machine"
  },
  "/about": {
    "title": "About Sona Machinery | Top Industrial Machinery Manufacturing Company India",
    "description": "Sona Machinery is the manufacturer, supplier, and exporter from India dealing in Agri-Machinery Equipments. It was started in 1995 by Mr Narendra Kumar and Mr Vasu Naren to provide Turnkey Rice Mill Plants, Rice Mill Machinery, Grain Processing Machinery and Grain Handling System (Grain Based Distillery - Unloading & Milling Section) for Ethanol producing Plants.",
    "keywords": "Industrial Machinery, Conveyor Systems, Rice Mill Equipment, Blending Machines, Screw Conveyors, Chain Conveyors, Rice Processing Technology"
  },
  "/vision-and-mission": {
    "title": "Sona Machinery Vission & Mission | Innovation in Agro-processing & Grain Milling industry",
    "description": "Sona Machinery, aim at providing smart technology solutions and facilitating the growth of the Agro-processing & Grain Milling. Company provides Solutions for Complete Rice Mill Projects, Rice Mill Machinery, and Grain Based Distilleries - Unloading & Milling Sections (Grain Handling System), while contributing to the supply of world class food products and to society.",
    "keywords": "Industrial Machinery, Conveyor Systems, Rice Mill Equipment, Blending Machines, Screw Conveyors, Chain Conveyors, Rice Processing Technology"
  },
  "/events/our-events": {
    "title": "Sona Machinery Events | Industrial Event, Rice, Grain Milling Event in India",
    "description": "Find the list of events about milling industires conducted by Sona Machinery",
    "keywords": ""
  },
  "/events/upcoming": {
    "title": "Sona Machinery Upcoming Events | Upcoming Industrail Event in India",
    "description": "Find the list of upcoming events about milling industires in India.",
    "keywords": ""
  },
  "/news/2023": {
    "title": "Sona Machinery Celebrates Milestones in Agricultural Innovation",
    "description": "Sona Machinery, we're thrilled to announce several key milestones that Launch of Innovative Grain Separator Series, Award for Technological Excellence, Collaboration with Agricultural Research Institutes, Community Outreach Program and much more.",
    "keywords": ""
  },
  "/news/2022": {
    "title": "News 2022 | Sona Machinery",
    "description": "Find the news of 2022 by Sona Machinery about grain milling industires and more.",
    "keywords": ""
  },
  "/news/2021": {
    "title": "News 2021 | Sona Machinery",
    "description": "Find the news of 2021 by Sona Machinery about grain milling industires and more.",
    "keywords": ""
  },
  "/blogs": {
    "title": "Pro Tips & Hacks from Sona Experts. Read Blog for the insights!",
    "description": "Explore our blog for a deep dive into the world of machinery, engineering, and industrial solutions. Our team of experts shares insights, tips, and industry trends to keep you informed and empowered.",
    "keywords": ""
  },
  "/careers": {
    "title": "Career Opportunities at Sona Machinery | Stay update with Sona Machinery",
    "description": "Sona Machinery, we value talent, innovation, and dedication. Join us on our journey to revolutionize agricultural technology and shape the future of farming. Explore fulfilling career opportunities and become part of a dynamic team committed to excellence.",
    "keywords": ""
  },
  "/vission-and-mission": {
    "title": "Sona Machinery Vission & Mission | Innovation in Agro-processing & Grain Milling industry",
    "description": "Sona Machinery, aim at providing smart technology solutions and facilitating the growth of the Agro-processing & Grain Milling. Company provides Solutions for Complete Rice Mill Projects, Rice Mill Machinery, and Grain Based Distilleries - Unloading & Milling Sections (Grain Handling System), while contributing to the supply of world class food products and to society..",
    "keywords": ""
  },
  "/management-teams": {
    "title": "Meet Our Team - Experts in Rice Milling, Grain Processing, Grain Based Distillery Solutions Industry",
    "description": "Explore the powerhouse behind Sona Machinery. Meet our team of dedicated professionals, each contributing their expertise to elevate our machinery solutions. Get to know the faces driving innovation and excellence at Sona Machinery..",
    "keywords": ""
  },
  "/grain-processing-machinery": {
    "title": "Grain Processing Machinery, Flour Milling Machinery, Seed and Grain Cleaning Machines",
    "description": "Sona Machinery one of the top manufacturer & exporter of grain processing machine, flour milling machine, seed cleaning machine, grain cleaning machine, wheat de stoning machine, Rice milling machinery, Feed processing, Spice processing machine",
    "keywords": "grain processing machine,flour milling machine, seed cleaning machine, grain cleaning machine, Feed Processing, Spice processing"
  },
  "/length-grader/lg-03": {
    "title": "Rice Length Grader Machine Cost, Best Supplier in India",
    "description": "Buy Rice Length Grader Machine & Equipments from Sona Machinery, a leading Manufacturer & Supplier in India. See the price, weight, capacity, power consumption and more.",
    "keywords": "rice length grader machine"
  },
  "/length-grader/lg-01": {
    "title": "Length Grader-LG-01 Grain Machine Price, Best Manufacturer & Supplier India",
    "description": "Buy Easy maintenance and minimum operating cost Multi Grader LG 01 grain Machine from Sona Machinery. Find Details about the Machine Price, Weight, Capacity, Power Consumption and more Call at  +91-9599-002201",
    "keywords": "rice length grader machine"
  },
  "/length-grader/lg-01c": {
    "title": "Length Grader Industrial Machine Price, Best Manufacturer & Supplier India",
    "description": "Order online Easy maintenance and minimum operating cost Multi Grader LG 01C Machine from Sona Machinery. Find Details about the Machine Price, Weight, Capacity, Power Consumption and more Call at  +91-9599-002201",
    "keywords": "Length Grader-LG-01C Rice Machine"
  },
  "/rice-multi-grader/shifter-150": {
    "title": "Rice Milling Multi Grader Shifter 150 Machine Cost, Manufacturer & Exporter in India",
    "description": "Buy Multi Grader Rice Milling Shifter-125 Machine and equipment form Sona Machinery. See the product details like - Price, Capacity, Power Consumption, Weight, Dimensions and more. Call at +91-9599-002201",
    "keywords": "rice grader machine, rice length grader machine"
  },
  "/rice-multi-grader/shifter-125": {
    "title": "Rice Multi Grader Shifter Machine Price, Manufacturer & Supplier in India",
    "description": "Buy Multi Grader Shifter Machine and equipment form Sona Machinery. See the product details like - Price, Capacity, Power Consumption, Weight, Dimensions and more. Call at +91-9599-002201",
    "keywords": "rice grader machine, rice length grader machine"
  },
  "/management-teams/founder": {
    "title": "Sona Machinery | Management Team, Know more about Founder, CEO & Core Team",
    "description": "Mr Narendra Kumar founded Sona Foods, now known as Sona Machinery, in 1995 to empower the food processing industry of India with its customized rice milling plants and solutions that fit customers’ resources and market trends.",
    "keywords": "Sona Machinery, Founder, Managing Director, CEO & Core Management Team"
  },
  "/management-teams/chairman-and-managing-director": {
    "title": "Sona Machinery | Management Team, Know more about Chairman & Managing Director",
    "description": "Mr Vasu Naren is the driving force behind Sona Machinery, under his Leadership the company has become the leading Original Equipment Manufacturer in Rice Milling Machinery, Rice Mill Projects, Grain Processing Machinery and Grain Based Distillery - Unloading & Milling.",
    "keywords": "Sona Machinery, Chairman & Managing Director & Core Management Team"
  },
  "/engineering-and-designing-consultation": {
    "title": "Rice Mill Solution, Industrial Rice Milling Engineering & Designing Consultant",
    "description": "Rice Mill Project designing, Grain Based Distillery Project Design, Grain Handling System Design, Unloading and Milling System project design and capacity calculation.",
    "keywords": "Rice Mill Solution, Grain Machinery Solution, Grain Based Distillery - Unloading & Milling, Grain Handling System, Ethanol Plants"
  },
  "/spares-and-services": {
    "title": "Rice & Grain Milling Machine Spares Parts Manufacturer, Supplier in India",
    "description": "Sona Machinery, a leading manufacturer and supplier of high-quality industrial milling spare parts globally. Our engineered components ensure seamless operation, longevity, and optimal performance of rice and grain milling machinery. Elevate your equipment's efficiency with our reliable spare parts solutions.",
    "keywords": "rice mill machine parts manufacturer, rice mill spare parts"
  },
  "/pre-cleaner/drc-25": {
    "title": "DRC 25, Rice & Grain Handling Pre Cleaner Machine, Manufacturer, Price, Capacity, Power Consumption",
    "description": "Buy Low maintenance, Easy servicing Rice and Grain handling pre cleaner DRC 25 Machine form Sona Machinery. Find the details like Capacity, Power Consumption, Weight, Dimensions & more. Call at +91-9599-002201.",
    "keywords": "rice pre cleaner machine, grain pre cleaner machine, padde pre cleaner machine, manufacturer of pre cleaner machine, grain handling system, rice milling machine"
  },
  "/pre-cleaner/hc-60": {
    "title": "Pre Cleaner HC 60 for Grains & Rice, Machine Cost, Capacity, Power Consumption",
    "description": "Order HC 60 Pre Cleaner Rice Machine form Sona Machinery, Top Manufacturer & Supplier. Find The Product details like model name, Capacity, Power Consumption, Weight and more,  Call us at +91-9599-002201",
    "keywords": "rice pre cleaner machine, grain pre cleaner machine, padde pre cleaner machine, manufacturer of pre cleaner machine, grain handling system, rice milling machine"
  },
  "/pre-cleaner/hc-70": {
    "title": "Pre Cleaner HC 70 Grain & Paddy Machine, Price, Capacity, Power Consumption",
    "description": "Grain & Paddy Order Pre Cleaner HC 67 Grain Pre Cleaner Machine form Sona Machinery. Find The Product details like model name, Capacity, Power Consumption, Weight and more,  Call us at +91-9599-002201",
    "keywords": "rice pre cleaner machine, grain pre cleaner machine, padde pre cleaner machine, manufacturer of pre cleaner machine, grain handling system, rice milling machine"
  },
  "/pre-cleaner/pcs": {
    "title": "Pre Cleaner PCS Machine Cost, Capacity, Power Consumption",
    "description": "Buy Pre Cleaner PCS Machine and equipment form Sona Machinery. Find Product details like - Capacity, Power Consumption, Weight, Dimensions and more. Call at +91-9599-002201",
    "keywords": "rice pre cleaner machine, grain pre cleaner machine, padde pre cleaner machine, manufacturer of pre cleaner machine, grain handling system, rice milling machine"
  },
  "/pre-cleaner/pcs-dr": {
    "title": "Pre Cleaner PCS Machine Cost, Capacity, Power Consumption",
    "description": "Buy Pre Cleaner PCS Machine and equipment form Sona Machinery. Find Product details like - Capacity, Power Consumption, Weight, Dimensions and more. Call at +91-9599-002201",
    "keywords": "rice pre cleaner machine, grain pre cleaner machine, padde pre cleaner machine, manufacturer of pre cleaner machine, grain handling system, rice milling machine"
  },
  "/pre-cleaner/pcs-2dr": {
    "title": "Pre Cleaner PCS 2 DR Machine Price, Capacity, Power Consumption, Weight",
    "description": "Buy Easy to use, Sustainable Pre Cleaner PCS 2 DR Machine form Sona Machinery. Model Name PCS 2DR, Capacity 8 - 10 TPH, 2+7.5 Power Consumption, Weight 1400 KG. For more detail call at +91-9599-002201",
    "keywords": "rice pre cleaner machine, grain pre cleaner machine, padde pre cleaner machine, manufacturer of pre cleaner machine, grain handling system, rice milling machine"
  },
  "/fine-cleaner/vcs-10": {
    "title": "Vibro Cleaner, Vibro Classifier Machine Manufacturer and Supplier. Precision Grain Fine Cleaning",
    "description": "Buy Low Maintenance and Higher reliability Vibro Cleaner, Vibro Classifier, Fine Cleaner, VCS 10 Machine from Sona Machinery. Model Name VCS 10, Capacity 6-8 TPH, Power Consumption 0.5 x 2 HP, Weight 680 KG . For more detail call at +91-9599-002201",
    "keywords": "Vibro Cleaner, Vibro Classifier, cleaning machine, grain processing machine, seed cleaning machine, grain processing equipment, multi grain cleaning machine"
  },
  "/fine-cleaner/fine-12": {
    "title": "Rice & Grain Fine Cleaner Machine, Fine 12 Price, Capacity, Power Consumption, Weight",
    "description": "Buy Lower Maintenance cost and Higher reliability fine cleaner Fine 12 Machine form Sona Machinery. Model Name Fine 12, Capacity 6-8 TPH, Power Consumption 0.5 x 2 HP, Weight 680 KG . For more detail call at +91-9599-002201",
    "keywords": "Vibro cleaning machine, grain processing machine, seed cleaning machine, material handling machine, grain processing equipment, multi grain cleaning machine"
  },
  "/fine-cleaner/fine-15": {
    "title": "Rice & Grain Fine Cleaning Machine, Fine 15 Price, Capacity, Power Consumption, Weight",
    "description": "Buy Lower Maintenance cost and Higher reliability fine cleaner Fine 15 Machine form Sona Machinery. Model Name Fine 15, Capacity 12 - 15 TPH, Power Consumption 1 x 2 Nos, Weight 1020 KG . For more detail call at +91-9599-002201",
    "keywords": "Vibro cleaning machine, grain processing machine, seed cleaning machine, material handling machine, grain processing equipment, multi grain cleaning machine"
  },
  "/stone-separator/s-40": {
    "title": "Stone Separator S-40 Rice Milling Machine Price, Best Manufacturer & Exporter",
    "description": "Sona Machinery presents, S-40 Stone Separator, a cutting-edge solution designed for efficient removal of stones and debris from various grains. Elevate your grain processing efficiency with our advanced S-40 Stone Separator, ensuring cleaner and high-quality produce.",
    "keywords": "rice destoner machine, paddy cleaner and destoner, paddy destoner machine, grain cleaning machine, rice milling machine"
  },
  "/stone-separator/pr-5": {
    "title": "Stone Separator PR-5 Milling Machine Price, Manufacturer & Exporter",
    "description": "Sona Machinery PR-5 Stone Separator, a cutting-edge solution designed for efficient removal of stones and debris from various grains. Elevate your grain processing efficiency with our advanced PR-5 Stone Separator Machine, ensuring cleaner and high-quality produce..",
    "keywords": "rice destoner machine, paddy cleaner and destoner, paddy destoner machine, grain cleaning machine, rice milling machine"
  },
  "/stone-separator/eco-5": {
    "title": "Stone Separator ECO-5 Machine Cost, Manufacturer & Supplier",
    "description": "Sona Machinery: India's Top Manufacturers and Suppliers of Modern Rice, Grain & Seed Stone Separator Machines and Equipments. To know more or order contact: +91-9599-002201.",
    "keywords": "rice destoner machine, paddy cleaner and destoner, paddy destoner machine, grain cleaning machine, rice milling machine"
  },
  "/paddy-husker/shel-125": {
    "title": "Paddy Husker Machine SHEL 125,Price & Exporter in India",
    "description": "Buy Paddy Husker Machine and equipment form Sona Machinery. Find Product details like - Capacity, Power Consumption, Weight, Dimensions and more. Call at +91-9599-002201.",
    "keywords": "paddy husker machine, paddy separator machine, paddy husker rice milling machine, rice dehusking machine, rice processing machine, rice rubber sheller"
  },
  "/paddy-husker/shel-104": {
    "title": "Paddy Husker Machine SHEL 104,Price & Exporter in India",
    "description": "Buy Paddy Husker Machine SHEL 104 and equipment form Sona Machinery. Find Product details like - Price, Capacity, Power Consumption, Weight, Dimensions and more. Call at +91-9599-002201",
    "keywords": "paddy husker machine, paddy separator machine, paddy husker rice milling machine, rice dehusking machine, rice processing machine, rice rubber sheller"
  },
  "/husk-fan/aspirators": {
    "title": "Paddy Husker Machine, Automatice Huskfan Aspirators, Manufacturer & Supplier",
    "description": "Buy Automatic Paddy Huskerfan Machine and equipment form Sona Machinery. Find Product details like - Price, Capacity, Power Consumption, Weight, Dimensions and more. Call at +91-9599-002201",
    "keywords": "paddy husker machine, paddy separator machine, paddy husker rice milling machine, rice dehusking machine, rice processing machine"
  },
  "/husk-fan/sn": {
    "title": "Huskfan SN, Price, Manufacturer & Supplier in India",
    "description": "Buy Paddy Huskfan SN Machine and equipment form Sona Machinery. See the product details like - Price, Capacity, Power Consumption, Weight, Dimensions and more. Call at +91-9599-002201",
    "keywords": "paddy husker machine, paddy separator machine, paddy husker rice milling machine, rice dehusking machine, rice processing machine"
  },
  "paddy-separator/rps-20": {
    "title": "Paddy & Rice Separator Machine RPS 20, Price & Manufacturer in India",
    "description": "Buy Paddy & Rice Separator RPS 20, Machine and equipment form Sona Machinery. See the product details like - Price, Capacity, Power Consumption, Weight, Dimensions and more. Call at +91-9599-002201",
    "keywords": "paddy separator machine, paddy to rice machine rice separator machine, paddy processing machine"
  },
  "/paddy-separator/rps-40": {
    "title": "Paddy & Rice Separator Machine RPS 40, Price & Exporter in India",
    "description": "Buy Paddy & Rice Separator RPS 40, Machine and equipment form Sona Machinery. See the product details like - Price, Capacity, Power Consumption, Weight, Dimensions and more. Call at +91-9599-002201",
    "keywords": "paddy separator machine, paddy to rice machine rice separator machine, paddy processing machine"
  },
  "/rice-whitener/pro-5s": {
    "title": "Rice Whitener PRO 5S Machine, Price & Exporter in India",
    "description": "Buy Rice Whitener PRO 5S, Machine and equipment form Sona Machinery. See the product details like - Price, Capacity, Power Consumption, Weight, Dimensions and more. Call at +91-9599-002201",
    "keywords": "rice whitener machine,rice whitener price,rice mill whitener machine, rice milling machine"
  },
  "/rice-whitener/pro-4s": {
    "title": "Rice Whitener PRO 4S Machine, Price & Exporter in India",
    "description": "Buy Rice Whitener PRO 4S, Machine and equipment form Sona Machinery. See the product details like - Price, Capacity, Power Consumption, Weight, Dimensions and more. Call at +91-9599-002201",
    "keywords": "rice whitener machine, rice whitener price, rice mill whitener machine, rice milling machine"
  },
  "/rice-whitener/pro-6s": {
    "title": "Rice Whitener PRO 6S Machine, Price & Supplier in India",
    "description": "Buy Rice Whitener PRO 6S, Machine and equipment form Sona Machinery. See the product details like - Price, Capacity, Power Consumption, Weight, Dimensions and more. Call at +91-9599-002201",
    "keywords": "rice whitener machine, rice whitener price, rice mill whitener machine, rice milling machine"
  },
  "/rice-whitener/neo-7": {
    "title": "Paddy & Rice Whitener NEO 7 Machine, Price & Manufacturer in India",
    "description": "Buy Paddy & Rice Whitener NEO 7, Machine and equipment form Sona Machinery. See the product details like - Price, Capacity, Power Consumption, Weight, Dimensions and more. Call at +91-9599-002201",
    "keywords": "rice whitener machine, rice whitener price, rice mill whitener machine, rice milling machine"
  },
  "/rice-whitener/neo-5": {
    "title": "Paddy & Rice Whitener NEO 5 Machine, Price & Manufacturer in India",
    "description": "Buy Paddy & Rice Whitener NEO 5, Machine and equipment form Sona Machinery. See the product details like - Price, Capacity, Power Consumption, Weight, Dimensions and more. Call at +91-9599-002201",
    "keywords": "rice whitener machine, rice whitener price, rice mill whitener machine, rice milling machine"
  },
  "/rice-silky-polisher/st-3": {
    "title": "Silky Polisher ST-3 Rice Machine Price, Manufacturer & Exporter in India",
    "description": "Buy Silky Polisher ST-3 Rice Machine and equipment form Sona Machinery. See the product details like - Price, Capacity, Power Consumption, Weight, Dimensions and more. Call at +91-9599-002201",
    "keywords": "silky rice polisher machine price, rice polisher machine, rice miliing machine, rice polishing machine"
  },
  "/rice-silky-polisher/st-4": {
    "title": "Silky Polisher ST-4 Rice & Grain Machine Price, Manufacturer & Exporter in India",
    "description": "Buy Silky Polisher ST-4 Rice Machine and equipment form Sona Machinery. See the product details like - Price, Capacity, Power Consumption, Weight, Dimensions and more. Call at +91-9599-002201",
    "keywords": "silky rice polisher machine price, rice polisher machine, rice miliing machine, rice polishing machine"
  },
  "/rice-silky-polisher/st-6": {
    "title": "Rice Silky Polisher ST-6 Machine Cost & Exporter in India",
    "description": "Sona Machinery a top rated manufactuere of Rice Silky Polisher ST-6 Machine in India, To know about machine like Price, Capacity, weight, Power Consumption and more call us at +91-9599-002201",
    "keywords": "silky rice polisher machine price, rice polisher machine, rice miliing machine, rice polishing machine"
  },
  "/rice-sizer/412": {
    "title": "Rice Sizer-412 Machine Cost, Manufacturer, Supplier & Exporter India",
    "description": "Buy Silky Rice Sizer-412 Machine and equipment form Sona Machinery. See the product details like - Price, Capacity, Power Consumption, Weight, Dimensions and more. Call at +91-9599-002201",
    "keywords": "rice sizer machine, rice mill machinery"
  },
  "/rice-sizer/612": {
    "title": "Rice Sizer-612 Machine Cost, Manufacturer, Supplier & Exporter India",
    "description": "Buy Silky Rice Sizer-612 Machine and equipment form Sona Machinery. See the product details like - Price, Capacity, Power Consumption, Weight, Dimensions and more. Call at +91-9599-002201",
    "keywords": "rice sizer machine, rice mill machinery"
  },
  "/investors": {
    "title": "Investors at Sona Machinery"
  }
}


function hashLinkScroll() {
  const { hash } = window.location;
  if (hash !== '') {
    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) element.scrollIntoView();
    }, 0);
  }
}

const indexInfo = pageInfo['/'];

const routeChangeHandler = function (location) {
  let currentInfo = indexInfo;
  if (pageInfo[location.pathname]) {
    currentInfo = pageInfo[location.pathname]
  }

  document.title = currentInfo.title;
  let metaTags = document.getElementsByTagName("META");
  for (let tag of metaTags) {
    if (tag.name == 'description') {
      tag.content = currentInfo.description;
    } else if (tag.name == 'keywords') {
      tag.content = currentInfo.keywords;
    }
  }

  hashLinkScroll();
  // ReactGA.send({ hitType: "pageview", page: location.pathname, title: currentInfo.title });
  ReactPixel.pageView();
}

const App = () => {

  let location = useLocation();
  routeChangeHandler(location);

  return (
    <div className="container-fluids">
      <div className="row opacity-75"
        style={{ position: 'fixed', bottom: '100px', right: '20px', zIndex: '100' }}>
        <a href="tel:+919599002201">
          <i className="bi bi-telephone-fill " style={{ fontSize: '3rem', color: 'green' }}></i>
        </a>
        <FloatingWhatsApp
          phoneNumber="+919599002201"
          accountName="Sona Machinery"
          className=''
          allowEsc
          allowClickAway
          notification
          notificationSound
          avatar={Logo}
        />
      </div>
      <Appwrap className="container-fluid">
        <Routes >
          <Route exact path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/contact-us" element={<Contact />} />

          <Route path="/management-team" element={<Teams />} />
          <Route path="/founders-desk" element={<Teams />} />
          <Route path="/management-team/founders-desk" element={<Teams />} />

          <Route path="/about-us" element={<About />} id='new-about' />

          <Route path="/careers" element={<Careers />} />

          <Route path="/vision-and-mission" element={<Mission />} />
          <Route path="/company-profile" element={<Mission />} />

          <Route path="/pre-cleaner" element={<Precleaner />} />
          <Route path="/cleaning" element={<Precleaner />} />

          <Route path="/fine-cleaner" element={<Finecleaner />} />
          <Route path="/stone-separator" element={<Stoneseperator />} />

          <Route path="/paddy-husker" element={<Paddyhusker />} />
          <Route path="/paddy-husker-2" element={<Paddyhusker />} />

          <Route path="/milling" element={<Paddyhusker />} />

          <Route path="/husk-fan" element={<Huskfan />} />
          <Route path="/paddy-separators" element={<PaddySeparator />} />
          <Route path="/rice-whitener" element={<Ricewhitener />} />
          <Route path="/silky-polisher" element={<Silkypolisher />} />

          <Route path="/sizer" element={<Sizer />} />
          <Route path="/grading" element={<Sizer />} />

          <Route path="/multi-grader" element={<Multigrader />} />
          <Route path="/length-grader" element={<Lengthgrader />} />

          <Route path="/grain-discharger" element={<Graindischarger />} />
          <Route path="/husk-fan" element={<Huskfan />} />

          <Route path="/products/:id" element={<Product />} />

          <Route path="/Pre_Cleaner_PCS" element={<PreCleanerPcs />} />

          <Route path="/material-handling/belt-conveyor" element={<BeltConveyor />} />
          <Route path="/material-handling/rice-mill-bucket-elevator" element={<BucketElevator />} />
          <Route path="/material-handling/chain-conveyor" element={<ChainConveyor />} />
          <Route path="/material-handling/screw-conveyor" element={<ScrewConveyor />} />


          <Route path="/content-writer" element={<Contentwriter />} />
          <Route path="/sales-executive" element={<Salesexecutive />} />
          <Route path="/quality-check" element={<Qualitycheck />} />

          <Route path="/blogs" element={<Blogs />} />

          <Route path="/blog/:id" element={<BlogsPage />} />
          <Route path="/indian-rice-industry-amidst-the-russian-ukrainian-war" element={<Navigate to="/blog/6386f7709cb1b0ed73980241" />} />
          <Route path="/how-to-increase-rice-mills-production" element={<Navigate to="/blog/6386f88e9cb1b0ed73980248" />} />
          <Route path="/what-things-need-to-be-checked-for-setting-up-a-rice-mill" element={<Navigate to="/blog/6386f98c9cb1b0ed73980255" />} />
          <Route path="/sona-machinery-ltd-announces-participation-in-mookambika-tech-expo-2022" element={<Navigate to="/blog/6386fcd49cb1b0ed73980296" />} />

          <Route path="/news/news-and-announcement" element={<News />} />
          <Route path="/news/:id" element={<News />} />
          <Route path="/newspage/:id" element={<NewsPage />} />

          <Route path="/jobs/:id" element={<JobPage />} />

          <Route path="/events/:id" element={<Events />} />
          <Route path="/event/:id" element={<EventsPage />} />


          <Route path="/turnkey-rice-mill-solutions" element={<Turnkeysolution />} />
          <Route path="/3-to-16-tph-rice-mill-plant" element={<Turnkeysolution />} />

          <Route path="/products" element={<Turnkeysolution />} />

          <Route path="/investors" element={<Investors />} />
          <Route path="/investors/board-composition" element={<BoardComposition />} />
          <Route path="/investors/shareholding-pattern" element={<ShareHoldingPattern />} />
          <Route path="/investors/committees" element={<Committees />} />
          <Route path="/investors/investor-news" element={<InvestorNews />} />
          <Route path="/investors/investor-contact" element={<InvestorContact />} />
          <Route path="/investors/investor-grievance" element={<InvestorGrievances />} />
          <Route path="/investors/cfcs" element={<CFCS />} />
          <Route path="/investors/rta" element={<RTA />} />
          
          
          
          <Route path="/investors/financial-reports" element={<FinancialReports />} />

          <Route path="/epc-solutions-for-grain-unloading-and-milling" element={<Graindistillery />} />
          <Route path="/ethanol-grain-milling-plant-manufacturer" element={<Graindistillery />} />

          <Route path="/grain-machinery" element={<Grainmachinery />} />
          <Route path="/grain-processing-machinery" element={<Grainmachinery />} />
          <Route path="/seeds-grains-machinery" element={<Grainmachinery />} />

          <Route path="/engineering-and-designing-consultation" element={<Design />} />
          <Route path="/spares-and-services" element={<Spares />} />

          <Route path="/rd" element={<Home />} />
          <Route path="/form-one" element={<Formone />} />

          <Route path="/sona-original-spares" element={<Spares />} />

          <Route path="/narendra" element={<Narendra />} />
          <Route path="/vasu" element={<Vasu />} />
          <Route path="/swetha" element={<Swetha />} />

          <Route path="/terms" element={<SonaTerms />} />
          <Route path="/privacy-policy" element={<SonaTerms />} />

          <Route path="/thankyou" element={<Thankyou />} />
          <Route path="/catalogue-request" element={<CatalogueRequest />} />
          <Route path="/wp-content/uploads/2022/02/Sona-June-2021.pdf" element={<CatalogueRequest />} />

          <Route path="/rice-blending-machine" element={<Home />} />

          <Route path="/pre-cleaner/drc-25" element={<Product id='pre-cleaner-drc-25' />} />
          <Route path="/pre-cleaner/hc-60" element={<Product id='pre-cleaner-hc-60' />} />
          <Route path="/pre-cleaner/hc-70" element={<Product id='pre-cleaner-hc-60' />} />
          <Route path="/pre-cleaner/pcs-2dr" element={<Product id='pre-cleaner-pcs-2dr' />} />
          <Route path="/fine-cleaner/vcs-10" element={<Product id='fine-cleaner-vcs-10' />} />
          <Route path="/fine-cleaner/vcs-12" element={<Product id='fine-cleaner-vcs-12' />} />
          <Route path="/fine-cleaner/vcs-15" element={<Product id='fine-cleaner-vcs-15' />} />
          <Route path="/stone-separator/s-40" element={<Product id='stone-separator-s-40' />} />
          <Route path="/stone-separator/s-80" element={<Product id='stone-separator-s-80' />} />
          <Route path="/stone-separator/eco-5" element={<Product id='stone-separator-eco-5' />} />
          <Route path="/stone-separator/pr-5" element={<Product id='stone-separator-pr-5' />} />
          <Route path="/paddy-husker/shel-125" element={<Product id='paddy-husker-shel-125' />} />
          <Route path="/paddy-husker/shel-104" element={<Product id='paddy-husker-shel-104' />} />
          <Route path="/husk-fan/sn" element={<Product id='husk-fan-sn' />} />
          <Route path="/paddy-separator/rps-20" element={<Product id='paddy-seperator-rps-20' />} />
          <Route path="/paddy-separator/rps-40" element={<Product id='paddy-seperator-rps-40' />} />
          <Route path="/rice-whitener/pro-4s" element={<Product id='rice-whitener-pro-4s' />} />
          <Route path="/rice-whitener/pro-5s" element={<Product id='rice-whitener-pro-5s' />} />
          <Route path="/rice-whitener/pro-6s" element={<Product id='rice-whitener-pro-6s' />} />
          <Route path="/rice-whitener/neo-5" element={<Product id='rice-whitener-neo-5' />} />
          <Route path="/rice-whitener/neo-7" element={<Product id='rice-whitener-neo-7' />} />
          <Route path="/rice-silky-polisher/st-4" element={<Product id='silky-polisher-st-4' />} />
          <Route path="/rice-silky-polisher/st-6" element={<Product id='silky-polisher-st-6' />} />
          <Route path="/rice-sizer/612" element={<Product id='sizer-612' />} />
          <Route path="/rice-sizer/412" element={<Product id='sizer-412' />} />
          <Route path="/rice-multi-grader/shifter-125" element={<Product id='multi-grader-shifter-125' />} />
          <Route path="/rice-multi-grader/shifter-150" element={<Product id='multi-grader-shifter-150' />} />
          <Route path="/length-grader/lg-01c" element={<Product id='length-grader-lg-01c' />} />
          <Route path="/length-grader/lg-01" element={<Product id='length-grader-lg-01' />} />
          <Route path="/length-grader/lg-03" element={<Product id='length-grader-lg-03' />} />
          <Route path="/flour-sifter" element={<Product id='flour-sifter' />} />

          <Route path="/*" element={<NotFound />} />
        </Routes >
      </Appwrap>

    </div>
  )
}

export { pageInfo };
export default App