import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllblog, blogSelector } from '../../api/blog'

import BannerBlogs from "./../../images/pages/pageheaders/banner_blogs.jpg";
import BannerBlogsMobile from "./../../images/pages/pageheaders/banner_blogs_m.jpg";
import PageHeader from '../global/pageheader'

function BlogEntry(props) {

  return (
    <div className="col-sm-12 col-md-4">
      <a href={props.link}>
        <div className="card shadow mt-4">
          <img className="card-img-top" src={props.img}></img>
          <div className="card-body">
            <div className="card-title mt-2">{props.title}</div>
          </div>
        </div>
      </a>
    </div>
  )
}

export default function Index() {
  const dispatch = useDispatch()
  const { all_blog, loading } = useSelector(blogSelector)

  useEffect(() => {
    dispatch(fetchAllblog())
  }, [dispatch])

  const blogDetails = all_blog.map((b) => BlogEntry({ img: b.image, title: b.title, link: `/blog/${b._id}` }));

  return (
    <div className="mb-4">
      <PageHeader img={BannerBlogs} img_mobile={BannerBlogsMobile} head="Blogs"></PageHeader>
      <div className="row align-middle align-items-center">
        {blogDetails}
      </div>
    </div>
  )
}

