import React from 'react'

export default function PageHeader(props) {
  let mob = !props.img_mobile ? '' :
    <div className="text-center position-relative mb-2 d-block d-md-none  " >
      <img src={props.img_mobile} className="img-flusid img-responsivse roundedn" style={{ maxHeight: '200px', minWidth: '100%' }} ></img>
      <div  className="tint-overlay"></div>
      <h2 className="text-white font-bold carousel-caption d-flex flex-column justify-content-center"
        style={{ top: 0 }}>{props.head} <br /> {props.head2}</h2>
    </div>;

  return (
    <div className='row'>
      <div className="text-center position-relative mb-2 d-none d-md-block">
        <img src={props.img} className="img-flusid img-responsivse rounded" style={{ maxHeight: '400px', minWidth: '100%' }} ></img>
        <div  className="tint-overlay"></div>
        <h2 className="text-white font-bold carousel-caption d-flex flex-column justify-content-center"
          style={{ top: 0 }}>{props.head} <br /> {props.head2}</h2>
      </div>
      {mob}
    </div>
  )
}
