import React from 'react';
import Gradingg from '../../../images/Gradingg.png';
import SHIFTER from '../../../images/products/multigrader/2.jpg';

import { Link } from 'react-router-dom';
import PageHeader from "./../../global/pageheader";
import ProductTile from '../productTile';

export default function Multigrader() {
  const multigrader = [
    {
      id: 1,
      img: SHIFTER,
      title: 'Multi Grader Shifter - 125',
      link: '/multi-grader-shifter-125'
    },
  ]

  return (
    <div className="container-fluid">
      <PageHeader img={Gradingg} head="Multi Grader"></PageHeader>
      <div className="container">
        <div className="row">

          {
            multigrader.map((item, i) => {
              return ProductTile({
                i,
                link: item.link,
                title: item.title,
                img: item.img
              })
            })
          }
        </div>

        <div className="my-5">
          <div className="row">
            <h2 className=" fw-bold">Why Multi Grader ?</h2>
            <p className="">Grader is used to separate rice on the basis of thickness, Multiple sieves are stacked upon each other, with bigger hole size at the top and then whole sizes gets smaller with hierarchy. The grain is fed from the top on to a continuously rotating machine, which in turn separate the broken rice as its diameter from whole rice.</p>
          </div>

          <div className="row">
            <h2 className="fw-bold">What are the usage of Multi Grader ?</h2>
            <p className="">Multi Grader is used for separating broken rice from the finer complete grains, thereby ensuring a fine quality of rice as output.</p>
          </div>

          <div className="row">
            <h2 className="fw-bol">What are the benefits of Multi Grader ?</h2>
            <p className="">
              <ul>
                <li>Easy maintenance and minimum operating cost</li>
                <li>Inbuilt self-cleaning mechanism to ensure optimum separation</li>
                <li>Percentage of the broken rice separation is adjustable</li>
              </ul>
            </p>
          </div></div>
      </div>
    </div>
  )
}
