import React, { useLayoutEffect } from 'react'
import ImageGallery from 'react-image-gallery';
import data from './data';
import { useNavigate, useParams } from 'react-router-dom'
import { NavLink } from "react-router-dom";
import SeriesProduct from './seriesproduct';
import BIS from "./../../images/bis.png";


const baseFolder = "./../../images/products/";

export function ProductImage(props) {
  return (
    <div className="p-2 shadows">
      <ImageGallery style={{ maxHeight: '200px' }} items={props.currentProduct.images}
        showFullscreenButton={false} showNav={false}
        showPlayButton={false} showThumbnails={true}
        originalHeight={'200px'}
        originalWidth={'200px'}
        loading='lazy'
        thumbnailPosition='right'
        autoPlay={true}
        originalClass="border border-primary"
      />
    </div>
  )
}

export default function Product(props) {
  let { id } = useParams()
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  }

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  if (props.id) {
    id = props.id;
  }

  let currentProduct = data.find(item => item.id == id || ('' + item.id).toLowerCase() == ('' + id).toLowerCase());

  let seriesProduct = data.filter(item => item != undefined && currentProduct != undefined && item !== currentProduct && item.series == currentProduct.series);
  return (
    <div className="container mb-4">
      <div className="row my-2">
        <div className='fs-6 mx-5'> <NavLink to="/" onClick={goBack}>Back</NavLink> | <NavLink to="/">Home</NavLink> / <NavLink to={`${currentProduct?.categoryLink}`}>{currentProduct?.category}</NavLink>
        </div>
      </div>

      <div className="row" id="landing">
        <div className="clearfix mt-5">
          <div className="m-2  col-md-6 float-md-end mb-3 ms-md-3">
            {currentProduct ? (
              <ProductImage currentProduct={currentProduct} />
            ) : ''}
          </div>
          <div className="m-4">
            <h2 className="m-4">{currentProduct?.title}</h2>
            <p className="m-4 text-justify">{currentProduct?.description}</p>
            <h2 className="m-4">USP of {currentProduct?.title}</h2>
            <ul className="m-4">
              {
                currentProduct?.usp?.map((item, i) => {
                  return <li key={i}>{item}</li>
                })
              }

              {currentProduct?.bisCertified ?
                <img src={BIS} height={'100px'} width={'200px'} className="mt-4" />

                : ''
              }
            </ul>
          </div>
        </div>
      </div>
      <div className="row m-4">
        <h2 className="my-4">Product Specification</h2>
        <div className="mx-auto">
          <table className="d-none d-md-block d-sm-none table table-striped table-hover text-center">
            <thead>
              <tr>
                {
                  currentProduct?.specification?.map((item, i) => {
                    return <th className="p-6 border border-slate-400"><b>{Object.keys(item)}</b></th>
                  })
                }
              </tr>
            </thead>
            <tbody>
              <tr>
                {
                  currentProduct?.specification?.map((item, i) => {
                    return <td className="p-6 border border-slate-400  text-center">{Object.values(item)}</td>
                  })
                }
              </tr>
            </tbody>
          </table>
          <table className="d-none d-md-none d-sm-block table table-striped table-hover text-center">
            <tbody>
              {
                currentProduct?.specification?.map((item, i) => {
                  return <tr>
                    <th className="">{Object.keys(item)}</th>
                    <td className="border-start">{Object.values(item)}</td>
                  </tr>
                })
              }
            </tbody>
          </table>
        </div>
      </div>
      <div className="row m-4">
        {currentProduct?.technology && <div className=''>
          <h2 className="">Technology & Functioning</h2>
          {
            currentProduct?.technology?.map((item, i) => {
              return <p key={i}>{item}</p>
            })
          }
        </div>
        }
      </div>
      {seriesProduct && seriesProduct.length > 0 ? (
        <div className="mt-4">
          <div className="row">
            <h5>Other product with same series :  {currentProduct.series}</h5>
          </div>
          <div className="row mt-4">
            {
              seriesProduct.map((item, i) => {
                return SeriesProduct({
                  i,
                  link: item.id,
                  title: item.title,
                  item
                })
              })
            }
          </div>
        </div>
      ) : ''}

    </div>
  )
}
