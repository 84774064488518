
import p1 from "./../../images/products/finecleaners/1.jpg";
import p2 from "./../../images/products/finecleaners/2.jpg";
import p3 from "./../../images/products/finecleaners/3.jpg";
import p4 from "./../../images/products/finecleaners/4.jpg";
import p5 from "./../../images/products/finecleaners/5.jpg";
import p6 from "./../../images/products/finecleaners/6.jpg";

import FINE from "./../../images/products/finecleaners/6.jpg";

import v1 from "./../../images/products/finecleaners/vcs/1.jpg";
import v2 from "./../../images/products/finecleaners/vcs/2.jpg";
import v3 from "./../../images/products/finecleaners/vcs/3.jpg";
import v4 from "./../../images/products/finecleaners/vcs/4.jpg";
import v5 from "./../../images/products/finecleaners/vcs/5.jpg";
import v6 from "./../../images/products/finecleaners/vcs/6.jpg";


const pcsImages = [
    { original: p1, thumbnail: p1 },
    { original: p2, thumbnail: p2 },
    { original: p3, thumbnail: p3 },
    { original: p4, thumbnail: p4 },
    { original: p5, thumbnail: p5 },
    { original: p6, thumbnail: p6 },
]

const vcsImages = [
    { original: v1, thumbnail: v1 },
    { original: v2, thumbnail: v2 },
    { original: v3, thumbnail: v3 },
    { original: v4, thumbnail: v4 },
    { original: v5, thumbnail: v5 },
    { original: v6, thumbnail: v6 }
]

const fineImages = [
    { original: FINE, thumbnail: FINE },
]

const finecleaners = [{
    id: 'fine-cleaner-pcs-1',
    title: 'FINE CLEANER - PCS - 1',
    series: 'FINE CLEANER - PCS',
    images: pcsImages,
    video: "https://www.youtube.com/embed/Rf8HkDra5ug",
    description: 'The role of Paddy Cleaner comes into play amidst pre-milling & post-parboiling. This machine incorporates wooden sieved decks to eliminate unwanted scum from the layers & ends up giving fine grain.',
    usp: ['Aspiration system for closed circuits', 'Tough design with a long-lasting structure', 'Low maintenance', 'Metal sieve and wooden sieve of high density', 'Fixed speed drives & motors'],
    specification: [{ 'Product Name': 'Fine Cleaner' }, { 'Model': 'PCS 1' }, { 'Capacity': '4-6 TPH' }, { 'Speed (RPM)': '150 RPM' }, { 'Power Consumption (HP) (Recommended)': '7.5/2' }, { 'Weight (Kg)': '1250' }, { 'Dimensions (L*W*H in mm)': '2960*1890*2415' }],
    technology: ['The technology used for PCS is Acentric movement of the sieves with an air aspiration system.', 'The grain is passed through a hopper into wooden deck equipped with special metal sieves, which clear outs oversized particles along with undersized particles with the use of aspiration system.', 'Aspiration systems are also present at the required places to suck the dust and other lighter impurities from the crop.'],
    category: 'fine-cleaner',
    categoryLink: '/fine-cleaner'

},
{
    id: 'fine-cleaner-pcs-2',
    title: 'FINE CLEANER - PCS - 2',
    series: 'FINE CLEANER - PCS',
    images: pcsImages,
    video: "https://www.youtube.com/embed/Rf8HkDra5ug",
    description: 'The role of Paddy Cleaner comes into play amidst pre-milling & post-parboiling. This machine incorporates wooden sieved decks to eliminate unwanted scum from the layers & ends up giving fine grain.',
    usp: ['Aspiration system for closed circuits', 'Tough design with a long-lasting structure', 'Low maintenance', 'Metal sieve and wooden sieve of high density', 'Fixed speed drives & motors'],
    specification: [{ 'Product Name': 'Fine Cleaner' }, { 'Model': 'PCS 2' }, { 'Capacity': '8-10 TPH' }, { 'Speed (RPM)': '150 RPM' }, { 'Power Consumption (HP) (Recommended)': '7.5/2' }, { 'Weight (Kg)': '1400' }, { 'Dimensions (L*W*H in mm)': '2960*1890*2415' }],
    technology: ['The technology used for PCS is Acentric movement of the sieves with an air aspiration system.', 'The grain is passed through a hopper into wooden deck equipped with special metal sieves, which clear outs oversized particles along with undersized particles with the use of aspiration system.', 'Aspiration systems are also present at the required places to suck the dust and other lighter impurities from the crop.'],
    category: 'fine-cleaner',
    categoryLink: '/fine-cleaner'

},
{
    id: 'fine-cleaner-vcs-10',
    title: 'VIBRO CLASSIFIER - VCS - 10',
    series: 'VIBRO CLASSIFIER - VCS',
    images: vcsImages,
    video: "https://www.youtube.com/embed/Rf8HkDra5ug",
    description: 'This machine is considered as the next available machine used for cleaning prior to the milling section. It is primarily designed to operate on continuous flow of vibration. It will help to segregate the paddy from fine foreign particles with the use of an aspiration system .',
    usp: [
        'Fully enclosed body for the dust-free operation.',
        'Low maintenance & high accuracy of the machine.',
        'Vibrating screen cleaning technology.',
        'Adjustable  inclination as per the grain size.',
        'Two Level cleaning and separation.',
        'Best for Dal, Legumes, Paddy, Beans, Seeds, etc.'
    ],
    specification: [{ 'Product Name': 'Vibro Classifier' }, { 'Model': 'VCS 10' }, { 'Capacity Rice (TPH) ': '6 - 8' }, { 'Power Consumption (HP)': '0.5 x 2 Nos.' }, { 'Weight (Kg)': '680' }, { 'Dimensions (L*W*H in mm)': '2955 x 1445 x 1520' }],
    technology: ['The technology used for Vibro Classifier is continuous vibration in one direction, which forces the grain to pass through the sieves and impurities to come out from the other end, also it is equipped with aspiration system, which clears the dust and other light impurities form the grain.'],
    category: 'fine-cleaner',
    categoryLink: '/fine-cleaner'

},
{
    id: 'fine-cleaner-vcs-12',
    title: 'VIBRO CLASSIFIER - VCS - 12',
    series: 'VIBRO CLASSIFIER - VCS',
    images: vcsImages,
    video: "https://www.youtube.com/embed/Rf8HkDra5ug",
    description: 'This machine is considered as the next available machine used for cleaning prior to the milling section. It is primarily designed to operate on continuous flow of vibration. It will help to segregate the paddy from fine foreign particles with the use of an aspiration system .',
    usp: [
        'Fully enclosed body for the dust-free operation.',
        'Low maintenance & high accuracy of the machine.',
        'Vibrating screen cleaning technology.',
        'Adjustable  inclination as per the grain size.',
        'Two Level cleaning and separation.',
        'Best for Dal, Legumes, Paddy, Beans, Seeds, etc.'
    ], specification: [{ 'Product Name': 'Vibro Classifier' }, { 'Model': 'VCS 12' }, { 'Capacity Rice (TPH) ': '10 - 12' }, { 'Power Consumption (HP)': '1 x 2 Nos.' }, { 'Weight (Kg)': '820' }, { 'Dimensions (L*W*H in mm)': '2955 x 1655 x 1520' }],
    technology: ['The technology used for Vibro Classifier is continuous vibration in one direction, which forces the grain to pass through the sieves and impurities to come out from the other end, also it is equipped with aspiration system, which clears the dust and other light impurities form the grain.'],
    category: 'fine-cleaner',
    categoryLink: '/fine-cleaner'

},
{
    id: 'fine-cleaner-vcs-15',
    title: 'VIBRO CLASSIFIER - VCS - 15',
    series: 'VIBRO CLASSIFIER - VCS',
    images: vcsImages,
    video: "https://www.youtube.com/embed/Rf8HkDra5ug",
    description: 'This machine is considered as the next available machine used for cleaning prior to the milling section. It is primarily designed to operate on continuous flow of vibration. It will help to segregate the paddy from fine foreign particles with the use of an aspiration system .',
    usp: [
        'Fully enclosed body for the dust-free operation.',
        'Low maintenance & high accuracy of the machine.',
        'Vibrating screen cleaning technology.',
        'Adjustable  inclination as per the grain size.',
        'Two Level cleaning and separation.',
        'Best for Dal, Legumes, Paddy, Beans, Seeds, etc.'
    ], specification: [{ 'Product Name': 'Vibro Classifier' }, { 'Model': 'VCS 15' }, { 'Capacity Rice (TPH) ': '12 - 15' }, { 'Power Consumption (HP)': '1 x 2 Nos.' }, { 'Weight (Kg)': '1020' }, { 'Dimensions (L*W*H in mm)': '2955 x 1955 x 1520' }],
    technology: ['The technology used for Vibro Classifier is continuous vibration in one direction, which forces the grain to pass through the sieves and impurities to come out from the other end, also it is equipped with aspiration system, which clears the dust and other light impurities form the grain.'],
    category: 'fine-cleaner',
    categoryLink: '/fine-cleaner'

},
{
    id: 'fine-cleaner-fine-12',
    title: 'FINE CLEANER - FINE - 12',
    series: 'FINE CLEANER - FINE',
    images: fineImages,
    video: "https://www.youtube.com/embed/Rf8HkDra5ug",
    description: 'Fine Cleaner is a technology-driven screen cleaning machine equipped with a wooden sieved deck along with an aspiration system to eliminate broken, immature & very fine particles of rice.',
    usp: [
        'Fine Cleaning of the grains with special grade imported screens.',
        'Low maintenance and high accuracy of machine.',
        'Special grade metal sieve and wooden laminated frames used.',
        'Machine has an integral fan, fixed speed drives and motors.',
        'Remove broken tip of rice/grain.'
    ],
    specification: [{ 'Product Name': 'Fine Cleaner' }, { 'Model': 'Fine-12' }, { 'Capacity': '4 - 6 TPH' }, { 'Power Consumption (HP) (Recommended)': '7.5/2 HP' }, { 'Weight (Kg)': '1300' }, { 'Dimensions (L*W*H in mm)': '2960*1520*2400' }],
    category: 'fine-cleaner',
    categoryLink: '/fine-cleaner'

},
{
    id: 'fine-cleaner-fine-15',
    title: 'FINE CLEANER - FINE - 15',
    series: 'FINE CLEANER - FINE',
    images: fineImages,
    video: "https://www.youtube.com/embed/Rf8HkDra5ug",
    description: 'Fine Cleaner is a technology-driven screen cleaning machine equipped with a wooden sieved deck along with an aspiration system to eliminate broken, immature & very fine particles of rice.',
    usp: [
        'Fine Cleaning of the grains with special grade imported screens.',
        'Low maintenance and high accuracy of machine.',
        'Special grade metal sieve and wooden laminated frames used.',
        'Machine has an integral fan, fixed speed drives and motors.',
        'Remove broken tip of rice/grain.'
    ], specification: [{ 'Product Name': 'Fine Cleaner' }, { 'Model': 'Fine-15' }, { 'Capacity': '8 - 10 TPH' }, { 'Power Consumption (HP) (Recommended)': '7.5/2  HP' }, { 'Weight (Kg)': '1400' }, { 'Dimensions (L*W*H in mm)': '2960*1520*2400' }],
    category: 'fine-cleaner',
    categoryLink: '/fine-cleaner'

}];

export default finecleaners;