import React, { useState } from 'react'

import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

export default function FeatureImage(props) {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className="mt-4">
            <div className="row text-center bg-info p-2 gradient">
                <h5>{props.head}</h5>
            </div>
            <div className="row">
                <img src={props.img} className="col-12 img rounded img-responsive" width={'90%'} onClick={handleShow}></img>
                <Modal show={show} onHide={handleClose} className="mol" size='xl' scrollable centered style={{ minWidth: '800px' }}>
                    <Modal.Body onClick={handleClose} className="my-2 p-2" style={{ width: '100%' }}>
                        <Container>
                            <Row>
                                <img src={props.img} className="col-12 img rounded img-responsive" style={{ scale: props.scale }} onClick={handleShow}></img>
                            </Row>
                        </Container>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}
