import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.scss';
import App from './App';
import store from './api';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";

ReactGA.initialize('G-8BMY7N9FY8');
ReactPixel.init('3146827302081849', {} , {
  autoConfig: true,
  debug: false,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
        <ToastContainer />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
);
