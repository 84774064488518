import React, { useLayoutEffect } from 'react';
import FINE from '../../images/products/finecleaners/1.jpg';
import SHEL from '../../images/products/paddyhusker/1.jpg';
import RPS20 from '../../images/products/paddyseparators/rps40/1.jpg';
import PRO from '../../images/PRO.webp';
import NEO from '../../images/NEO.webp';
import ST4 from '../../images/ST4.webp';
import SIZER from './../../images/products/sizer/1.jpg';
import LG from '../../images/products/lengthgrader/2.jpg';
import Qualified from '../../images/legend-qualified.png';
import India from '../../images/legend-india.png';
import Years from '../../images/legend-years.png';
import Three from '../../images/legend-three.png';
import Turn from '../../images/turn.png';
import PageHeader from '../global/pageheader';
import HuskFan from "./../../images/products/huskfan/1.jpg";
import ProductCard from "./../products/productCard";

export default function Grainmachinery() {

  return (
    <div className="container-fluid">
      <PageHeader img={Turn} head="Rice & Grain Machinery"></PageHeader>
      <div className="container">
        <div className="row text-center my-4">
          <h3>Sona Machinery Solutions</h3>
        </div>
        <div className="row text-justify">
          <div className="col-sm-12 col-md-5 mt-5">
            <h5 className="">One Stop Solution for Rice & Grain Machinery</h5>
            <p className="">Sona Machinery is the leader in providing innovative, technology-driven post-harvest processing machinery for various types of Grains and Seeds (like wheat, maize, millets, soyabean, poultry feeds, seeds, etc.). The top-of-line processing machines are manufactured in our well-equipped, world-class production facility and are an excellent option for processing grains and seeds. The solutions are efficient in cleaning, sorting, handling, and grading of grains and seeds.</p>
            <ul>
              <li>Market Leader in Grain Processing Solutions</li>
              <li>Exquisite Range of Products Solutions</li>
              <li>Trusted for Quality</li>
              <li>Prompt Service Support</li>
              <li >Easily Available Original Spares</li>
            </ul>
          </div>

          <div className="col-sm-12 col-md-3 mx-auto">
            <div className="row">
              <div className="card my-2 shadow">
                <div className="card-image text-center my-2"><img src={Qualified} className="img-fluid" style={{ maxHeight: '120px' }}></img></div>
                <h5>Team of Highly Qualified Engineers</h5>
                <p className="">For Production of High-quality modern machines, consultancy, material handling setup, fabrication, installation & Training. </p>
              </div>
              <div className="card my-2 shadow">
                <div className="card-image text-center my-2"><img src={Years} className="img-fluid" style={{ maxHeight: '120px' }}></img></div>
                <h5>27 years of Manufacturing Experience</h5>
                <p className="">Expertise in Engineering, Erection, Supervision, and Commissioning<br /><br /></p>
              </div>
            </div>
          </div><div className="col-sm-12 col-md-3 mx-auto">
            <div className="row">
              <div className="card my-2 shadow">
                <div className="card-image text-center my-2"><img src={India} className="img-fluid" style={{ maxHeight: '120px' }}></img></div>
                <h5>Pan India leader in the Agro-processing Industry</h5>
                <p className="">We have solutions for aromatic basmati rice and non-basmati medium & short grains <br /><br /><br /> </p>
              </div>
              <div className="card my-2 shadow">
                <div className="card-image text-center my-2"><img src={Three} className="img-fluid" style={{ maxHeight: '120px' }}></img></div>
                <h5>Sona Machinery range from 3 to 10 TPH</h5>
                <p className="">Cost-effective fixed price and tailored timeline to customers for a hassle-free experience <br /><br /></p>
              </div>
            </div>
          </div>
        </div>
        <div className="row text-center" >
          <h2>Our Products</h2>
          <div className="row mx-auto" >
            <ProductCard img={FINE} tgt="/fine-cleaner-fine-15" desc="Fine Cleaner"></ProductCard>
            <ProductCard img={SHEL} tgt="/paddy-husker-shel-104" desc="Paddy Husker"></ProductCard>
            <ProductCard img={HuskFan} tgt="/husk_fan" desc="Husk Fan"></ProductCard>
            <ProductCard img={RPS20} tgt="/fine-cleaner-fine-12" desc="Fine Cleaner"></ProductCard>
            <ProductCard img={SHEL} tgt="/paddy-separator-rps-20" desc="Paddy Separator"></ProductCard>
            <ProductCard img={PRO} tgt="/rice-whitener-pro-5S" desc="Rice Whitener Pro"></ProductCard>
            <ProductCard img={NEO} tgt="/rice-whitener-neo-7" desc="Rice Whitener Neo"></ProductCard>
            <ProductCard img={ST4} tgt="/silky-polisher-st-4" desc="Silky Polisher"></ProductCard>
            <ProductCard img={SIZER} tgt="/sizer-612" desc="Sizer"></ProductCard>
            <ProductCard img={LG} tgt="/length-grader-lg-03" desc="Length Grader"></ProductCard>
          </div>
        </div>
      </div>
    </div>
  )
}