import React from 'react'
import { NavLink } from 'react-router-dom'

export default function JobApply() {
  return (
    <div className="row mt-5 bg-success bg-gradient shadow mb-5 " style={{ height: '150px' }} >
      <div className="col text-center my-auto">
        <div className="row text-white">
          <h4>Didn't find relevant job  ?</h4>
        </div>
        <div className="row text-black d-none d-md-block">
          <h5>Upload Your Resume & we will get back to you <br /> as soon as we have a relevant opening.</h5>
        </div>
      </div>
      <div className="col mx-auto my-auto">
        <NavLink to='/form-one'>
          <button className="btn btn-info btn-lg w-20">Drop Your CV</button>
        </NavLink>
      </div>
    </div>
  )
}
