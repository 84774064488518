import React, { useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllJob, jobSelector } from '../../api/jobs'
import { Link } from 'react-router-dom'
import CareerHeader from "./../../images/pages/pageheaders/banner_careers.jpg";
import CareerHeaderMobile from "./../../images/pages/pageheaders/banner_careers_m.jpg";

import PageHeader from '../global/pageheader'

function JobCard(props) {

  return (
    <div className="col-md-4 col-sm-6 mt-4">
      <div className="card m-2 shadow">
        <div className="card-header fw-bold fs-6">{props.title}</div>
        <div className="card-body">
          <p className="fs-6">We are looking for motivated and passionate people who are willing to share the same motive as us.</p>
          <div className="row">
            <a href={'/jobs/' + props._id} className="btn btn-primary mt-4 mb-2 w-50 mx-auto">View Job</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function JobPostings() {
  const { all_jobs } = useSelector(jobSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchAllJob())
  }, [dispatch])

  const jobContent = all_jobs.map((j) => JobCard(j));

  return (
    <div className="container-fluid">
      <PageHeader img={CareerHeader} img_mobile={CareerHeaderMobile}  head="Grow With us"></PageHeader>
      <div className="row text-center"><h4>We're Hiring</h4></div>
      <div className="row text-center"><h6>We once dreamt of becoming self-reliant in agro-machinery manufacturing and now we are the market leader in India. Be part of our Team !!</h6></div>
      <div className="row">
        {jobContent}
      </div>
    </div>
  )
}
