import React from 'react'

import BannerInvestors from "./../../images/pages/pageheaders/banner_investors.jpg";
import BannerInvestorsMobile from "./../../images/pages/pageheaders/banner_investors_m.jpg";
import InvestorsImg from '../../images/investors/investors.jpg';
import PageHeader from "../global/pageheader";

export default function InvestorContact() {

    return (
        <div className="container-fluid">
            <PageHeader img={BannerInvestors} img_mobile={BannerInvestorsMobile} head="Investor Contact"></PageHeader>
            <div className="card mt-2 mb-2 shadow" >
                <div className="card-header">
                    For Investor Related matters Contact Us
                </div>
                <div className="card-body">
                    <div className="mt-2 mb-2" >
                        <div className="row">
                            <div className="col fw-bold">Reg Office :</div>
                            <div className="col">
                                <div className="row">
                                    701 7th floor KLJ tower Plot No. B-5,
                                </div>
                                <div className="row">
                                    Netaji Subhash Place, Maurya Enclave,
                                </div><div className="row">
                                    North West Delhi, Delhi,
                                </div><div className="row">
                                    Delhi, India, 110034
                                </div>
                            </div>
                        </div>
                        
                        <div className="row my-5">
                            <div className="col fw-bold">Phone :</div>
                            <div className="col">
                                <div className="row">
                                    +91-84489-84368
                                </div>
                            </div>
                        </div>
                        <div className="row my-5">
                            <div className="col fw-bold">Email :</div>
                            <div className="col">
                                <div className="row">
                                    cs@sonamachinery.com
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}