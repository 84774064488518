import React from 'react';

import Qualified from '../../images/legend-qualified.png';
import India from '../../images/legend-india.png';
import Years from '../../images/legend-years.png';
import Three from '../../images/legend-three.png';
import Design1 from '../../images/design1.png';
import PageHeader from "../global/pageheader";
import FeatureImage from './featureImageRow';
import EthanolProcess from "./../../images/ethanol-process.jpg"
import RiceMill from "./../../images/rice-mill-plot.jpg"
import RiceProcess from "./../../images/rice-process.jpg"
import TrustedByBrandsList from '../global/trustedbybrandsList';

export default function Design() {

  return (
    <div className='container-fluid'>
      <PageHeader img={Design1} head="Engineering, Procurement & Construction">
      </PageHeader>
      <div className="container">
        <div className="row text-center my-4">
          <h3>Design & Consultation</h3>
        </div>
        <div className="row text-justify">
          <div className="col-sm-12 col-md-5 mt-5">
            <h5 className="">One Stop Solution for Design & Consultation</h5>
            <p className="my-3">Sona Machinery provides an exquisite range of turnkey solutions for modern rice processing mills. Our turnkey solution projects range from 3TPH to 10 TPH for automated mills and support clients in engineering, erection, supervision, and commissioning. </p>
            <ul className="mx-5">
              <li className="">
                Sona Machinery expert in Grain Milling Plant Setup.
              </li>
              <li >Pan India leader in the agro-processing industry
              </li>
              <li>
                Other types of drawings, and bank loan assistance.
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-3">
            <div className="card m-2 p-2 shadow">
              <div className="card-image text-center"><img src={Qualified} className="img-fluid rounded" alt="" style={{ maxHeight: '150px', maxWidth: '150px' }} /></div>
              <div>
                <h5>Team of Highly qualified engineers</h5>
                <p>Sona Machinery provides a complete end-to-end solution for the milling section, from the unloading of the grain to pre-mash. <br /><br /><br /><br /></p>
              </div>
            </div>
            <div className="card m-2 p-2 shadow">
              <div className="card-image text-center"><img src={Years} className="img-fluid rounded" alt="" style={{ maxHeight: '150px', maxWidth: '150px' }} /></div>
              <div>
                <h5>27 years of manufacturing experience</h5>
                <p>Apart from Grain-Based Ethanol Distillery Manufacturing & Solution, we also manufacture almost all the machinery and equipment required</p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-3 ">
            <div className="card m-2 p-2 shadow">
              <div className="card-image text-center"><img src={India} className="img-fluid rounded" alt="" style={{ maxHeight: '150px', maxWidth: '150px' }} /></div>
              <div>
                <h5>Pan India leader in the agro-processing industry</h5>
                <p>like Grain Cleaning Machines, Destoning Machines, Material Handling Equipment, and Pre Fabricated Flour Silos.<br /></p>
              </div>
            </div>
            <div className="card m-2 p-2 shadow">
              <div className="card-image text-center"><img src={Three} className="img-fluid rounded" alt="" style={{ maxHeight: '150px', maxWidth: '150px' }} /></div>
              <div>
                <h5>Solutions from 60 KLPD to 500 KLPD Grain Miling</h5>
                <p>Cost-effective fixed price and tailored timeline to customers for a hassle-free experience</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <FeatureImage head="Ethanol Process Flow Diagram" img={EthanolProcess} scale={'2'} className="mt-2 mb-2"></FeatureImage>
          <FeatureImage head="Rice Process Flow Diagram" img={RiceProcess} scale={'2'} className="mt-2 mb-2"></FeatureImage>
          <FeatureImage head="Rice Mill Plot Diagram" img={RiceMill} scale={'2'} className="mt-2 mb-2"></FeatureImage>
        </div>

        <div>
          <TrustedByBrandsList photos='3'></TrustedByBrandsList>
        </div>
      </div >
    </div>
  )
}
