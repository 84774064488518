import React from 'react';

import LOGO_DISTILLERY from "./../../images/pages/homepage/solutions/solndistillery.jpg";
import LOGO_SPARES from "./../../images/pages/homepage/solutions/solnspares.jpg";
import LOGO_ENGINEERING from "./../../images/pages/homepage/solutions/solnengineering.jpg";
import LOGO_GRAIN from "./../../images/pages/homepage/solutions/solngrain.jpg";
import LOGO_TURNKEY from "./../../images/pages/homepage/solutions/solnturnkey.jpg";

import ICON_DISTILLERY from "./../../images/pages/homepage/solutions/icondistillery.png";
import ICON_SPARES from "./../../images/pages/homepage/solutions/iconspares.png";
import ICON_ENGINEERING from "./../../images/pages/homepage/solutions/iconengineering.png";
import ICON_GRAIN from "./../../images/pages/homepage/solutions/icongrain.png";
import ICON_TURNKEY from "./../../images/pages/homepage/solutions/iconturnkey.png";

import BANNER_ENG from "./../../images/pages/homepage/solbanner/engineering.png";
import BANNER_GRAIN from "./../../images/pages/homepage/solbanner/grain-based.png";
import BANNER_SEED from "./../../images/pages/homepage/solbanner/grain-seed.png";
import BANNER_SPARE from "./../../images/pages/homepage/solbanner/service-spare.png";
import BANNER_TURNKEY from "./../../images/pages/homepage/solbanner/turnkey.png";

import Accordion from 'react-bootstrap/Accordion';

export function SolutionCard(props) {

  let topStyle = {};
  let boxStyle = {
    marginLeft: '4px',
    marginRight: '4px'
  }

  return (
    <div className="card border-0 m-1" style={topStyle}>
      <div className='ms-3 mt-3 px-1' style={{ minHeight: '60px', maxHeight: '60px' }}>
        <img src={props.icon} className='img-responsive' style={{ minHeight: '60px', minWidth: '65px', maxHeight: '60px', maxWidth: '6px' }}></img>
      </div>
      <div className='mx-1 px-
       mt-1 content-align-middle' style={{ minHeight: '130px', maxHeight: '130px' }}>
        <div className="" style={{ minHeight: '40px', maxHeight: '40px',  ...boxStyle}} >
          <span className="fw-bold">{props.title}</span>
        </div>
        <div className="text-justify px-" style={boxStyle}><span className="mt-1">{props.desc}</span></div>
      </div>
      <div className="mx-1 mb-3 fw-bold px- mt-2"><a href={props.rml} className="link-dark link-underline link-underline-opacity-50 " style={boxStyle}>Read More..</a></div>
      <div className="text-center mb-1 p-">
        <img src={props.img} className="rounded2 img-responsive" width={'100%'}></img>
      </div>
    </div>
  )
}

export function SolutionBanner(props) {
  return (
    <div className="card shadow">
      <div className="card-body text-center">
        <a href={props.rml} className="link-dark link-underline link-underline-opacity-50 ">
          <img src={props.banner} className="img-responsisve rounded" style={{maxHeight: '540px', maxWidth: '100%'}}></img>
        </a>
      </div>
    </div>
  )
}

export default function IndexSolutions() {
  const vals = [
    { title: 'Turnkey Rice Mill  Solution', banner: BANNER_TURNKEY, icon: ICON_TURNKEY, img: LOGO_TURNKEY, desc: 'Innovative Technology Complete Project Rice Mill Solutions for Modern Millers. Sona Machinery has been ...', rml: '/turnkey-rice-mill-solutions' },
    { title: 'Grain Based Distillery', banner: BANNER_GRAIN, icon: ICON_DISTILLERY, img: LOGO_DISTILLERY, desc: 'Turnkey Project Solution for Grain Based Distillery - in Grain Unloading & Milling Section. We offer EPC ...', rml: '/epc-solutions-for-grain-unloading-and-milling' },
    { title: 'Grain & Seed Processing', banner: BANNER_SEED, icon: ICON_GRAIN, img: LOGO_GRAIN, desc: 'Post-Harvest Processing Machinery for Cleaning, Destoning, Grading & Material Handling. The top ... ', rml: '/grain-machinery' },
    { title: 'Engineering & Construction', banner: BANNER_ENG, icon: ICON_ENGINEERING, img: LOGO_ENGINEERING, desc: 'Customised Drawings, Designs and Consultation to fulfill your overall project requirements. Our turnkey ...', rml: '/engineering-and-designing-consultation' },
    { title: 'Spares & Service Support', banner: BANNER_SPARE, icon: ICON_SPARES, img: LOGO_SPARES, desc: 'Original, High Quality Spare Parts, easily available with timely delivery of requirements. The constant ...', rml: '/spares-and-services' }
  ];

  const mainCards = [];
  const accordions = [];
  vals.forEach((val, idx) => {
    mainCards.push(
      <SolutionCard pos={idx} title={val.title} img={val.img} icon={val.icon} desc={val.desc} rml={val.rml}></SolutionCard>
    )

    //mainCards.push(
    //  <SolutionBanner pos={idx} title={val.title} banner={val.banner} img={val.img} icon={val.icon} desc={val.desc} rml={val.rml}></SolutionBanner>
    //)

    accordions.push(
      <Accordion.Item eventKey={idx} alwaysOpen>
        <Accordion.Header> {val.title}</Accordion.Header>
        <Accordion.Body>
          <div className="row" key={idx}>
            <img src={val.img} key={idx} className="col rounded mx-auto img-responsive"></img>
          </div>
          <div className="row mt-4" key={idx}><span className="text-justify">{val.desc}</span></div>
          <div className="row w-50 align-middle text-center mx-auto mb-3" key={idx}><a href={val.rml} className="btn btn-sm btn-primary">Read More..</a></div>
        </Accordion.Body>
      </Accordion.Item>
    );

  });

  return (
    <div className="container section">
      <div className="row text-center m-2 mb-4">
        <h2 className="page-section-header">Our Solutions</h2>
      </div>
      <div className=" mx-1 bg-light">
        <div className="justify-content-center card-group my-2">
          {mainCards}
        </div>
      </div>
    </div>
  )
}