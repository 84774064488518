
import PHI0053 from "./../../images/products/paddyhusker/1.jpg";
import PHI0102 from "./../../images/products/paddyhusker/2.jpg";
import PHI0106 from "./../../images/products/paddyhusker/3.jpg";
import PHI0112 from "./../../images/products/paddyhusker/4.jpg";
import PHI0118 from "./../../images/products/paddyhusker/5.jpg";
import PHI0135 from "./../../images/products/paddyhusker/6.jpg";

const huskerImages = [
    { original: PHI0053, thumbnail: PHI0053 },
    { original: PHI0102, thumbnail: PHI0102 },
    { original: PHI0106, thumbnail: PHI0106 },
    { original: PHI0112, thumbnail: PHI0112 },
    { original: PHI0118, thumbnail: PHI0118 },
    { original: PHI0135, thumbnail: PHI0118 },
]

const paddyhuskers = [
    {
        id: 'paddy-husker-shel-104',
        title: 'Paddy Husker Shel-104',
        series: 'PADDY HUSKER SHEL',
        images: huskerImages,
        video: "https://www.youtube.com/embed/Rf8HkDra5ug",
        description: 'Paddy De-Huskers are used to break open the paddy to obtain rice. Huskers are installed with 2 Rubber coated  Rollers, which move together in the opposite direction, but with a speed difference. The paddy is fed using vibro motors in a straight line in between these rubber roils. These rubber rolls Crunch the paddy, which opens the paddy from the ends, separating husk and grain. There is an aspiration system installed, which clears out the husk and dust.',
        usp: ['Very High degree of shelling',
            'Less broken by using pneumatic controls.',
            'Apart from De-hull Paddy, this machine can also be used for Oats, Barley, Sunflower Seeds, etc.'],
        specification: [{ 'Product Name': 'Paddy Husker' }, { 'Model': 'SHEL-104' }, { 'Capacity': '4 - 5 TPH' }, { 'Power Consumption (HP) (Recommended)': '12.5 HP' }, { 'Weight (Kg)': '590' }, { 'Dimensions (L*W*H in mm)': '1353*915*1386' }],
        technology: ['Huskers function on the technology of breaking open the shells for the paddy by pressing it in between two rubber rolls (to better understand it we can take the example of Peanut. We press open the peanut shell by pressing it with our fingers to obtain the nut out of it). The paddy is fed using a vibro motor in the straight line into the rubber rolls, which crunch the paddy and open it from both the ends. There is an aspiration system installed in the machine, which sucks the light weighted husk and dust from the crop.'],
        category: 'Paddy Husker',
        categoryLink: '/paddy-husker'
    },
    {
        id: 'paddy-husker-shel-125',
        title: 'Paddy Husker Shel-125',
        series: 'PADDY HUSKER SHEL',
        images: huskerImages,
        video: "https://www.youtube.com/embed/Rf8HkDra5ug",
        description: 'Paddy De-Huskers are used to break open the paddy to obtain rice. Huskers are installed with 2 Rubber coated  Rollers, which move together in the opposite direction, but with a speed difference. The paddy is fed using vibro motors in a straight line in between these rubber roils. These rubber rolls Crunch the paddy, which opens the paddy from the ends, separating husk and grain. There is an aspiration system installed, which clears out the husk and dust',
        usp: [
            'Very High degree of shelling',
            'Less broken by using pneumatic controls',
            'Apart from De-hull Paddy, this machine can also be used for Oats, Barley, Sunflower Seeds, etc.'
        ],
        specification: [{ 'Product Name': 'Paddy Husker' }, { 'Model': 'SHEL-125' }, { 'Capacity': '5 - 6 TPH' }, { 'Power Consumption (HP) (Recommended)': '15 HP' }, { 'Weight (Kg)': '750' }, { 'Dimensions (L*W*H in mm)': '1353*940*1414' }],
        technology: ['Huskers function on the technology of breaking open the shells for the paddy by pressing it in between two rubber rolls (to better understand it we can take the example of Peanut. We press open the peanut shell by pressing it with our fingers to obtain the nut out of it). The paddy is fed using a vibro motor in the straight line into the rubber rolls, which crunch the paddy and open it from both the ends. There is an aspiration system installed in the machine, which sucks the light weighted husk and dust from the crop.'],
        category: 'Paddy Husker',
        categoryLink: '/paddy-husker'
    },

]

export default paddyhuskers; 