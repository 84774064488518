import React, { useLayoutEffect } from 'react'

export default function AboutText() {
  return (
    <div className="container text-justify">
      <div className="row mt-5 mb-3 text-justify">
        <h3>About Sona Machinery</h3>
      </div>
      <div className="row text-justify mt-2 mb-5">
        <div className="col m-4">
          <p>
            <span className="fw-bold">Sona Machinery Limited</span> has been at the helm of the industry since initial stages of mechanization in
            Agriculture supporting it with its smart technology solutions with a mission is to develop &amp;
            deliver world-class cutting-edge technology products to our customers.
          </p>

          <p>
            Today, under the leadership of <span className="fw-bold">Mr Vasu Naren,</span> as its Chairman &amp; Managing Director,
            Sona Machinery Ltd. is an ISO 9001:2015 Certified Original Equipment Manufacturer having a sprawling 10000 sq. mts. 
            facility with over 350+ skilled professionals delivering customized solutions to more than 10000+ satisfied customers 
            in India &amp; Globally.Its product list includes (but is not limited to) a complete range of cutting-edge technology 
            equipments for Complete Rice Mill Project Set-ups as well as manufacturing equipments for cleaning, processing various 
            Grains like Pulses, Wheat, Sesame, Millet, Corn, Seeds, etc. in desired capacities. In terms of support, we have a Pan India Sales, 
            Service & Support Network for our customers. We also cater to our customers who are engaged in Production of Ethanol (Grain Based Distilleries), 
            by providing them Complete Project Set-ups in Unloading & Milling Sections. Sona Machinery is among the “Top 10 Industrial Service Providers” of 
            2022 as per Industry Outlook and is Awarded as “Prestigious Brands Asia 2023” in Agri-Machinery Manufacturing category by Barc Asia & 
            Global Business Symposium.
          </p>
        </div>
      </div>
    </div>
  )
}