import React from 'react'
import JobPostings from './jobpostings'
import JobApply from './jobapply'

export default function index() {
  return (
    <div>
      <JobPostings />
      <JobApply />
    </div>
  )
}
