import React from 'react'
import Swetha from '../../images/shweta.JPG'

export default function swetha() {
  return (
    <div>
      <div className=' object-cover bg-cover w-full flex justify-center items-center'>
  <h2 className="text-4xl lg:text-5xl text-white font-semibold hidden md:block">Management Team</h2>
  <h2 className="text-4xl lg:text-5xl text-white font-semibold block md:hidden text-center"> Management <br/>Team</h2>

  </div>
    <section className=" bg-white overflow-hidden lg:py-12 lg:px-32 px-6 " style={{fontFamily:'poppins'}}>
  <div className=" container px-6 mx-auto">
    <div className="flex lg:items-center -m-8 lg:-m-14" id="manage-info" >
      <div className="w-full md:w-1/2 p-8 md:mb-80 mb- lg:p-14">
        <div className="relative max-w-max mx-auto lg:ml-auto lg:mr-0 overflow-hidden rounded-4xl">
          <img className="transform hover:scale-105 transition ease-in-out duration-1000" src={Swetha} alt=""/>
          <h2 className="mt-4 mb-4 text-4xl md:text-4xl xl:text-10xl font-bold font-heading tracking-px-n leading-none text-teal-900 text-center">Shweta Baisla</h2>
          <p className="mb-6 font-sans text-sm text-teal-800 font-semibold uppercase tracking-px text-center">&#x1F44B; CF0 & COO </p>

          <div className="absolute bottom-0 left-0 w-full px-16 pb-10">
            <div className="overflow-hidden rounded-lg" style={{backdropFilter: 'blur(8px);'}}>
              <div className="flex flex-wrap sm:divide-x divide-y sm:divide-y-0 divide-gray-300">
               
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-full p-8 lg:p-10">
        <div className="">
          <p className="mb-5 text-lg text-teal-900 font-bold">"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ex libero, posuere quis fermentum et, molestie dignissim metus."</p>
          <p className="mb-5 text-teal-900  text-lg font-medium"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ex libero, posuere quis fermentum et, molestie dignissim metus. Vivamus lorem sem, accumsan et tempus in, tempor non quam. In ac leo orci. Nam feugiat at turpis sit amet eleifend. Ut scelerisque nisi et libero aliquam sollicitudin. Curabitur nec lacinia sem. Nulla quis porttitor massa. Vivamus quis euismod odio. Aenean pretium purus ac dui auctor pellentesque ultricies sed nibh. Aliquam elementum luctus est at gravida. Pellentesque interdum justo eget quam dapibus aliquet.</p>
          <p className="mb-5 text-teal-900  text-lg font-medium">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ex libero, posuere quis fermentum et, molestie dignissim metus. Vivamus lorem sem, accumsan et tempus in, tempor non quam. In ac leo orci. Nam feugiat at turpis sit amet eleifend. Ut scelerisque nisi et libero aliquam sollicitudin. Curabitur nec lacinia sem. Nulla quis porttitor massa. Vivamus quis euismod odio. Aenean pretium purus ac dui auctor pellentesque ultricies sed nibh. Aliquam elementum luctus est at gravida. Pellentesque interdum justo eget quam dapibus aliquet. </p>
          <p className="mb-5 text-teal-900  text-lg font-medium">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ex libero, posuere quis fermentum et, molestie dignissim metus. Vivamus lorem sem, accumsan et tempus in, tempor non quam. In ac leo orci. Nam feugiat at turpis sit amet eleifend. Ut scelerisque nisi et libero aliquam sollicitudin. Curabitur nec lacinia sem. Nulla quis porttitor massa. Vivamus quis euismod odio. Aenean pretium purus ac dui auctor pellentesque ultricies sed nibh. Aliquam elementum luctus est at gravida. Pellentesque interdum justo eget quam dapibus aliquet.</p>
          <p className="text-teal-900  text-lg font-medium"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ex libero, posuere quis fermentum et, molestie dignissim metus. Vivamus lorem sem, accumsan et tempus in, tempor non quam. In ac leo orci. Nam feugiat at turpis sit amet eleifend. Ut scelerisque nisi et libero aliquam sollicitudin. Curabitur nec lacinia sem. Nulla quis porttitor massa. Vivamus quis euismod odio. Aenean pretium purus ac dui auctor pellentesque ultricies sed nibh. Aliquam elementum luctus est at gravida. Pellentesque interdum justo eget quam dapibus aliquet.</p>
        </div>
      </div>
    </div>
  </div>
</section>
  
      </div>
  )
}
