import Logo from '../../images/brands/sentini.png';
import Logo1 from '../../images/brands/krbl.png';
import Logo2 from '../../images/brands/patan.png';
import Logo3 from '../../images/brands/rb.png';
import Logo4 from '../../images/brands/ruchi.png';
import Logo5 from '../../images/brands/teri.png';
import Logo6 from '../../images/brands/balaji.png';
import Logo7 from '../../images/brands/dhanuka.png';
import Logo8 from '../../images/brands/bcml.png';
import Logo10 from '../../images/brands/gulshan.png';
import Loadable from '@loadable/component';
//import 'react-multi-carousel/lib/styles.css';

function LogoItem(props) {

  return (
    <div className="align-middle mx-2 d-flex" style={{minHeight: '60px', maxHeight: '60px'}}>
      <img src={props.logo} alt="brand logo" className="img-fluid align-self-middle" height={'60px'} width={'60px'} />
    </div>
  )
}

export default function TrustedByBrandsList(props) {
  const OwlCarousel = Loadable(() => import('react-owl-carousel3'));
  const options = {
    loop: true,
    nav: false,
    dots: false,
    autoplay: true,
    merge: true,
    smartSpeed: 1000,
    autoplayTimeout: 5000,
    responsive: {
      0: {
        items: 2,
      },
      768: {
        items: 3,
      },
      1200: {
        items: 3,
      }
    }
  }

  if (props.photos) {
    options.responsive['768'].items = (props.photos * 1) + 1;
    options.responsive['1200'].items = (props.photos * 1) + 3;
  }

  const items = [];

  [Logo, Logo1, Logo2, Logo3, Logo4, Logo10, Logo5, Logo6, Logo7, Logo8].forEach((itm, idx) => {
    items.push(<LogoItem logo={itm}></LogoItem>)
  })

  return (
    <div className="my-1">
      <div className="row mx-auto text-center page-section-header">
        <h2>Trusted by Brands</h2>
      </div>
      <div className="mt-2">
        <OwlCarousel
          className="mt-4"
          {...options}
        >
          {items}
        </OwlCarousel>
      </div>
    </div>
  )
}