import React from 'react'
import loadable from '@loadable/component';

const OwlCarousel = loadable(() => import('react-owl-carousel3'));

const testimonialData = [
  {
    val: 'We have installed a 8TPH rice mill plant from Sona Machinery and are fully satisfied with the rice mill plant supplied and commissioned with the quality output of production. In future if we plan any other plant or wish to scale-up the current operations, then we would definitely go with Sona Machinery only.',
    cust: 'Vipul Agrawal, Managing Director, Vasudev Foods, Uttarakhand'
  },
  {
    val: 'I thank Sona Machinery Team for making our plant operations successful and helping us meet the capacity output desired in Grain Handling System – Unloading & Milling Sections. We look forward to working with Sona Machinery in our future projects as we now know whom to entrust this responsibility to.',
    cust: 'B. Srinivas, Chief Procurement Officer, Balrampur Chini Mills'
  },
  {
    val: 'While we were planning to set-up our rice mill plant, we looked at different manufacturers but the machines and quality from Sona Machinery were far better. I am satisfied with the rice mill set-up and the Rice Quality obtained is No. 1 with a broken percentage which is very less as compared to others.',
    cust: 'Naveen, Sri Manikantha Modern Rice Mill, Telangana'
  },
  {
    val: 'Sona Machinery have successfully completed the erection and commissioning of our Grain Handling System - Unloading Section on full load and have provided best services as desired. We look forward to work with Sona Machinery for our future ',
    cust: 'Om Sons Marketing Pvt Ltd'
  }
]
const TestimonialItem = (props) => {
  return (
    <div className='item'>
      <div className="my-2 fs-5">
        "{props.item.val}"
      </div>
      <div className="ms-3 my-5 fw-bold fs-5">
        - {props.item.cust}
      </div>
    </div>
  );
}

const testimonials = () => {
  const options = {
    loop: true,
    dots: false,
    nav: true,
    navText: [
      '<i class="bi bi-chevron-double-left d-none d-md-block"></i>',
      '<i class="bi bi-chevron-double-right d-none d-md-block"></i>'
    ],
    autoplay: true,
    merge: true,
    smartSpeed: 2000,
    autoplayTimeout: 5000,
    margin: 10,
    responsive: {
      0: {
        items: 1,
      }
    }
  };
  const optionsMob = {
    loop: true,
    dots: false,
    nav: false,
    autoplay: true,
    merge: true,
    smartSpeed: 2000,
    autoplayTimeout: 5000,
    margin: 10,
    responsive: {
      0: {
        items: 1,
      }
    }
  }

  let items = testimonialData.map(d => {
    return (
      <TestimonialItem item={d} />
    );
  })

  return (
    <div className="container-fluid my-1 bg-primary text-white gradient rounded" style={{ minHeight: '100px' }}>
      <div className="row text-center my-4 page-section-header">
        <h2 className="">Customer Testimonials</h2>
      </div>
      <div className="row align-items-center text-center">
        <div className="d-md-none d-sm-block">
          <div className="col-md-8 offset-md-2 col-12">
            <OwlCarousel
              className="my-2 owl-carousel  owl-theme"
              {...optionsMob}
            >
              {items}
            </OwlCarousel>
          </div>
        </div>
        <div className="d-none d-md-block">
          <div className="col-md-8 offset-md-2 col-12">
            <OwlCarousel
              className="my-2 owl-carousel  owl-theme"
              {...options}
            >
              {items}
            </OwlCarousel>
          </div>
        </div>
      </div>

    </div>

  )
}

export default testimonials;