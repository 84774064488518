import React from 'react'

import PCS from '../../images/products/finecleaners/pcs/1.jpg'
import HC from '../../images/products/precleaners/drc/1.jpg'
import VCS from '../../images/products/finecleaners/vcs/1.jpg'
import FINE from '../../images/products/finecleaners/1.jpg'
import SHEL from '../../images/products/paddyhusker/1.jpg'
import RPS20 from '../../images/products/paddyseparators/rps40/1.jpg'
import PRO from '../../images/products/ricewhitener/pro/1.jpg';
import NEO from '../../images/products/ricewhitener/neo/1.jpg';
import ST4 from '../../images/products/silkypolisher/st6/1.jpg';
import Product from '../../images/product.png'
import LG from '../../images/products/lengthgrader/1.jpg';
import Fact from '../../images/fact.jpg';
import Turnn from '../../images/turnn.png';
import Qualified from '../../images/legend-qualified.png';
import India from '../../images/legend-india.png';
import Years from '../../images/legend-years.png';
import Three from '../../images/legend-three.png';
import Standard from '../../images/standard.png';
import PageHeader from "./../global/pageheader";
import ProductCard from "./../products/productCard";
import TrustedByBrandsList from '../global/trustedbybrandsList';
import RiceMillPlant from "./../../images/rice-mill-plot.jpg";
import FeatureImage from './featureImageRow'

import SOUTH_VIDEO from "./../../images/pages/solutionspage/turnkeysolution/southtestimonialvideo.mp4";

export default function Turnkeysolution() {
  const [display, setDisplay] = React.useState(false);

  return (
    <div className="container-fluid mx-auto">
      <PageHeader img={Turnn} head="Turnkey Rice Mill Solutions"></PageHeader>
      <div className="container">
        <div className="row mt-4">
          <div className="col-sm-12 col-md-4">
            <h5>Sona Machinery Solutions</h5>
            <p className="text-justify word-wrap ">Sona Machinery has relentlessly been developing technologically advanced rice milling machines which have been created specifically for various types of rice extensively cultivated in India as well as across the world. We have an expertise and offer our machinery for processing of various types of Indian rice like aromatic basmati rice, non-basmati medium and short grains.
              We are proud to be in the exclusive club of OEM’s who provide an exquisite range of complete Turnkey Solutions for modern rice processing mills. Our turnkey solution projects range from 3TPH to 10 TPH for automated mills and support clients in engineering, erection, supervision, and commissioning.
              The Milling Plant machinery is also customized as per the customer’s requirements based on rice’s variety, standard, and quality.</p>
            <ul className="">
              <li className="">
                Trusted for Quality
              </li>
              <li className="">
                10000+ Customers across the Globe
              </li>
              <li className="">
                ISO 9001:2015 Certified
              </li>
              <li className="">
                Prompt Service Support
              </li>
              <li className="">
                Easily Available Original Spares
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-3 mx-auto">
            <div className="row">
              <div className="card my-2 shadow">
                <div className="card-image text-center my-2"><img src={Qualified} className="img-fluid" style={{ maxHeight: '120px' }}></img></div>
                <h5>Team of Highly Qualified Engineers</h5>
                <p>For Production of High-quality modern machines, consultancy, material handling setup, fabrication, installation & Training. </p>
              </div>
              <div className="card my-2 shadow">
                <div className="card-image text-center my-2"><img src={Years} className="img-fluid" style={{ maxHeight: '120px' }}></img></div>
                <h5>27 years of Manufacturing Experience</h5>
                <p>Expertise in Engineering, Erection, Supervision, and Commissioning<br /><br /></p>
              </div>
            </div>
          </div><div className="col-sm-12 col-md-3 mx-auto">
            <div className="row">
              <div className="card my-2 shadow">
                <div className="card-image text-center my-2"><img src={India} className="img-fluid" style={{ maxHeight: '120px' }}></img></div>
                <h5>Pan India Leader in the Agro-processing Industry</h5>
                <p>We have solutions for aromatic basmati rice and non-basmati medium & short grains <br /><br /> </p>
              </div>
              <div className="card my-2 shadow">
                <div className="card-image text-center my-2"><img src={Three} className="img-fluid" style={{ maxHeight: '120px' }}></img></div>
                <h5>Sona Machinery range from 3 to 10 TPH</h5>
                <p>Cost-effective fixed price and tailored timeline to customers for a hassle-free experience</p>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mt-5 mb-5">
          <div className="card">
            <div className="card-header"><h5>Customer Speak</h5></div>
            <div className="card-body">
              <div className="row">
                <div className="mx-auto" style={{ maxWidth: '700px' }}>
                  <iframe className="embed-responsive w-100 shadow" height={'400px'} src="https://www.youtube.com/embed/_J2HhqgGjTc"
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
                <div className="mx-auto" style={{ maxWidth: '400px', maxHeight: '400px' }}>
                  <video controls webkit-playsinline="true" style={{ minWidth: '400px', maxWidth: '100%', minHeight: '60%', maxHeight: '100%' }}
                    disablePictureInPicture={true} preload="auto" crossOrigin="anonymous" id="myVideo">
                    <source src={SOUTH_VIDEO} className="border-1" type="video/mp4" />
                    Your browser does not support HTML5 video.
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FeatureImage head="Rice Mill Plot Diagram" img={RiceMillPlant} className="mt-2 mb-2" scale={'3'}></FeatureImage>
        <div className="row mt-4 mx-auto bg-info shadow gradient rounded m-2 p-2 mt-4 pt-4">
          <div className="col-sm-12 col-md-6">
            <h3>Customized Rice Mill Plant solutions according to</h3>
            <p>Sona Machinery has always been invigorating the milling industry through our customized top-of-line machinery, with a strong emphasis on the manufacturing process, through quality testing laboratories and a qualified, experienced team of enthusiasts.</p>
          </div>
          <div className="col-sm-4 col-md-2">
            <div className="row mx-auto">
              <img src={Standard} alt="" className="img-fluid col text-center" style={{ maxHeight: '100px', maxWidth: '100px' }} />
              <h5>The Type of rice</h5>
              <ul className="col">
                <li>Steam Rice </li>
                <li>Raw Rice </li>
                <li>Par Boiled Rice</li>
              </ul>
            </div>
          </div>
          <div className="col-sm-4 col-md-2">
            <div className="row mx-auto">
              <img src={Product} alt="" className="img-fluid col text-center" style={{ maxHeight: '100px', maxWidth: '100px' }} />
              <h5>The Quality of rice</h5>
              <ul className="col">
                <li>Basmati Long </li>
                <li>Non-Basmati</li>
                <li>IR</li>
                <li>Swarna</li>
              </ul>
            </div>
          </div>
          <div className="col-sm-4 col-md-2">
            <div className="row mx-auto">
              <img src={Standard} alt="" className="img-fluid col text-center" style={{ maxHeight: '100px', maxWidth: '100px' }} />
              <h5>As per the standards</h5>
              <ul className="col">
                <li>Export Quality</li>
                <li>Government work</li>
                <li>Local Supply</li>
                <li>Repackaging</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row mt-4 align-middle ">
          <div className="col-6 align-middle mt-4">
            <h2>End-to-end rice milling solutions from Sona Machinery</h2>
            <p className="">Sona Machinery provides an exquisite range of turnkey solutions for modern rice processing mills.</p>
            <div className="row mx-auto w-50 mt-4">
              <a href="/contact" className="btn btn-primary shadow ">Enquire Now</a>
            </div>
          </div>
          <div className="col-6">
            <img src={Fact} className="img-fluid rounded m-2 mt-1"></img>
          </div>
        </div>
        <div className="row text-center" >
          <h2>Our Products</h2>
          <div className="row mx-auto" >
            <ProductCard img={PCS} tgt="/pre-cleaner-pcs-2dr" desc="Paddy Cleaner"></ProductCard>
            <ProductCard img={HC} tgt="/pre-cleaner-drc-25" desc="Pre Cleaner - DRC"></ProductCard>
            <ProductCard img={VCS} tgt="/fine-cleaner-vcs-10" desc="Vibro Cleaner"></ProductCard>
            <ProductCard img={FINE} tgt="/fine-cleaner-fine-12" desc="Fine Cleaner"></ProductCard>
            <ProductCard img={SHEL} tgt="/paddy-husker-shel-104" desc="Paddy Husker"></ProductCard>
            <ProductCard img={RPS20} tgt="/paddy-separator-rps-20" desc="Paddy & Rice Separator"></ProductCard>
            <ProductCard img={PRO} tgt="/rice-whitener-pro-4s" desc="Rice Whitener Pro Series"></ProductCard>
            <ProductCard img={NEO} tgt="/rice-whitener-neo-5" desc="Rice Whitener Neo Series"></ProductCard>
            <ProductCard img={ST4} tgt="/silky-polisher-st-4" desc="Rice Silky Polisher"></ProductCard>
            <ProductCard img={LG} tgt="/length-grader-lg-01" desc="Length Grader"></ProductCard>
          </div>
        </div>
        <div>
          <TrustedByBrandsList photos='3'></TrustedByBrandsList>
        </div>
      </div>
    </div>
  )
}

